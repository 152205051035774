import React, {Component} from 'react';
import ViewQuestion from './view.view';
import EditQuestion from './edit.view';
import MathJax from 'react-mathjax-preview'
import status from '../../scripts/status';
import logger from '../../helper/logger';
import listQuestionsScript from './list.script';
import {batchWriteToDB} from '../../scripts/batchWriteToDB';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import userRights from '../../scripts/userRights';
import {isTrue, isUndefined} from '../../scripts/validations';
import uuid from 'react-uuid';

import {deleteQuestion} from '../../scripts/delete'
import { getFromS3 } from '../../scripts/s3';
const REVIEWER = 'reviewer';
const DESIGNER = 'designer';

export default class ListStatusQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
          statusName: 'DRAFT',
          parent_questions:[],
          questions: [],
          questionInView:[],
          secondChild:[], //only used while editing. This will help update the other child if edits are the same
          parentInView:[],
          view:false,
          edit:false,
          isPublished:false,
          isDeleting:false,
          userType: userRights.userType(),
          developers:'',
          reviewers:'',
          uuid: uuid(),
          username:'',
          questionsSelected:[],
          isLoading:false,
          showModal:false,
          showModalForProduction:false,
          questionIdToBeDeleted:''
        };
      }
      initialize=()=>{
        this.setState({
          skills:'',
          skillId:'',
          skillName:'',
          questions: '',
          questionInView:0,
          view:false,
          edit:false,
          isPublished:false,
          isDeleting:false,
          showModal:false,
          showModalForProduction:false,
          userType: userRights.userType(),
          uuid:uuid(),
          questionIdToBeDeleted:'',
          isLoading:false
        })
      }

      fetchQuestionsByStatus =async() => {
        
        let all_questions = await listQuestionsScript.fetchQuestionsByStatusQualifier(this.state.statusName, 'CHILD');
     //   let questions=[];
        const questions = all_questions.reduce((acc, value) => {
            // Group initialization
            
            if (!acc[value.groupId]) {
              acc[value.groupId] = [];
            }
           
            // Grouping
            acc[value.groupId].push(value);
           
            return acc;
          }, {});
          console.log(questions);
            this.setState({
            questions
            })
      }

      filterSelectedQuestions=()=>{
        if (this.state.questions)
        {
            debugger;
            let questions = this.state.questions;
          //  let parent_questions = this.state.parent_questions;
            let filteredQuestions=[];
            for (var key in questions)
            {
              if (questions.hasOwnProperty(key)) {
                console.log(key);
                console.log(questions[key][0]);
                console.log(questions[key][1]);
                if (questions[key][0].isChecked || questions[key][1].isChecked)
                {
              //   let filtered_parent = parent_questions.filter((parent => parent.sk === val));
                  if (questions[key][0].isChecked === true)
                    filteredQuestions.push(questions[key][0]);
                  if (questions[key][1].isChecked === true)
                    filteredQuestions.push(questions[key][1]);  
                // filteredQuestions.push(filtered_parent[0]);                  
                }
              }
            }
  /*           Object.keys(questions).map((val) =>{
              if (questions[val][0].isChecked || questions[val][1].isChecked)
              {
             //   let filtered_parent = parent_questions.filter((parent => parent.sk === val));
                if (questions[val][0].isChecked === true)
                  filteredQuestions.push(questions[val][0]);
                if (questions[val][1].isChecked === true)
                  filteredQuestions.push(questions[val][1]);  
               // filteredQuestions.push(filtered_parent[0]);                  
              }
            }) */
            console.log(filteredQuestions);
            return filteredQuestions;
        }
      }      

      submitForReview = ()=>{
        this.setState({
            isLoading:true
          })
       let questionsToBeSubmitted =  this.filterSelectedQuestions();        

       let jsonObject = listQuestionsScript.processContentData(questionsToBeSubmitted,"FOR REVIEW");
       logger.logMessage("listQuestions.view", 'submitForReview',"processedAllData "+jsonObject)
       this.batchUpdateDB(jsonObject);
       
      }
      submitForDesign = ()=>{
        this.setState({
            isLoading:true
          })
        let questionsToBeSubmitted =  this.filterSelectedQuestions();     
        let jsonObject = listQuestionsScript.processContentData(questionsToBeSubmitted,"FOR DESIGN");
 
        logger.logMessage("listQuestions.view", 'submitForReview',"processedAllData "+jsonObject)
        this.batchUpdateDB(jsonObject);
       }

      batchUpdateDB=async(jsonObject) =>{
          //debugger
          let res = await batchWriteToDB(jsonObject);
          this.setState({
            isLoading:false
          })
          if(res.UnprocessedItems.length!==0)
            logger.logMessage("listQuestions.view","batchUpdateDB","There are a few UnProcessedItems"+res.UnprocessedItems.length);
          else
            logger.logMessage("listQuestions.view","batchUpdateDB","All items have been written successfully");
            this.fetchQuestionsByStatus();
        }

     
      
      isChecked=()=> {    
        let questions = this.state.questions;
        for (var key in questions)
        {
          if (questions.hasOwnProperty(key))
            if (questions[key][0].isChecked || questions[key][1].isChecked)
              return true;
        }
        return false;
      }     
      onCheckBoxPressed=(e)=>{
          debugger;
        let questions = this.state.questions;
        let target_value = e.target.value.split(",");
        if (target_value[1] === "both")
        {
          questions[target_value[0]][0]["isChecked"] = e.target.checked;
          questions[target_value[0]][1]["isChecked"] = e.target.checked;
        }
        else
        {
          questions[target_value[0]][target_value[1]]["isChecked"] = e.target.checked;
        }       
        this.setState({
          questions
        })
      }
      disableCheckBox=(statusName, name)=>{
        if (!userRights.doIOwn(name))
          return true;
        if (status.isDraft(statusName) || status.isDesignDone(statusName) || status.isSentBack(statusName))
          return false;
        return true;  
      }
      disableCheckBoxForProduction=(statusName)=>{
        if (status.isReviewed(statusName))
          return false;
        return true;  
      }
      canShowQuestion=(val)=>{
        if ((val.copyQuestion === true || val.copyQuestion === "true") && val.country_language === 'IN|EN')
          return true;
        if (val.copyQuestion === false || val.copyQuestion === "false")
          return true;  
      }
      handleChange = (e) => {
            this.setState({ [e.target.name]: e.target.value })
      }
      render(){      
        let questions = this.state.questions || [];    
        console.log("Length "+Object.keys(questions).length); 

        if (this.state.isLoading)
         {
           return(<div>
             <h4>The status is getting updated....</h4>
              </div>)
         }
          return (
            <div class='container'>
              <h4 style={{textAlign:"center"}}>List of questions</h4>
                <Row class="form-group">
                    <Col ms={8}>
                       
                          <select  onChange={this.handleChange} class="form-select" value={this.state.statusName} name="statusName">
                              <option selected value="DRAFT">DRAFT</option>
                              <option  value="FOR REVIEW">FOR REVIEW</option>
                              <option value="FOR DESIGN">FOR DESIGN</option>
                              <option value="DESIGN DONE">DESIGN DONE</option>
                              <option value="SENT BACK">SENT BACK</option>
                              <option value="DESIGNER SENT BACK">DESIGNER SENT BACK</option>
                              <option value="PRODUCTION">PRODUCTION</option>
                          </select>
                    </Col>
                    <Col ms={4} class="bd-highlight">
                             <button class="btn btn-primary" type="button"
                                onClick={this.fetchQuestionsByStatus} >GO</button>
                    </Col> 
                </Row>
                 <br></br>
                 
                 <div>
                    <Row style={{marginTop:"2vmin"}}>
                    <Col ms={4} class="bd-highlight">
                        <button class="btn btn-warning" type="button" disabled={!this.isChecked}
                        onClick={this.submitForReview} >Submit for review</button>
                    </Col>    
                    <Col ms={4} class="bd-highlight">
                        <button class="btn btn-warning" disabled={!this.isChecked} type="button"
                        onClick={this.submitForDesign} >Submit for design</button>
                    </Col>     
{/*                     {(userRights.isUserAdmin()) && (                  
                    <Col ms={4} class="bd-highlight">
                        <button class="btn btn-warning" disabled={!this.isChecked} type="button"
                        onClick={this.submitForProduction} >PRODUCTION</button>
                    </Col>   )}  */}
                    </Row> 
                    <br></br>
                    <div>  
                  <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Check</th>                   
                      <th scope="col">Question</th>
                      <th scope="col">Country|Language</th>
                      <th scope="col">Skill Name</th>
                      <th scope="col">Total Images</th>
                      <th scope="col">Designed By</th>
                      <th scope='col'>Created By</th>
{/*                       <th scope ='col'>View</th>      
                      <th scope ='col'>Edit</th>  */}         
                    </tr>
                  </thead>
                  {
                      Object.keys(questions).map((val)=>{
                      let rows = [];
                      if (this.canShowQuestion(questions[val][0]))
                      {
                        let ii=0;
                        let child_content = questions[val][0];
                        rows.push(
                          <tr key={child_content.sk}>
                              <td>{ii+1}</td>
                              <td>        
                                <Form.Check type="checkbox" disabled={this.disableCheckBox(child_content.statusName, child_content.createdBy)} 
                                value={child_content.groupId+","+"both"} onChange={this.onCheckBoxPressed}/>
                              </td>
                              <td><MathJax math={child_content.question} /></td>
                              <td>{child_content.country_language}</td>
                              <td>{child_content.skillName}</td>
                              <td>{child_content.totalImages || '0'}</td>
                              <td>{child_content.isDesignedBy}</td>
                              <td>{child_content.createdBy}</td>
{/*                               <td onClick={()=>this.showQuestionByIndex(value)}>View</td>
                              {(userRights.canEdit(child_content)) && (
                                <td onClick={()=>this.editQuestionByIndex(val)}>Edit</td>)} */}
                            </tr>
                        )
                      }
                      return <tbody>{rows}</tbody> 
                    })
                }
                </table> 
                </div>   
                </div>
        </div>
  );
    }
}

