import apis from '../../apis/api'
import logger from '../../helper/logger'
import { batchWriteToDB } from '../../scripts/batchWriteToDB';
var ObjectID = require("bson-objectid");

export const cloneSession=async(data)=>{
  let sk;

  if (data.year === "None")   
      sk = `${data.program}|${data.sessionType}|${data.levelName}|${data.sessionNumber}|`;
  else
      sk = `${data.year}|${data.subject}|${data.curriculum}|${data.program}|${data.sessionType}|${data.levelName}|${data.sessionNumber}|`;

  let sessions = await isSessionAlreadyAvailable(sk);

  if (sessions.length)
  {
      console.log(sessions);
      let sk_full = `${sk}${data.category}|${data.categoryNumber}`;
      let sameSession = sessions.filter(session => session.sk === sk_full);
      if (sameSession.length)
          alert("The session is already available. Please edit the session or create a new session")   
      else
      {
         
          console.log("Same session but not same category available...Going ahead and creating....");
          let sessionId = sessions[0].sessionId;
          
          let jsonObject = createAnotherCategorySameSession(data, sessionId, sk_full);
          let updatedQuestions = await updateQuestion(jsonObject, data.sessionType); 
          updatedQuestions.push(jsonObject);
          console.log(updatedQuestions);
          let res = await batchWriteToDB(updatedQuestions);
          console.log(res);
        
      }
  }
  else
  {

      let sk_full = `${sk}${data.category}|${data.categoryNumber}`;
      let jsonObject = processContentData(data, sk_full);
      let updatedQuestions = await updateQuestion(jsonObject, data.sessionType);
      updatedQuestions.push(jsonObject);
      console.log(updatedQuestions);
      let res = await batchWriteToDB(updatedQuestions);
      console.log(res);
  }
}
const updateQuestion=async(data, sessionType)=>{
  console.log(data);
  let parent_questions=[];
  if (data["IB"] !== undefined){
    for (let ii=0;ii<data["IB"].length;ii++)
    { 
      let res = await apis._get_qualifier_sk(`PARENT`,`PARENT|${data["IB"][ii].attributes.parentId}`);
      console.log(res);
      let new_data = updateTags(res[0], sessionType, data.sessionId, data.levelName, data.program, data.sessionNumber, data.category, data.categoryNumber);
      parent_questions.push(new_data);
    }
  }
  if (data["RF"] !== undefined){
    for (let ii=0;ii<data["RF"].length;ii++)
    { 
      let res = await apis._get_qualifier_sk(`PARENT`,`PARENT|${data["RF"][ii].attributes.parentId}`);
      console.log(res);
      let new_data = updateTags(res[0], sessionType, data.sessionId, data.levelName, data.program, data.sessionNumber, data.category, data.categoryNumber);
      parent_questions.push(new_data);
    }
  }
  if (data["MR"] !== undefined){
    for (let ii=0;ii<data["MR"].length;ii++)
    { 
      let res = await apis._get_qualifier_sk(`PARENT`,`PARENT|${data["MR"][ii].attributes.parentId}`);
      console.log(res);
      let new_data = updateTags(res[0], sessionType, data.sessionId, data.levelName, data.program, data.sessionNumber, data.category, data.categoryNumber);
      parent_questions.push(new_data);
    }
  }
  if (data["LR"] !== undefined){
    for (let ii=0;ii<data["LR"].length;ii++)
    { 
      let res = await apis._get_qualifier_sk(`PARENT`,`PARENT|${data["LR"][ii].attributes.parentId}`);
      console.log(res);
      let new_data = updateTags(res[0], sessionType, data.sessionId, data.levelName, data.program, data.sessionNumber, data.category, data.categoryNumber, data.year);
      parent_questions.push(new_data);
    }
  }
  return parent_questions;
}

const updateTags=(data, sessionType, sessionId, levelName, programName, sessionNumber, category, categoryNumber, year)=>{
  debugger;
  let tag_details = `Level ${levelName} - ${programName} - ${sessionType} - Session ${sessionNumber} - ${category} - ${categoryNumber} - MATH - CBSE - ${year}`;
  console.log(data);
  data.tagDetails.push(tag_details);
  data.tags.push(sessionId);
  return data;
}

const isSessionAlreadyAvailable = async(sk)=>{
  //example of sk RISE|LIVE|C|1|CW|1
  try {

    console.log(sk);
    let res = await apis._get_qualifier_sk(`SESSION`,sk);

    console.log("RESPONSE"+res);
    return res;
  }
  catch(e)
  {
      logger.logError("listQuestionsScript","fetchQuestions",e)
  } 
}
const processContentData  = (data, sk)=>{

  let date = new Date();
  date = date.toISOString(); 
  let sessionId =  ObjectID();
  console.log(sessionId);
  let sessionData = data;
  sessionData.pk = `SESSION|${sessionId}`;
  sessionData.sessionId = sessionId;
  sessionData.sk = sk;
  sessionData.program=data.program;
  sessionData.statusName="DRAFT";
  sessionData.curriculum ="CBSE";
  sessionData.subject = "MATH";
  sessionData.year=data.year;
  sessionData.createdAt=date;
  sessionData.updatedAt=date;
  sessionData.createdBy=localStorage.getItem("username");

  return sessionData;
}
const createAnotherCategorySameSession= (data, sessionId, sk)=>{

let date = new Date();
date = date.toISOString(); 
//store time in seconds in the database

let sessionData = data;
sessionData.pk = `SESSION|${sessionId}`;
sessionData.sessionId = sessionId;
sessionData.sk = sk;
sessionData.program=data.program;
sessionData.statusName="DRAFT";
sessionData.curriculum ="CBSE";
sessionData.subject = "MATH";
sessionData.year=data.year;
sessionData.createdAt=date;
sessionData.updatedAt=date;
sessionData.createdBy=localStorage.getItem("username");


return sessionData;
} 
