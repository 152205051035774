import React from 'react';
import TextRegion from './textRegion'
import Images from '../images'
import MathJaxRegion from '../mathjaxRegion'
import {isUndefined} from '../../scripts/validations';

const Pair = (props) =>{
    
    let textField = props.textField; //sends the name of the prop
    let imageField = props.imageField;
    let className = (isUndefined(props.className) ? textField:props.className)
    let dataSetId = (isUndefined(props.dataSetId) ? textField:props.dataSetId)

    return(
            <div>
                <TextRegion textField={textField} className={className} dataSetId={dataSetId} />
                <MathJaxRegion textField={textField} typedValue ={props.typedValue}/>
                <Images 
                    dataSetId={dataSetId} 
                    className={className} 
                    imageField ={imageField} 
                    storeFile={props.storeFile}
                    imageURL={props.imageURL} 
                 />
            </div> 
        )
       
}            
export default Pair
