    //upload file to s3 bucket
    import uuid from 'react-uuid'
    import {Auth, Storage } from 'aws-amplify';
    import logger from '../helper/logger';
    import { isUndefined } from './validations';

    export const uploadToS3 =async(file,imageName)=>
    {      
          if (isUndefined(file))
               return;
          let data = await Auth.currentAuthenticatedUser()
          if (data)
          {
               Storage.configure({ level: 'public' });  
               let name = uuid();  
               logger.logMessage("uploadToS3","UUID of image",name);
               let result = await Storage.put(name,file);
               logger.logMessage("uploadToS3","uploadToS3",`Uploaded ${imageName}`);
               return name;
          }
          else
               alert("Your session has expired..You may have to manually logout and login for now....")
    }
    export const uploadDocToS3 =async(fileName, fileId, fileLink)=>
    {      
          if (isUndefined(fileName))
               return;
          let data = await Auth.currentAuthenticatedUser()
          if (data)
          {
               Storage.configure({ level: 'public' });  
               let folder = fileName.split('.')[1];
               let result = await Storage.put(folder+'/'+fileId,fileLink);
               console.log("Uploaded successfully"+result);
               return;
          }
          else
               alert("Your session has expired..You may have to manually logout and login for now....")
    }

    export const deleteFromS3 =async(image)=>
    {      
          if (isUndefined(image))
               return;
          let data = await Auth.currentAuthenticatedUser()
          if (data)
          {
               Storage.configure({ level: 'public' });                 
               logger.logMessage("deleteFromS3","UUID of image",image);
               let result =  Storage.remove(image) 
                              .then(result => logger.logMessage("deleteFromS3","deleteFromS3",`deleted ${image}`))
                              .catch(err => logger.logError("deleteFromS3","deleteFromS3",`Error deleting ${image}`)); 
               return result;
          }
          else
               alert("Your session has expired..You may have to manually logout and login for now....")
    }

    export const getFromS3=async(image)=>{
          Storage.configure({ level: 'public' }); 
          return await Storage.get(image);
    }