import React, { Component } from 'react';
import MathJax from 'react-mathjax-preview'
import DisplayImage from '../../components/displayImage';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logger from '../../helper/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faSpinner, faCopy } from '@fortawesome/free-solid-svg-icons';
import { async } from 'regenerator-runtime';
library.add(faTimesCircle,faSpinner, faCopy);
export default class TestQuestion extends Component {
    constructor(props) {
        super(props);
        console.log(props.question)
        this.state = {
            question: props.question,
            parent: props.parent,
            qType: props.parent.qType,
            answerGiven: '',
            multiAnswerGiven: [],
            feedback: '',
            submitted: false,
            modalShow: false,
            qcopied:false,
            qidcopied:false,
            videoURL:'',
            videoSaved:false,
            isLoading:false
        }
    }

    handleShow = () => {
        this.setState({ modalShow: true });
    }
    handleClose = () => {
        this.setState({ modalShow: false });
    }

    // mathjaxToString=()=>
    // {
    //     let question ='';
    //     console.log("indie")
    //     if(document.querySelectorAll('#MathJax-Span-1').length>0)
    //     {
    //         console.log("indie 1")
    //         question = document.querySelectorAll('#MathJax-Span-1')[0].innerText.replace(/\n/g,' ')

    //     }
    //     else
    //     {
    //         console.log("indie 2")
    //         question=document.querySelectorAll('.react-mathjax-preview-result')[0].innerText;
    //     }
    //         return question;
    // }

    onSubmit = () => {
        console.log("Submit pressed");
        console.log(this.state.question);
        let feedback;
        if (this.state.qType === 'input') {
            if (this.IsInputAnswerCorrect())
                feedback = "Wow! Good Job";
            else
                feedback = "Sorry! Better luck next time";
        }
        else if (this.state.qType === 'mcq') {
            if (Number(this.state.answerGiven) === this.state.question.answers[0])
                feedback = "Wow! Good Job";
            else
                feedback = "Sorry! Better luck next time";
        }
        else if (this.state.qType === 'multiple') 
        {
              const multiAns=[...this.state.multiAnswerGiven.sort((a,b)=> {return a-b })];
              if (JSON.stringify(multiAns)==JSON.stringify(this.state.question.answers.sort((a,b)=> {return a-b })))
              {
                  feedback = "Wow! Good Job";
                  console.log("true");
              }
              else{
                feedback = "Sorry! Better luck next time"
                console.log("false");
              }
        }
        this.setState({ feedback, submitted: true });
    }
    IsInputAnswerCorrect = () => {
        console.log(this.state.question.answers);
        try {
            let answers = this.state.question.answers;
            for (let ii = 0; ii < answers.length; ii++) {
                if (answers[ii] === this.state.answerGiven)
                    return true;
            }
            return false;
        }
        catch (e) {
            if (this.state.question.answers === this.state.answerGiven)
                return true;
        }
        return false;
    }
    handleChange = (e) => {
        if (e.target.name.includes("options") && this.state.parent.qType==="mcq") 
        {
            this.setState({ answerGiven: e.target.dataset.id })
        }
        else if (e.target.name.includes("options") && this.state.parent.qType==="multiple") 
        {   console.log(e);
            let multiAns=[];
            if(e.target.checked===true)
            {
                multiAns=[...this.state.multiAnswerGiven,parseInt(e.target.dataset.id)]
                this.setState({multiAnswerGiven:multiAns})
                console.log(multiAns);
            }
            else if (e.target.checked===false)
            {
                multiAns=[...this.state.multiAnswerGiven]
                for (let i=0; i<multiAns.length; i++)
                {
                    if(multiAns[i]===parseInt(e.target.dataset.id))
                    {
                        multiAns.splice(i,1);
                    }
                }
                this.setState({multiAnswerGiven:multiAns})
                console.log(multiAns);
            }
            
            
        }
        else
            this.setState({ [e.target.name]: e.target.value });
    }
    saveVideo=async()=>{ 
        debugger;
        this.setState({isLoading:true});
        await this.props.saveVideo(this.state.videoURL);
        this.setState({isLoading:false,videoSaved:this.props.videoSaved});
        console.log(this.props.videoSaved);
    }
    render() {

        const question = this.state.question;
        const questiontext=this.state.question.question;
        const parent = this.state.parent;
        console.log(question);
        console.log(parent);
        let options = this.state.question.options || [];
        let oImages = this.state.question.oImages || [];
        return (
            <>
                <Modal show={this.props.show} onHide={() => { this.props.setTest(false) }} size="lg" style={{
                    backgroundImage: `url("https://www.mathbuddyonline.com/assets/images/studentDashboard/mathbuddy_student_home.jpg")`
                }}>
                    <Modal.Header className="w-100" >
                        <Modal.Title className='w-100 d-flex align-items-center justify-content-between'><span className="ms-4 question_label" onClick={this.handleShow}>Question</span>
                            <FontAwesomeIcon icon="fa-solid fa-times-circle" size="lg" color='#545cad' onClick={() => { this.props.setTest(false) }} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="table-responsive">
                        <div class="form-group mt-2 mb-2" >

                            {(this.state.parent.isLatex === true || this.state.parent.isLatex === "true" ) && (
                                <MathJax class="question_text" math={this.state.question.question} />
                            )}
                            {(this.state.parent.isLatex === "false" || this.state.parent.isLatex === false) && 
                    
                            (<>
                                {(!this.state.question.question.includes("<br>"))&&( 
                                <div className='question_text'>{this.state.question.question}</div>)}
                            
                                {(this.state.question.question.includes("<br>"))&&( 
                                <div className='question_text' dangerouslySetInnerHTML={{ __html: this.state.question.question }}></div>)}    
                            </>
                                )}
                            <br />

                            {(this.state.question.qImage) && (
                                <div>
                                    <DisplayImage imageURL={this.state.question.qImage} />
                                </div>
                            )}
                            {(parent.qType==="mcq")&&(options.length > 0) && (
                                <div>
                                    {
                                        options.map((val, idx) => {
                                            return (
                                                <div class="form-check">
                                                    <input value={val} onClick={this.handleChange} type="radio" data-id={idx} id={`options${idx}`} name="options" class="form-check-input" />
                                                    <label class="question_option" for={`options${idx}`}><MathJax math={val} /></label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            {(parent.qType==="multiple")&&(options.length > 0) && (
                                <div>
                                    {
                                        options.map((val, idx) => {
                                            return (
                                                <div class="form-check">
                                                    <input value={val} onClick={this.handleChange} type="checkbox" data-id={idx} id={`options${idx}`} name="options" class="form-check-input" />
                                                    <label class="question_option" for={`options${idx}`}><MathJax math={val} /></label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            {(parent.qType==="mcq")&&(oImages.length > 0) && (
                                <div>{
                                    oImages.map((val, idx) => {
                                        return (
                                            <div class="form-check">
                                                <input value={val} onClick={this.handleChange} type="radio" data-id={idx} id={`options${idx}`} name="options" class="form-check-input" />
                                                <label class="question_option" for={`options${idx}`}> <DisplayImage imageURL={val} /></label>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            {(parent.qType==="videoSavedmultiple")&&(oImages.length > 0) && (
                                <div>{
                                    oImages.map((val, idx) => {
                                        return (
                                            <div class="form-check">
                                                <input value={val} onClick={this.handleChange} type="checkbox" data-id={idx} id={`options${idx}`} name="options" class="form-check-input" />
                                                <label class="question_option" for={`options${idx}`}> <DisplayImage imageURL={val} /></label>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            {(this.state.qType === 'input') && (this.state.parent.isSystemEvaluated === "true" || this.state.parent.isSystemEvaluated === true) && (
                                <input name="answerGiven" onChange={this.handleChange} type="number" class="form-control w-50" placeholder="Type your answer here" />
                            )}
                            {(this.state.qType === 'input') && (!this.state.parent.isSystemEvaluated || this.state.parent.isSystemEvaluated === "false") && (
                                <input name="answerGiven" onChange={this.handleChange} class="form-control w-50" placeholder="Type your teacher evaluated answer" />
                            )}
                            <button className="submit_btn mt-4 mb-4" onClick={this.onSubmit}>Submit</button>
                            <p className='question_option'>{this.state.feedback}</p>
                            {(this.state.submitted && (this.state.parent.isLatex === true || this.state.parent.isLatex === "true")) && (
                                <>
                                    <p className='question_option'>Explanation:<br></br>
                                    <MathJax class="form-control question_option mb-2" math={this.state.question.explanation} /></p>
                                    {(this.state.question.eImage) && (
                                        <div>
                                            <DisplayImage imageURL={this.state.question.eImage} />
                                        </div>
                                    )}
                                </>
                            )}
                            {(this.state.submitted && (this.state.parent.isLatex === "false" || this.state.parent.isLatex === false)) && (
                                <>
                                {(!this.state.question.explanation.includes("<br>"))&&( 
                                <p className='question_option mb-2'>Explanation:<br></br>{this.state.question.explanation}</p>)}
                                {(this.state.question.explanation.includes("<br>"))&&( 
                                    <p className='question_option mb-2' dangerouslySetInnerHTML={{ __html: `Explanation:<br></br>${this.state.question.explanation}`}}></p>)}
                                    
                                    {(this.state.question.eImage) && (
                                        <div>
                                            <DisplayImage imageURL={this.state.question.eImage} />
                                        </div>
                                    )}
                                </>
                            )}

                        </div>
                    </Modal.Body>

                </Modal>


                <Modal show={this.state.modalShow} onHide={this.handleClose} size="lg"
                style={{
                    backgroundImage: `url("https://www.mathbuddyonline.com/assets/images/studentDashboard/mathbuddy_student_home.jpg")`
                }}>
                <Modal.Header className="w-100" >
                        <Modal.Title className='w-100 d-flex align-items-center justify-content-between'><span className="ms-4 question_label">Question details</span>
                            <FontAwesomeIcon icon="fa-solid fa-times-circle" size="lg" color='#545cad' onClick={this.handleClose} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="form-group mt-2 mb-2" >
                            
                            
                                <label className='font mb-2'>Question ID: </label> &nbsp;&nbsp;<span className='copy'><FontAwesomeIcon icon="fa-solid fa-copy" color='#545cad' onClick={() => {navigator.clipboard.writeText(this.state.question.contentId); this.setState({ qcopied: false, qidcopied: true })}}/></span>&nbsp;&nbsp;{(this.state.qidcopied === true) &&(<span>Copied!</span>)}
                                <div className='fontm mb-2'>{this.state.question.contentId}</div>
                                
                            {(this.state.parent.isLatex === true || this.state.parent.isLatex === "true" || this.state.question.question.includes("<br>")) && (
                                <>
                                    <label className='font mb-2'>Question: </label> &nbsp;&nbsp;<span className='copy'><FontAwesomeIcon icon="fa-solid fa-copy" color='#545cad' onClick={() => {navigator.clipboard.writeText(document.querySelectorAll('.react-mathjax-preview-result')[0].innerText); this.setState({ qcopied: true,qidcopied: false }) }}/></span>&nbsp;&nbsp;{(this.state.qcopied === true) &&(<span>Copied!</span>)}
                                    <div className='fontm mb-4'><MathJax class="fontm mb-4" math={this.state.question.question} /></div>
                                </>
                            )}
                            {(this.state.parent.isLatex === "false" || this.state.parent.isLatex === false) && (!this.state.question.question.includes("<br>")) &&
                                (<><label className='font mb-2'>Question: </label>&nbsp;&nbsp; <span className='copy'><FontAwesomeIcon icon="fa-solid fa-copy" color='#545cad' onClick={() => {navigator.clipboard.writeText(this.state.question.question);this.setState({ qcopied: true,qidcopied: false }) }}/></span>&nbsp;&nbsp;{(this.state.qcopied === true) &&(<span>Copied!</span>)}
                                <div className='fontm mb-4'>{this.state.question.question}</div>
                                </>)}
                                                            
                                <label className='font mb-2 mt-4'>Video Link: </label><br></br>
                                <input type='text' onChange={this.handleChange} name="videoURL" className='w-100 form-control fontm mb-2'/>        

                                <div className='w-100 d-flex align-items-center justify-content-center mt-4 mb-2'>
                                    <button className="submit_btn mt-4 mb-2" onClick={this.saveVideo}>Save</button></div>
                                    {(this.state.isLoading)&&(<div className='w-100 d-flex align-items-center justify-content-center mt-4 mb-2'><FontAwesomeIcon icon="fa-solid fa-spinner" spin size='lg' color={'#545cad'} /></div>)}
                                    {(!this.state.isLoading)&&(this.state.videoSaved)&&(<div className='w-100 d-flex align-items-center justify-content-center mt-4 mb-2'><label className='font mb-2'>Video link saved successfully </label></div>)}    
                            

                                
                    </div>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}