import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {saveSession} from './create.script';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner} from "@fortawesome/free-solid-svg-icons";
library.add(faSpinner);

export default class CreateSession extends Component {
  
    constructor(props) {
    //  debugger
        super(props);
        this.state={
            levelName: '',
            sessionNumber:'',
            curriculum:'CBSE',
            year:'None',
            subject:'MATH',
            sessionType:'LIVE',
            category:'',
            categoryNumber:'',
            isLoading:false,
            programName:'RISE',
            qualifier: 'SESSION',
            sessionTime: 60
        }
    }

    isdisable=()=>{
        if(!this.state.sessionNumber)
        {
            return true;
        }
        else if(!this.state.categoryNumber)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    saveSession=async()=>{
        this.setState({isLoading:true})
       await saveSession(this.state);
       this.setState({isLoading:false})
     }

    render() 
    {      
        if (this.state.isLoading)
        {
            return (
                <div>
                    <div className='w-100 d-flex justify-content-center align-items-center' style={{color:"#545cad", height:"100%"}}><FontAwesomeIcon icon="fa-solid fa-spinner" spin size="lg" /> Adding the Session. Please be patient.....</div>
                </div>
            )
        }
        return (
            <div class='container'>
                <h4 style={{ textAlign: "center" }}>Create a session</h4><br></br>
                <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                        
                        <Row>    
                            <Col ms={2}> 
                                <select class="form-select"  name="year">
                                    <option value="" disabled selected hidden>Choose a year</option>
                                    <option  value='None'>None</option>
                                    <option  value="2022">2022</option>
                                    <option  value="2023">2023</option>
                                    <option  value="2024">2024</option>
                                    </select>
                            </Col> 
                            <Col ms={2}> 
                                <select class="form-select" value={this.state.subject} name="subject">
                                    <option  value="MATH">MATH</option>
                                    <option  value="CS">CS</option>
                                    </select>
                            </Col> 
                            <Col ms={2}> 
                                <select class="form-select" value={this.state.curriculum} name="curriculum">
                                    <option  value="CBSE">CBSE</option>
                                    <option  value="CODE">CODE</option>
                                </select>
                            </Col> 
                            <Col ms={2}> 
                                <select class="form-select" value={this.state.sessionType} name="sessionType">
                                    <option  value="LIVE">LIVE</option>
                                    <option  value="DEMO">DEMO</option>
                                    </select>
                            </Col>   
                            <Col ms={2}> 
                                <select class="form-select" value={this.state.programName} name="programName">
                                    <option  value="RISE">RISE</option>
                                    <option  value="OLMP">Olympiad Preparation</option>
                                    <option  value="MATHLATHON">Mathlathon</option>
                                    <option  value="JUNIOR-BLOCK-CODER">Junior Block Coder</option>
                                    <option  value="SCRATCH">Scratch</option>
                                    <option  value="SCRATCH-AI-ML">SCRATCH/AI-ML</option>
                                    <option  value="APP-INVENTOR">App Inventor</option>
                                    <option  value="PYTHON">Python</option>                                    
                                </select>
                            </Col>                                 
                            <Col ms={2}>                            
                                <select class="form-select" value={this.state.levelName} name="levelName">
                                    <option selected >Select a level</option>
                                    <option  value="A">A</option>
                                    <option  value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                    <option value="F">F</option>
                                    <option value="G">G</option>
                                    <option value="H">H</option>                                    
                                </select>
                            </Col>
                            </Row>
                            <br></br>
                            <Row>
                            <Col ms={2}> 
                                <input class="form-control" name="sessionNumber" type="number" 
                                  value={this.state.sessionNumber} placeholder="Type a session Number" />
                            </Col>   
                            <Col ms={2}>
                                <select class="form-select" value={this.state.category} name="category">
                                    <option>Select a category</option>
                                    <option value="CW">Classwork</option>
                                    <option  value="HW">Homework</option>                                   
                                </select>
                            </Col> 
                            <Col ms={2}>
                                <input class="form-control" name="categoryNumber" type="number" 
                                  value={this.state.categoryNumber} placeholder="Type the category Number"  />
                            </Col> 
                            <Col ms={2}>
                                    <select class="form-select" value={this.state.qualifier} name="qualifier">
                                        <option >Select the session type</option>
                                        <option selected value="SESSION">Regular Session</option>
                                        <option  value="ASSESSMENT">Assessment</option>                                   
                                    </select>
                            </Col>
                            <Col ms={2}>
                                <input class="form-control" name="sessionTime" type="number" 
                                  value={this.state.sessionTime} placeholder="Session time in minutes"  />
                            </Col>                             
                            <Col ms={4} >
                                <Button disabled={this.isdisable()} onClick={this.saveSession}>SAVE</Button>
                            </Col>                           
                        </Row>
                        <br/><br/>
                        <Row>
                            <Col style={{color:"blue"}}>
                            <p>Note 1: Session number is a running number. Please make sure that you type the next available session number to avoid confusion</p>
                            <p>Note 2: Category number is useful for Homeworks. If you want to have multiple homeworks for the same session, then you should choose a running number for the same.</p>
                            <p>Note 3: There will always be just one classwork with the category number as 1</p>
                            </Col>
                        </Row>
               </form>
            </div>
        );
    }    
}