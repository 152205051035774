import logger from '../../helper/logger'
import uuid from 'react-uuid'

const addSubjectScript = {

    processContentData:(myName)=>{
        let date = new Date();
        date = date.toISOString();
        const data = 
        {
          "pk":`SUBJECT`,	
          "sk":uuid(),
          "createdBy": localStorage.getItem("username"),
          "myName":myName,
          "createdAt":date,
          "updatedAt":date,
          "createdBy":localStorage.getItem("username"),
          "statusName":"DRAFT"
        }
        logger.logMessage("addSubject.script.js","ProcessContentData Successful",data);
        return data;
    }
}
export default addSubjectScript