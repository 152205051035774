import React, {Component} from 'react';
import {Link} from 'react-router-dom'

export default function Document(){
    
        return (
          <div style={{textAlign:"center"}}><br></br> 
              <Link to='/uploadDocument'> Upload Document </Link> 
         </div>
         );
    
}
