import apis from '../apis/api'
import logger from '../helper/logger'

export const deleteQuestion=async(pk,sk)=>{   
    console.log(pk+ " " +sk);   
    try {
        let res = await apis._delete(pk,sk);
        return res;
    }
    catch(e)
    {
        logger.logError("listQuestionsScript","delete question",e)
    }
}