import apis from '../../apis/api'
import logger from '../../helper/logger'

const designerScript = {

    fetchQuestionsBetweenDates:async(date1, date2)=>{                
        try {
            return await apis._get_status_date(`PRODUCTION`, date1, date2);
        }
        catch(e)
        {
            logger.logError("designerScript","fetchQuestionsBetweenDates",e)
        }
    },
    

}
export default designerScript