import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import userRights from '../../scripts/userRights';
import { getFromS3 } from '../../scripts/s3';
import EditQuestion from '../question/edit.view';
import editSessionScript from './edit.script';
import ViewQuestion from '../question/view.view';
import TestQuestion from '../question/test.view';
const CATEGORY=["IB","RF","MR","LR"];
export default class SessionView extends Component {
  
    constructor(props) {
        super(props);
        console.log(props.session)
        this.state={
            session:props.session,
            levelName: props.session.levelName,
            sessionNumber:props.session.sessionNumber,
            questionInView:[],
            parentInView:[],
            order:0,
            view:false,
            edit:false,
            test:false
        }
    }
        
    componentDidMount = async() => {
        //get All questions for Ice breaker for this session
        let ib = this.state.session["IB"];
        this.setState({ib});
    }
    // canSeeQuestion=(statusName)=>{
    //     if (userRights.isUserDesigner() && statusName === "FOR DESIGN")
    //         return true;
    //     if (userRights.isUserDeveloper())
    //         return true;
    // }
    viewQuestion=async(val, idx)=>
    {
        await this.setQuestionDetails(val, idx);
        this.setView(true);
    }
    setQuestionDetails = async(val,idx)=>{
        let questionInView =[];
        let parentInView=[];          
        let groupOfQuestions = await editSessionScript.fetchByGroupId(val.contentId);
        parentInView = groupOfQuestions.filter(parent=>parent.contentId === val.contentId);
        questionInView = groupOfQuestions.filter(question=> question.country_language === val.attributes.country_language);
        questionInView = questionInView[0];
        
        parentInView = parentInView[0];
        if (questionInView.qImage)
        {
          let image = await getFromS3(questionInView.qImage);
          questionInView.qImage = image;
        }
        if (questionInView.eImage)
        {
          let image = await getFromS3(questionInView.eImage);
          questionInView.eImage = image;
        }
        if (questionInView.oImages)
        {
          let images=[];
          for (var ii=0;ii<questionInView.oImages.length;ii++)
          {
            images[ii] = await getFromS3(questionInView.oImages[ii]);
            
          }
          questionInView.oImages = images;
        }      

        this.updateSessionQuestion(idx, questionInView, val.attributes.category, val.order);
        this.setState({questionInView, parentInView,order:val.order});
    }
    
    updateSessionQuestion=(idx, question, category,order) => {
        let session = this.state.session;
        session[category][idx]={contentId: question.parentId, attributes: question, order:order}
        this.setState({session});
    }
    setView=(view)=>{
        this.setState({
          view
        })
    }
    
    render() 
    {      
        if (this.state.view){
            return (
                <ViewQuestion setView={this.setView} parent ={this.state.parentInView} question={this.state.questionInView}/>
            )
        }
        
        return (
            <div class='container'>
                <Row><Col>
                <h4 style={{ textAlign: "center" }}>Session Details</h4>
                </Col></Row>
               <Row style={{align:"right"}}><Col ms={3} >
                    <Button className="float-right" onClick={this.props.goBack}>BACK</Button></Col>
                </Row><br></br>
                <br/>                
                <div>
                    <Row>                                    
                        <Col ms={12}>{`Level ${this.state.levelName} - Session ${this.state.sessionNumber}`}</Col>                              
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col><h5>Section 2: Information</h5></Col>
                    </Row>
                    <Row>                        
                        <Col ms={2} style={{color:"blue"}}>Ice Breaker</Col>
                        <Col ms={1}>{this.state.session["IB"] && this.state.session["IB"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Rapid Fire</Col>
                        <Col ms={1}> {this.state.session["RF"] && this.state.session["RF"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Math Reasoning</Col>
                        <Col ms={1}> {this.state.session["MR"] && this.state.session["MR"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Logical Reasoning</Col>
                        <Col ms={1}> {this.state.session["LR"] && this.state.session["LR"].length}</Col>
                        <Col ms={2} style={{color:"blue"}}>Lessons</Col>
                        <Col ms={1}> {this.state.session["LN"] && this.state.session["LN"].length}</Col>                       
                    </Row>
                    <Row><Col ms={12}>
                        {CATEGORY.map((category, idx)=>{    
                            let oneRow=[];                
                            {(this.state.session[category]) && (
                                oneRow.push(
                                <div>
                                    <Row>
                                        <Col ms={12}>{category} questions</Col>
                                    </Row>
                                    <Row><Col ms={12}>
                                    <div>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>                  
                                                    <th scope="col">Question</th>
                                                    <th scope="col">Skill</th>
                                                    <th scope="col">Country|Language</th>
                                                    <th scope="col">StatusName</th>
                                                    <th scope="col">Total Images</th>
                                                                      
                                                </tr>
                                            </thead>
                                            
                                            {(this.state.session[category].map((val,idx) => {
                                     
                                                let rows=[];
                                                let attributes = val.attributes;
                                                                                                 
                                                    rows.push(
                                                        <tr>
                                                            <td>{val.order}</td>
                                                            <td  style={{cursor:"pointer", color:"blue", 
                                                                textDecoration: "underline"}} onClick={()=>this.viewQuestion(val,idx)}>{attributes.question}</td>
                                                            <td>{attributes.skillName}</td>
                                                            <td>{attributes.country_language}</td>
                                                            <td>{attributes.statusName}</td>
                                                            <td>{attributes.totalImages}</td>
                                                        </tr>
                                                    )
                                                
                                                return <tbody>{rows}</tbody>
                                            }))
                                            }
                                        </table>                                        
                                    </div>  
                                    </Col></Row>
                                </div>
                            ))}
                            return <div>{oneRow}</div>
                        })}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}