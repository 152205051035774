import React from 'react';
import {Button} from 'react-bootstrap';

const Success = (props) =>{
    return(            
            <div class="text-center font-weight-bold text-primary">
            <h4>Document updated successfully</h4>
            <Button className="btn-com" onClick={()=>{props.goBack(false)}}>Upload another Document </Button>
            </div>
        )       
}            
export default Success
