import { Auth } from 'aws-amplify';
var AWS = require('aws-sdk');

export const listUsers=async(userType, stateName,object)=>{
  debugger;
  let res = await Auth.currentCredentials();
  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId:res.accessKeyId,
    secretAccessKey:res.secretAccessKey,
    securityToken:res.securityToken,
    credentials:res
  });
  var params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    GroupName:userType
  };
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
  await cognitoidentityserviceprovider
  .listUsersInGroup(params)
  .promise()
  .then(results => {
    console.log(results);
    let users = results["Users"];
    const items = users.map((item) => {
      return {
          value: item["Username"],
          label: item["Attributes"][4]["Value"] //need to see how to get it by email
      }
    });
    object.setState({
      [stateName]:items,
    })   
    return results;
  })
  .catch(e => {
    console.log(e);
    return e;
  });
}
