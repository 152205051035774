import React from 'react'

export default function About() {
    return (
        <div style={{textAlign:"center"}}>
            <h1>About</h1>
            <h5>This is an app to add and manage content for Math Buddy</h5>            
        </div>
    )
}
