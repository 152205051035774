import React from 'react';
import MathJax from 'react-mathjax-preview'
import DisplayImage from '../../components/displayImage';

const ViewQuestion = (props) =>{
    let question = props.question;
    let options = question.options || [];
    let oImages = question.oImages || [];   
    console.log(oImages);
    let qType = props.parent.qType;
    let answer;
    if (qType === 'mcq')
        answer = "Correct answer is option "+Number(question.answers[0]+1);
    else if (qType === 'multiple')
    {
        let answers = question.answers;
        answer = "Correct options are "
        for (var ii=0; ii<answers.length;ii++)
            answer += (answers[ii]+1)+', ';
    }    
    else
       answer = question.answers;

    return(            
        <div class='container'>
            <button class="btn btn-primary" type="button" onClick={()=>{props.setView(false)}}>BACK</button>
            <div class="form-group" >
                <label class="text-primary" htmlFor="Skillname">Skill name</label>
                <MathJax class="form-control" math={question.skillName}/>
                <label class="text-primary" htmlFor="Question">Question</label>
                <MathJax class="form-control" math={question.question}/>
                <label class="text-primary" htmlFor="Question">Question Image</label>
                <DisplayImage imageURL={question.qImage} />               
                <label class="text-primary" htmlFor="Options">Options</label>
                {
                    options.map((val,idx) => {
                       
                        return (                       
                            <div class="form-group" key={idx} >
                                <label htmlFor="Options">{`Option${idx+1}`}</label>
                                <MathJax math={val} />                               
                            </div>
                        )
                      })
                }
                {
                      oImages.map((val,idx) => {
                        return (                       
                            <div class="form-group" key={idx} >
                                <label htmlFor="Options">{`Option${idx+1}`}</label>                               
                                <DisplayImage imageURL={oImages[idx]} />
                            </div>
                        )
                      })
                }
                <label class="text-primary" htmlFor="Answer">Answer</label>
                <p>{answer}</p>
                <label class="text-primary" htmlFor="Question">Explanation</label>
                <MathJax class="form-control" math={question.explanation}/>
                <label class="text-primary" htmlFor="Question">Explanation Image</label>
                <DisplayImage imageURL={question.eImage}/>
            </div>
        </div>
        )       
}            
export default ViewQuestion
