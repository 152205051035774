import React, {Component} from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'

export default class  MyDropDown extends Component {

    onSelect=(e)=>{
        this.props.triggerUpdate(e)
        console.log(e.value)
    }
    render(){
        if (this.props.options.length === 0)
        return(
            <div>Loading</div>
        )
        return (
          <div>
            <form>
                <Dropdown class="form-group" 
                    options={this.props.options} 
                    onChange={this.onSelect} 
                     value={this.props.value} 
                    placeholder={this.props.placeholder} />
            </form>
         </div>
         );
    }
}
