import React, {Component} from 'react';

import Button from 'react-bootstrap/Button';
import SkillList from '../skills/skillList';
import EditQuestion from '../question/edit.view';
import editSessionScript from './edit.script';
import ViewQuestion from '../question/view.view';
import {batchWriteToDB} from '../../scripts/batchWriteToDB';
import {writeToDB} from '../../scripts/writeToDB';
import { getFromS3 } from '../../scripts/s3';
import userRights from '../../scripts/userRights';
import logger from '../../helper/logger';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faLink, fas, faSpinner, faCircleArrowLeft,faCloudArrowUp, faCloudArrowDown,faClock,faTimes,
     faArrowRotateRight, faPlus } from "@fortawesome/free-solid-svg-icons";
library.add(faEdit, faLink,faSpinner, faCircleArrowLeft,faCloudArrowUp, faCloudArrowDown, faClock,faTimes,faArrowRotateRight, faPlus);
import listQuestionsScript from '../question/list.script';


export default class EditSession extends Component {
  
    constructor(props) {
        super(props);
        debugger;
        this.state={
            session:props.session,
            levelName: props.session.levelName,
            sessionNumber:props.session.sessionNumber,
            sessionTime:props.session.sessionTime/60 || 60,
            program: props.session.program !== undefined ? props.session.program : props.session.programName,
            subject: props.session.subject || 'MATH',
            curriculum: props.session.curriculum || 'CBSE',
            year: props.session.year || '',
            order: props.session.order || 0,
            sessionTopics:'',            
            questions:[],
            parent_questions:[],
            questionFilter:'available',
            dateFilter:2,
            category:'IB',
            removedQuestions:[],
            questionInView:[],
            parentInView:[],
            view:false,
            edit:false,
            files:[],
            ib:[],
            rf:[],
            mr:[],
            lr:[],
            pr:[],
            ln:[],
            vd:[],
            fi:[],
            video_country: 'IN',
            videoName:'',
            videoLink:'',
            fileId: '',
            fileName:'',
            fileLink:'',
            file_country: 'IN',
            skillId:'',
            skillName:'',
            section4:false,
            isLoading:false,
            saved: '',
            loadingSave: false
        }
    }
        
    componentDidMount = async() => {
        //get All questions for Ice breaker for this session
        let ib = this.state.session["IB"];
        if (this.props.session.sessionTopics)
            this.processSessionTopics(this.props.session.sessionTopics);
        this.setState({ib});
    }
    processSessionTopics =(topics) =>{

        let sessionTopics = [];
        for (var ii=0;ii<topics.length;ii++)
        {
            sessionTopics += topics[ii]+'\n';
        }
        this.setState({sessionTopics});
        
    }

    setLevel=(levelName)=>{
        this.setState({levelName});
    }
    setSkillId = (skillId) =>{
        this.setState({skillId})
    }
    setSkillName = (skillName) =>{
        this.setState({skillName})
    }  
    setView=(view)=>{
        this.setState({
          view
        })
    }
    setEdit=(edit)=>{
        this.setState({
          edit
        })
    }
    fetchQuestions = async() =>{
        //returns all questions for a skill. This includes parent and child content
        let all_questions = await listQuestionsScript.fetchQuestions(this.state.skillId); 
 
        if (userRights.isUserDeveloper() || userRights.isUserAdmin())
          this.processQuestions(all_questions,'PARENT');  
    }
    processDocuments=(all_questions, qualifier)=>{
        let parent_questions = all_questions.filter(question => (question.qualifier === qualifier))
        let questions=[];
        parent_questions.map((question,idx) => {
          questions[question.sk] = all_questions.filter(children => (children.parentId === question.contentId))
          if (this.isTagToBeRemovedFromQuestion(question))
          {
              //The tag has already been marked as removed and so now we need to remove tag and tag details from that question's parent.
                this.deleteTagNDetails(question);
          }
        })
        this.setState({
            questions,
            parent_questions
        })
    }
    processQuestions=(all_questions, qualifier)=>{
        let parent_questions = all_questions.filter(question => (question.qualifier === qualifier && question.category === this.state.category))
        let questions=[];
        parent_questions.map((question,idx) => {
          questions[question.sk] = all_questions.filter(children => (children.parentId === question.contentId))
          if (this.isTagToBeRemovedFromQuestion(question))
          {
              //The tag has already been marked as removed and so now we need to remove tag and tag details from that question's parent.
                this.deleteTagNDetails(question);
          }
        })
        this.setState({
            questions,
            parent_questions
        })
    }

    fetchQuestionsForCategory=async()=>{
        let all_questions = await editSessionScript.fetchQuestionsByCategory(this.state.category, this.state.dateFilter);    
        console.log(all_questions);
        this.processQuestions(all_questions, 'PARENT');
    }
    
    isTagToBeRemovedFromQuestion=(question)=>{

        let parentId = question.contentId;
        let removedQuestions = this.state.removedQuestions;
        if (removedQuestions.find(element => element === parentId))
        {
            return true;
        }
        return false;
    }

    addQuestionToSession=(id)=>{
        
       // console.log(this.state.parent_questions[id]);
       debugger;
        let parent_questions = this.state.parent_questions;
        if (parent_questions[id].tags && this.isQuestionAlreadyInSession(parent_questions[id].tags))
            return;
        if (this.isQuestionInRemovedList(parent_questions[id].contentId))
            this.removeFromRemovedQuestionsList(parent_questions[id].contentId);    
        let session = this.state.session;
        let order = session[this.state.category] ? (session[this.state.category].length + 1) : 1;
        let question = this.state.questions[parent_questions[id].sk][0]; //take either India or US alone

        let categoryQuestion = {contentId: question.parentId, attributes: question, order:order};
        if (!session[this.state.category])
            session[this.state.category] = [];
        session[this.state.category].push(categoryQuestion);
        let tags = parent_questions[id].tags || [];
        
        tags.push(session.sessionId);
        let tag_details = parent_questions[id].tagDetails || [];
     debugger;
        let sessionType = this.state.session.sk.split('|')[4];
        //For all new sessions, we are using this tag. Assuming that we cannot create sessions in old Math Buddy curriculum anymore
        tag_details.push(`Level ${this.state.levelName} - ${this.state.program} - ${sessionType} - Session ${this.state.sessionNumber} - ${this.state.session.category} - ${this.state.session.categoryNumber} - ${this.state.subject} - ${this.state.curriculum} - ${this.state.year}`)
        parent_questions[id].tags = tags;
        parent_questions[id].tagDetails = tag_details;
        this.setState({
            parent_questions,
            session
        })                 
    }
    isQuestionAlreadyInSession =(tags) =>{
        for (let ii=0; ii<tags.length; ii++)
        {
            if (tags[ii] === this.state.session.sessionId)
                return true;
        }
        return false;
    }
    isQuestionInRemovedList =(parentId) =>{
        let removedQuestions = this.state.removedQuestions;
        for (let ii=0; ii<removedQuestions.length; ii++)
        {
            if (removedQuestions[ii] === parentId)
                return true;
        }
        return false;
    }

    removeFromRemovedQuestionsList=(parentId) =>{
        let removedQuestions = this.state.removedQuestions;
        let index = removedQuestions.findIndex(element => element === parentId);
        removedQuestions.splice(index,1);
        return;
    }
    showSection4=()=>{
        this.setState({section4:true, saved:''})
    }
    handleChange=(e)=>{
       // e.target.name = e.target.value;
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'category')
        {
            this.setState({section4:false, questions:[], parent_questions:[]})
        }
    }
    handleOrderChange=(e)=>{
        // e.target.name = e.target.value;
        let session = this.state.session;
        session[this.state.category][e.target.name].order = e.target.value;
        this.setState({session})
    }

    saveSessionDetails =async()=>{
        let session = this.state.session;
        //remove the trailing \n
        this.state.sessionTopics.replace(/\n+$/, "");
        let array = this.state.sessionTopics.split('\n');  
        session.sessionTopics = array.filter(function (e) { return e!="" });   
        session.sessionTime = this.state.sessionTime*60;
        session.order = Number(this.state.order);
        session.curriculum = this.state.curriculum;
        session.subject = this.state.subject;
        let date = new Date();
        date = date.toISOString(); 
        session.updatedAt = date;
        console.log(session.sessionTopics);
        this.setState({session, isLoading:true});  
        let res = await writeToDB(session);  
        console.log(res);  
        this.setState({isLoading:false}) ;  
    }
    saveSelection=async()=>{
        //Save the tags to questions and also remove the tags and save question if questions are removed.        
        let selectedQuestions = this.getQuestionsWithSessionTags();//parent_questions.filter(parent => parent.tags) || [];
        let removedQuestions = this.state.removedQuestions;
        let session = this.state.session;
        this.setState({session, loadingSave:true}); 
        if (selectedQuestions.length){
            selectedQuestions.map((question, idx)=>{
                console.log("Inside saved selection "+idx);
                if (this.isTagToBeRemovedFromQuestion(question))
                {
                    this.deleteTagNDetails(question);
                    this.removeFromRemovedQuestionsList(question.parentId);
                }

            })
        }
        //We need to push new files that are added
        let files = this.state.files;
        for (let ii=0;ii<files.length;ii++)
            selectedQuestions.push(files[ii])

        //the question is getting removed from the session. However since the parent is not present in the state (probably noone hit the GO button)
        //we need to make sure that the parent's tag is also removed for this question. 


        if (removedQuestions.length)
        {
            console.log("Inside removed question ");
            let res = await this.fetchParentAndRemoveTags(removedQuestions);
            console.log("removed the following questions ");
            console.log(res);
            for (let ii=0;ii<res.length;ii++)
                selectedQuestions.push(res[ii]);

            console.log("Before batch save");
            debugger;
            await this.batchSave(selectedQuestions);    
        }      
        else     
        {
            console.log("Inside else");
            debugger;
            await this.batchSave(selectedQuestions); 
        }       
        this.setState({session, loadingSave:false}); 
    }

    getQuestionsWithSessionTags=()=>{
        let parent_questions = this.state.parent_questions;
        let selectedQuestions =[];
        for (let ii=0;ii<parent_questions.length;ii++)
        {
            if (parent_questions[ii].tags && this.isQuestionAlreadyInSession(parent_questions[ii].tags))
                selectedQuestions.push(parent_questions[ii]);
        }
        console.log(selectedQuestions);
        return selectedQuestions;
    }
    batchSave =async(selectedQuestions)=>{
        
        let session = this.state.session;
        let allQuestions = [];
        for (let ii=0;ii<selectedQuestions.length;ii++)
        {
            allQuestions.push(selectedQuestions[ii]);
        }
        editSessionScript.orderContent(this.state.session, this.state.category);
        session.updatedAt= new Date().toISOString();
        allQuestions.push(session);
        this.setState({session, loadingSave:true}); 
        let res = await batchWriteToDB(allQuestions);
        this.setState({session, loadingSave:false}); 
        if(Object.keys(res.UnprocessedItems).length)
        {
            logger.logMessage("editSession.view","batchUpdateDB","There are a few UnProcessedItems"+res.UnprocessedItems.length);
            this.setState({saved:false});
        }    
        else
        {
            logger.logMessage("editSession.view","batchUpdateDB","All items have been written successfully")
            this.setState({saved:true});
        }
        this.setState({session});  
    }

    fetchParentAndRemoveTags = async(removedQuestions) =>{
        let selectedQuestions=[];
     //   let mapres = await removedQuestions.map(async(val, idx) => {
            //Fetch each question and remove the tag
            for (const question of removedQuestions){
                let res = await editSessionScript.fetchParentByQualifierSK(`PARENT|${question}`);
                
                console.log("Inside fetch parent and remove ");
                if (res.length)
                {
                    console.log(res[0]);
                    if (res[0].tags !== undefined)
                    {
                        this.deleteTagNDetails(res[0]);
                        selectedQuestions.push(res[0]);
                    }
                }
            }
        console.log("before returning from fetchParentAndRemove");
        console.log(selectedQuestions);
        return selectedQuestions;
    }
    removeQuestion=(idx)=>{
        debugger;
        let session = this.state.session;
        let category_questions = session[this.state.category];
        let deletedQuestion = category_questions.splice(idx,1);
        let removedQuestions =this.state.removedQuestions;
        removedQuestions.push(deletedQuestion[0].attributes.parentId);
        if (this.state.parent_questions.length)
        {
            let myParent = this.getParent(deletedQuestion[0]);
            console.log("myParent "+myParent);
            if (myParent[0].tags)
                this.deleteTagNDetails(myParent[0]);
        }
        session[this.state.category] = category_questions;
        this.setState({session, removedQuestions});
    }
    removeLesson=(idx)=>{
        
        let session = this.state.session;
        let lessons = session[this.state.category];
        lessons.splice(idx,1);
        this.setState({session})
    }
    removeVideo=(idx)=>{
        
        let session = this.state.session;
        let videos = session[this.state.category];
        videos.splice(idx,1);
        this.setState({session})
    }

    getParent=(question)=>{
        let parent_questions = this.state.parent_questions;
        let ret = parent_questions.filter(parent=>parent.contentId === question.contentId);
        console.log("Returning the filtered question "+ret);
        return ret;
    }

    deleteTagNDetails=(question)=>{
        let tag = this.state.session.sessionId;
        let tag_details1;
        let tag_details2='';
        let sessionType = this.state.session.sk.split('|')[4];
        if (this.state.year === '' )
            tag_details1 = `Level ${this.state.levelName} - Session ${this.state.sessionNumber} - ${this.state.session.category} - ${this.state.session.categoryNumber}`;
        else
        {
            tag_details1 = `Level ${this.state.levelName} - Session ${this.state.sessionNumber} - ${this.state.session.category} - ${this.state.session.categoryNumber} - ${this.state.subject} - ${this.state.curriculum} - ${this.state.year}`;
            tag_details2 = `Level ${this.state.levelName} - ${this.state.program} - ${sessionType} - Session ${this.state.sessionNumber} - ${this.state.session.category} - ${this.state.session.categoryNumber} - ${this.state.subject} - ${this.state.curriculum} - ${this.state.year}`;

        }
        for (let ii=0; ii<question.tags.length; ii++)
        {
            if (question.tags[ii] === tag)
            {
                question.tags.splice(ii,1);
                break;
            }
        }
        for (let ii=0; ii<question.tagDetails.length; ii++)
        {
            if (question.tagDetails[ii] === tag_details1 || question.tagDetails[ii] === tag_details2)
            {
                question.tagDetails.splice(ii,1);
                break;
            }
        }
        return;
    }

    updateQuestion=async(idx)=>{
        let session = this.state.session;
        let questionToBeUpdated = session[this.state.category][idx];
        let fetchedQuestion = await editSessionScript.fetchQuestionByPkSK(
                                    questionToBeUpdated.attributes.pk, 
                                    questionToBeUpdated.attributes.sk);
        console.log(fetchedQuestion);
        session[this.state.category][idx]={contentId: fetchedQuestion[0].parentId, attributes: fetchedQuestion[0]}
        this.setState({session});
    }

    //Add the skill picked to lessons
    addSkillToLessons=()=>{
        if (this.lessonAlreadyAddedToSession())
            return;
        let session = this.state.session;
        let order = session[this.state.category] ? (session[this.state.category].length + 1) : 1;
        if (!session[this.state.category])
            session[this.state.category] = [];
        session[this.state.category].push({contentId:this.state.skillId, attributes:{skillName: this.state.skillName},order:order});
        this.setState({session});
    }
    lessonAlreadyAddedToSession=()=>{
        let session=this.state.session;
        let lessons = session[this.state.category] || [];
        let found = lessons.filter(lesson=>lesson.contentId === this.state.skillId);
        if (found.length === 0)
            return false;
        return true;    
    }
    addVideos=()=>{
        let session = this.state.session;
        let order = session[this.state.category] ? (session[this.state.category].length + 1) : 1;
        if (!session[this.state.category])
            session[this.state.category] = [];
        session[this.state.category].push({contentId:this.state.videoLink, attributes:{videoName: this.state.videoName, country:this.state.video_country},order:order});
        this.setState({session});
    }
    addFiles=async()=>{
        let session = this.state.session;
        let order = session[this.state.category] ? (session[this.state.category].length + 1) : 1;
        if (!session[this.state.category])
            session[this.state.category] = [];
        //upload file to s3 and get the name of the file

        session[this.state.category].push({fileLink:this.state.fileId, contentType: folder, attributes:{fileName:this.state.fileName, skillId:this.state.skillId, skillName: this.state.skillName, country_language:`${this.state.file_country+'|EN'}`},order:order});
        this.setState({session});
        //Add the pdf to a skill and store it in another row in the database
        var jsonObject = this.createFileObject(folder);
        let files = this.state.files;
        files.push(jsonObject);
        this.setState({files})
    }
    fetchDocumentsForSkill = async()=>{
        let documents = await editSessionScript.fetchDocuments(this.state.skillId);
        this.processDocuments(documents, 'PARENT|DOCUMENT');
    }

    disableReviewStatus=()=>{
        if (this.disableForProduction() && this.state.session.statusName !== "FOR REVIEW")
            return false;
        return true;
    }
    //developer or admin can make the session production ready
    disableForProduction =() =>{
        if ((userRights.isUserAdmin() || userRights.isUserDeveloper()) && this.allQuestionsInProduction() && !this.isSessionProductionReady())
            return false;
        return true;
    }
    isSessionProductionReady=()=>{
        if (this.state.session.statusName === "PRODUCTION|SESSION")
            return true;
        return false;
    }
    allQuestionsInProduction=()=>{
        
        let session = this.state.session;
        if (session.IB)
        {
            for (const IB of session.IB)
            {
                if (IB.attributes.statusName !== "PRODUCTION")
                    return false;
            }
        }
        if (session.RF)
        {
            for (const RF of session.RF)
            {
                if (RF.attributes.statusName !== "PRODUCTION")
                    return false;
            }
        }
        if (session.MR)
        {
            for (const MR of session.MR)
            {
                if (MR.attributes.statusName !== "PRODUCTION")
                    return false;
            }
        }
        if (session.LR)
        {
            for (const LR of session.LR)
            {
                if (LR.attributes.statusName !== "PRODUCTION")
                    return false;
            }
        }
        return true;
    }
    setStatus=async(status)=>{        
        let session = this.state.session;
        session.statusName =status;
        let date = new Date();
        session.updatedAt = date.toISOString();        
        this.setState({session, isLoading:true});  
        let res = await writeToDB(session);  
        console.log(res);  
        this.setState({isLoading:false}) ;    
    }
    setSkillId = (skillId) => {
        this.setState({
          skillId
        })
    }
    setSkillName = (skillName) =>{
        this.setState({
          skillName
        })
    }
    canEditQuestion=(idx) =>{
        let session = this.state.session;
        let questionToBeUpdated = session[this.state.category][idx];
        return userRights.canEdit(questionToBeUpdated.attributes);
    }
    canViewQuestion=(idx)=>{
        return true;
/*         let session = this.state.session;
        let questionToBeUpdated = session[this.state.category][idx];
        return userRights.canView(questionToBeUpdated.attributes);   */
    }
    viewQuestion=async(val, idx)=>
    {
        let questionInView =[];
        let parentInView=[];
        if (Object.keys(this.state.questions).length)
        {
            //have hardcoded the value of 0 for deep copy. Need a fix for the same to copy
            questionInView = {...this.state.questions[`PARENT|${val.contentId}`][0]};
            parentInView = this.state.parent_questions.filter(question=>question.sk === `PARENT|${val.contentId}`);  
        }
        else //if the question has to be fetched from database directly and not stored locally
        { 
            console.log("From db");           
            let groupOfQuestions = await editSessionScript.fetchByGroupId(val.contentId);
            parentInView = groupOfQuestions.filter(parent=>parent.contentId === val.contentId);
            questionInView = groupOfQuestions.filter(question=> question.country_language === val.attributes.country_language);
            questionInView = questionInView[0];
        }
        parentInView = parentInView[0];
        if (questionInView.qImage)
        {
          let image = await getFromS3(questionInView.qImage);
          questionInView.qImage = image;
        }
        if (questionInView.eImage)
        {
          let image = await getFromS3(questionInView.eImage);
          questionInView.eImage = image;
        }
        if (questionInView.oImages)
        {
          let images=[];
          for (var ii=0;ii<questionInView.oImages.length;ii++)
          {
            images[ii] = await getFromS3(questionInView.oImages[ii]);
            
          }
          questionInView.oImages = images;
        }      

        this.updateSessionQuestion(idx, questionInView, val.order);
        this.setState({questionInView, parentInView});
        this.setView(true);
    }
    editQuestion=async(val, idx) => {
        let questionInView =[];
        let parentInView=[];
        let secondChild;
        if (Object.keys(this.state.questions).length)
        {
            let questions = this.state.questions;
            let selectedQuestion = questions[`PARENT|${val.contentId}`];

            questionInView = selectedQuestion.filter(question=> question.sk === val.attributes['sk']);
            if (questionInView.country_language === 'IN|EN')
                secondChild = selectedQuestion.filter(question => question.parentId === val.contentId && question.country_language === 'US|EN')
            else
                secondChild = selectedQuestion.filter(question => question.parentId === val.contentId && question.country_language === 'IN|EN')

            parentInView = this.state.parent_questions.filter(question=>question.sk === `PARENT|${val.contentId}`);  
        }
        else //if the question has to be fetched from database directly and not stored locally
        {            
            let groupOfQuestions = await editSessionScript.fetchByGroupId(val.contentId); 
            questionInView = groupOfQuestions.filter(question=> question.country_language === val.attributes.country_language);          
            parentInView = groupOfQuestions.filter(parent=>parent.contentId === val.contentId);
            if (questionInView[0].copyQuestion === true)
            {
                if (questionInView[0].country_language === 'IN|EN')
                    secondChild = groupOfQuestions.filter(question => question.country_language === 'US|EN')
                else
                    secondChild = groupOfQuestions.filter(question => question.country_language === 'IN|EN')
  
            }
        }
        
        questionInView = questionInView[0];
        secondChild = secondChild[0];
        parentInView = parentInView[0];  
        let skillName = questionInView.skillName;
        
        this.updateSessionQuestion(idx, questionInView, val.order);
        this.setState({
            questionInView,
            parentInView,
            secondChild,
            skillName,
            edit:true
        }) 
    }
    updateSessionQuestion=(idx, question, order) => {
        let session = this.state.session;
        session[this.state.category][idx]={contentId: question.parentId, attributes: question, order:order}
        this.setState({session});
    }
    render() 
    {      
        let questions = this.state.questions;
        let parent_questions = this.state.parent_questions;
        let session = this.state.session;
        if (this.state.isLoading){
            return(<div className='w-100 d-flex justify-content-center align-items-center' style={{color:"#545cad", height:"100%"}}><FontAwesomeIcon icon="fa-solid fa-spinner" spin size="lg" /> Updating session....</div>)
        }
        if (this.state.view){
            return (
                <ViewQuestion setView={this.setView} parent ={this.state.parentInView} question={this.state.questionInView}/>
            )
        }
        if (this.state.edit)
        {
          return(<div>
                   <EditQuestion  setEdit={this.setEdit} 
                       question={this.state.questionInView} 
                       secondChild={this.state.secondChild}
                       parent ={this.state.parentInView}
                       isPublished ={this.state.isPublished}
                       canReviewQuestion={userRights.isUserReviewer()}
                       canDesignQuestion={userRights.isUserDesigner()}
                       skillName = {this.state.skillName}
                        />
                 </div>)
        } 
        return (
            <div class="mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
                <div class="border border-2 container2 rounded-3 bg-white ms-2 d-flex flex-column justify-content-center align-items-center">
                <div class="w-100 row header rounded-top pt-2 pb-2 ps-4 pe-4">
                <div className='col-6 col-sm-4 order-1 d-flex justify-content-start align-items-center '><h4 className="modal-title text-white headFont">Edit The Session</h4></div>
                <div className='col-12 col-sm-4 order-3 order-sm-2  d-flex justify-content-center align-items-center '><h4 className="modal-title text-white headFont">{`Level ${this.state.levelName} - Session ${this.state.sessionNumber}`}</h4></div>                              
                <div class="col-6 col-sm-4 order-2  order-sm-3 d-flex justify-content-around align-items-center ">
                    <button className='btn btn-success rounded-3' disabled={this.disableForProduction()}
                    onClick={()=>{this.setStatus('PRODUCTION|SESSION')}}><FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" color={"white"} size="lg"/><span className="modal-title text-white"> PR</span></button>
                   <button className='btn btn-danger rounded-3'  disabled={this.disableReviewStatus()} onClick={()=>{this.setStatus('FOR REVIEW')}}><FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" color={"white"} size="lg"/><span className="modal-title text-white"> Review</span></button>
                    <FontAwesomeIcon icon="fa-solid fa-circle-arrow-left" color={"white"} size="2x" onClick={this.props.goBack}/>
                </div> 
            </div>
            <form style={{width:"95%"}} className='mt-4' onSubmit={this.handleSubmit} onChange={this.handleChange}>
                <div>   
                    <div className='w-100 row mt-2'>
                        <div className='col-12 col-md-4  mt-2'>
                            <div className='w-100 row'>
                            <div className='col-9 d-flex justify-content-start justify-content-sm-start ps-4  align-items-center '><h5 className='mt-2 font'> Edit time (in minutes <FontAwesomeIcon icon="fa-solid fa-clock" color={"#545cad"}/>): </h5> </div>
                            <div className='col-3 d-flex justify-content-center align-items-center '><input className='form-control' type="number" name="sessionTime" value ={this.state.sessionTime} /> </div>
{/*                             <div className='col-4 col-sm-4 mt-2 d-flex justify-content-center align-items-center'><Button className="btn-com" onClick={this.saveSessionTime}>Update Time</Button></div>
 */}                                                        
                            <div className='col-9 mt-2 d-flex justify-content-start justify-content-sm-start ps-4 align-items-center'><h5 className='font'>Edit session order: </h5></div> 
                            <div className='col-3 d-flex justify-content-center align-items-center '><input class='form-control' type="number" name="order" 
                            value ={this.state.order} /></div>  
{/*                             <div className='col-4 col-sm-4 mt-2 d-flex justify-content-center align-items-center '><Button className="btn-com" onClick={this.saveSessionOrder}>Update Order</Button></div>                              
 */}                            </div>
                        </div>

                        <div className='col-12 col-md-6 mt-2 mb-2'>                                
                            <div className='w-100 row'>
                                <div className='col-12 d-flex justify-content-start ps-4  align-items-center'><h5 className='font'>Add Topics for Session: </h5></div>
                                <div className='col-12 d-flex justify-content-center align-items-center '><textarea class='form-control' type="text" name="sessionTopics" placeholder="Add topics to any session or assessment"
                                value ={this.state.sessionTopics} /></div>       
                            </div>
                        </div>

                        <div className='col-12 col-sm-2 mb-2 d-flex justify-content-center align-items-center '><Button className="btn btn-success" onClick={this.saveSessionDetails}>Save Session</Button></div>

                    </div>
                    <hr></hr>
                    <div className='w-100 row'>    
                    <div className='col-12 d-flex justify-content-center align-items-center mb-2 mt-2'><h6>Section 2: Information</h6></div>
                        <div className='ps-4 col-6 col-sm-2 d-flex justify-content-start justify-content-sm-around align-items-center'>         
                        <h6 className='fontm'>Ice Breaker: </h6>&nbsp;
                        <h6 className='fontm'>{this.state.session["IB"] && this.state.session["IB"].length}</h6></div>
                        <div className='ps-4  col-6 col-sm-2 d-flex justify-content-start justify-content-sm-around align-items-center'>         
                        <h6 className='fontm'>Rapid Fire: </h6>&nbsp;
                        <h6 className='fontm'>{this.state.session["RF"] && this.state.session["RF"].length}</h6></div>
                        <div className='ps-4  col-6 col-sm-2 d-flex justify-content-start justify-content-sm-around align-items-center'>         
                        <h6 className='fontm'>Math Reasoning: </h6>&nbsp;
                        <h6 className='fontm'>{this.state.session["MR"] && this.state.session["MR"].length}</h6></div>
                        <div className='ps-4  col-6 col-sm-2 d-flex justify-content-start justify-content-sm-around align-items-center'>         
                        <h6 className='fontm'>Logical Reasoning: </h6>&nbsp;
                        <h6 className='fontm'>{this.state.session["LR"] && this.state.session["LR"].length}</h6></div>
                        <div className='ps-4  col-6 col-sm-2 d-flex justify-content-start justify-content-sm-around align-items-center'>         
                        <h6 className='fontm'>Lessons: </h6>&nbsp;
                        <h6 className='fontm'>{this.state.session["LN"] && this.state.session["LN"].length}</h6></div>
                        <div className='ps-4  col-6 col-sm-2 d-flex justify-content-start justify-content-sm-around align-items-center'>         
                        <h6 className='fontm'>Videos: </h6>&nbsp;
                        <h6 className='fontm'> {this.state.session["VD"] && this.state.session["VD"].length || 0}</h6></div>                                                   
                    
                    </div>
                    <hr></hr>
                    <div className='w-100 row mb-4'>
                    <div className='col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end align-items-center'><h6>Section 3: Choose a category to add questions</h6></div>                            
                    <div className='col-12 col-sm-6 d-flex justify-content-center align-items-center mt-2'>
                            <select onChange={this.handleChange} class="form-select w-50" value={this.state.category} name="category">
                                <option selected value="IB">ICEBREAKER</option>
                                <option  value="RF">RAPID FIRE</option>
                                <option value="MR">MATHEMATICAL REASONING</option>
                                <option value="LR">LOGICAL REASONING</option>
                                <option value="PR">PROJECT</option>
                                <option value="RV">REVISION</option>
                                <option value="LN">LESSONS</option>
                                <option value="VD">VIDEOS</option>
                                <option value="FI">FILES</option>
                                </select>
                        </div>
                        </div>
                    {(this.state.category !== "LN") && ( this.state.category !== "VD") &&( this.state.category !== "FI") &&  (     
                            <div class="table-responsive border border-2 rounded-top bg-white">
                            <table class='table bg-white table-borderless'>
                                    <thead class="table-primary">
                                    <tr style={{ marginTop: "2vmin", align: "center" }}>
                                    <th  scope="col">S.No</th>
                                    <th  scope="col">Question</th>
                                   
                                    <th  scope="col">Skill</th>
                                    <th scope ="col">Order</th>
                                    <th scope="col">Status</th>
                                    <th  scope="col" style={{ textAlign: "center" }}>Action</th>
                                </tr>
                            </thead>  
                            { (session[this.state.category]) && session[this.state.category].map((val, idx)=>{ 
                                
                                let rows=[];                                   
                                    rows.push(
                                        <tr class="border-top" style={{ marginTop: "2vmin", alignItems: "center" }}>
                                            <td className="col-1">{idx+1}</td>
                                            {/* {(this.canViewQuestion(idx)) && ( */}
                                                <td className="col-4" style={{cursor:"pointer", color:"blue", 
                                                textDecoration: "underline"}} 
                                                onClick={()=>this.viewQuestion(val, idx)}>{val.attributes['question']}</td>
                                          {/*   )} */}
                           {/*                  {(!this.canViewQuestion(idx)) && (
                                                <td className="col-4" style={{cursor:"pointer", color:"blue"}}>
                                                    {val.attributes['question']}</td>
                                            )} */}
                                            
                                            <td className="col-3" >{val.attributes['skillName']}</td>
                                            <td className ="col-1">
                                                <input class='form-control w-75' value={session[this.state.category][idx].order} name={idx} onChange={this.handleOrderChange} 
                                                    type="number" placeholder='Order'/>
                                            </td>
                                            <td className="col-2">{val.attributes['statusName']}</td>
                                            
                                            <td className="col-1">
                                                <tr className='d-flex align-items-center justify-content-around' style={{cursor:"pointer"}}>
                                                    {((!userRights.isUserReviewer()) && ((this.state.skillName === '') || (this.state.skillName === val.attributes['skillName']))) && (
                                                        <td onClick={()=>this.removeQuestion(idx)}><FontAwesomeIcon icon="fa-times" size="lg" color={"red"}/></td>
                                                    )}                                                        
                                                    <td onClick={()=>this.updateQuestion(idx)}><FontAwesomeIcon icon="fa-arrow-rotate-right" size="lg" color={"green"}  /></td>
                                                    {(this.canEditQuestion(idx)) && (val.attributes['copyQuestion'] === "true" || val.attributes["copyQuestion"] === true) && (
                                                        <td onClick={()=>this.editQuestion(val,idx)}><FontAwesomeIcon icon="fa-edit" size="lg" color={"blue"}  /></td>)}
                                                    {(this.canEditQuestion(idx)) && (val.attributes['copyQuestion'] === "false" || val.attributes['copyQuestion'] === false) && (
                                                        <td>US and India are separate. Cannot edit here</td>)}

                                                </tr>                                                    
                                            </td>
                                        </tr> )                                        
                                    return <tbody>{rows}</tbody>
                                })
                            }                 
                        </table>
                            </div>
                    )}
                    {(this.state.category === "LN") && (
                        <div class="table-responsive border border-2 rounded-top bg-white">
                        <table class='table bg-white table-borderless'>
                                <thead class="table-primary">
                                    <tr>
                                        <th  scope="col">S.No</th>
                                        <th  scope="col">Lessons</th>
                                        <th scope ="col">Order</th>
                                        <th  scope="col" style={{textAlign:'center'}}>Action</th>
                                    </tr>
                                </thead> 
                                {(session[this.state.category]) && 
                                    session[this.state.category].map((val, idx)=>{ 
                                        let rows=[];
                                        rows.push(
                                            <tr class="border-top" style={{ marginTop: "2vmin", alignItems: "center" }}>
                                                <td scope="col">{idx+1}</td>
                                                <td scope="col">{val.attributes["skillName"]}</td>
                                                <td scope ="col">
                                                    <input class='form-control w-25' value={session[this.state.category][idx].order} name={idx} onChange={this.handleOrderChange} 
                                                    type="number" placeholder='Order'/>
                                                </td>                                       
                                                <td style={{cursor:"pointer",textAlign:'center'}} onClick={()=>this.removeLesson(idx)}><FontAwesomeIcon icon="fa-times" size="lg" color={"red"}/></td>
                                            </tr>
                                        )
                                        return <tbody>{rows}</tbody>
                                    })
                                }
                            </table>
                        </div>
                    )}
                    {(this.state.category === "VD") && (session[this.state.category]) && (
                        <div class="table-responsive border border-2 rounded-top bg-white">
                        <table class='table bg-white table-borderless'>
                                <thead class="table-primary">
                                    <tr>
                                        <th  scope="col">S.No</th>
                                        <th  scope="col">Video Name</th>
                                        <th scope ="col">Video Link</th>
                                        <th scope ="col">Country</th>
                                        <th  scope="col">Order</th>
                                        <th  scope="col" style={{textAlign:'center'}}>Action</th>
                                    </tr>
                                </thead> 
                            {session[this.state.category].map((val,idx)=>{
                                let rows=[];
                                rows.push(
                                    <tr class="border-top" style={{ marginTop: "2vmin", alignItems: "center" }}>
                                        <td scope="col">{idx+1}</td>
                                        <td scope="col">{val.attributes["videoName"]}</td>
                                        <td scope="col">{val["contentId"]}</td>
                                        <td scope="col">{val.attributes["country"]}</td>
                                        <td scope ="col">
                                        <input class='form-control w-25' value={session[this.state.category][idx].order} name={idx} onChange={this.handleOrderChange} 
                                            type="number" placeholder='Order'/>
                                        </td>                                       
                                        <td style={{cursor:"pointer",textAlign:'center'}} onClick={()=>this.removeVideo(idx)}><FontAwesomeIcon icon="fa-times" size="lg" color={"red"}/></td>
                                    </tr> 
                                )
                                return <tbody>{rows}</tbody>
                            })}
                            </table>
                        </div>
                    )}
                    {(this.state.category === "FI") && (session[this.state.category]) && (
                        <div class="table-responsive border border-2 rounded-top bg-white">
                        <table class='table bg-white table-borderless'>
                                <thead class="table-primary">
                                    <tr>
                                        <th  scope="col">S.No</th>
                                        <th  scope="col">Skill Name</th>
                                        <th  scope="col">File Name</th>
                                        <th scope ="col">Upload</th>
                                        <th scope ="col">Country</th>
                                        <th  scope="col">Order</th>
                                        <th  scope="col" style={{textAlign:'center'}}>Action</th>
                                    </tr>
                                </thead> 
                            {session[this.state.category].map((val,idx)=>{
                                let rows=[];
                                rows.push(
                                    <tr class="border-top" style={{ marginTop: "2vmin", alignItems: "center" }}>
                                        <td scope="col">{idx+1}</td>
                                        <td scope="col">{val.attributes["skillName"]}</td>
                                        <td scope="col">{val.attributes["fileName"]}</td>
                                        <td scope="col">{val.attributes["fileLink"]}</td>
                                        <td scope="col">{val.attributes["country_language"]}</td>
                                        <td scope ="col">
                                        <input class='form-control w-25' value={session[this.state.category][idx].order} name={idx} onChange={this.handleOrderChange} 
                                            type="number" placeholder='Order'/>
                                        </td>    
                                        {((!userRights.isUserReviewer()) && ((this.state.skillName === '') || (this.state.skillName === val.attributes['skillName']))) && (                                   
                                        <td style={{cursor:"pointer",textAlign:'center'}} onClick={()=>this.removeQuestion(idx)}><FontAwesomeIcon icon="fa-times" size="lg" color={"red"}/></td>
                                        )}
                                        </tr> 
                                )
                                return <tbody>{rows}</tbody>
                            })}
                            </table>
                        </div>
                    )} 
                        {(!this.state.section4) && (
                       <div className='d-flex justify-content-center align-items-center mb-2 mt-2'>
                            {(this.state.category !== "LN") && (this.state.category !== "VD")  && (this.state.category !== "FI") && (<h6>Want to add more questions?</h6>)}
                            {(this.state.category === "LN") && (<h6>Want to add more lessons?</h6>)}
                            {(this.state.category === "VD") && (<h6>Want to add videos?</h6>)}
                            {(this.state.category === "FI") && (<h6>Want to add files?</h6>)}
                            &nbsp;&nbsp;&nbsp;<Button className="btn-com" onClick={this.showSection4}>Go</Button>
                        </div>
                        )}
                        {(this.state.section4) && (
                        <div className='d-flex justify-content-center align-items-center mb-2 mt-2'>
                            <h6>Save the selection</h6>
                            &nbsp;&nbsp;&nbsp;<Button className="btn btn-success" onClick={this.saveSelection}>Save</Button>&nbsp;&nbsp;&nbsp;
                            {(this.state.saved && !this.state.loadingSave) && (<h6 style={{color:"green"}}>Saved successfully</h6>)}
                            {(this.state.loadingSave) && (<FontAwesomeIcon icon="fa-solid fa-spinner" spin size="lg" />)}
                            {(this.state.saved === false) && (<h6 style={{color:"red"}}>Could not save...</h6>)}                            
                        </div>
                        )}
                        <hr/>
                        {(
                            (this.state.section4) && (this.state.category !== "LN") && (this.state.category !== "VD") && (this.state.category !== "FI") && (
                                <div>
                                    <div className='w-100 row'>                                
                                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                                        <h6>Section 4: Show questions</h6>
                                    </div>
                                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'> 
                                        
                                            <select class="form-select w-50" value={this.state.questionFilter} name="questionFilter">
                                                <option  value="all">All</option>
                                                <option  selected value="available">Available</option>
                                                <option  value="production">Production</option>
                                            </select>
                                        </div>
                                    <div className='col-10 col-md-5 d-flex justify-content-center align-items-center'> 
                                        <SkillList class="form-group"                                   
                                            setSkillId={this.setSkillId}
                                            setSkillName={this.setSkillName}                    
                                        />  
                                                                            
                                        </div>
                                        <div className='mt-2 col-2 col-md-1 d-flex justify-content-center align-items-center'>
                                            <Button className="btn-com rounded" type="button" onClick={this.fetchQuestions} >GO</Button>
                                        </div>                                     
                                        <div className='col-10 col-md-5 d-flex justify-content-center align-items-center'>
                                            <select class="form-select" value={this.state.dateFilter} name="dateFilter">
                                                <option  selected value="2">Last 2 days</option>
                                                <option  value="7">Last 7 days</option>
                                                <option  value="15">Last 15 days</option>
                                                <option  value="30">Last 30 days</option>
                                            </select>
                                        </div>
                                        <div className='mt-2 mb-2 col-2 col-md-1 d-flex justify-content-center align-items-center'>
                                            <Button className="btn-com" onClick={this.fetchQuestionsForCategory}>GO</Button>
                                        </div>     
                                    </div>
                                    {(Object.keys(questions).length) && (
                                        <div class="table-responsive border border-2 rounded-top bg-white mb-4">
                                        <table class='table bg-white table-borderless'>
                                        <thead class="table-light">
                                            <tr>
                                                <th  className="col-1">S.No</th>
                                                <th  className="col-3">Question</th>
                                                <th  className="col-2">Skill</th>
                                                <th  className="col-2">Sessions Used</th>
                                                <th  className="col-2">Tag Details</th>
                                                <th  className="col-1">Type</th>
                                                <th  className="col-1">Action</th>
                                            </tr>
                                        </thead>                                 
                                        {
                                            parent_questions.map((val, idx)=>{ 
                                            if ((this.state.questionFilter === 'all') || 
                                                ((!val.tags || !val.tags.length) && this.state.questionFilter === "available") || 
                                                (val.statusName === "PRODUCTION" && this.state.questionFilter === "production")){
                                                
                                                let rows =[];                                                                    
                                                let child_content = questions[val.sk][0];  
                                                
                                                rows.push(
                                                    <tr class="border-top" style={{ marginTop: "2vmin", alignItems: "center" }}>
                                                    <td scope="col" ms={1}>{idx+1}</td>
                                                    <td scope="col" ms={4}>{child_content.question}</td>
                                                    <td scope="col" ms={3}>{val.skillName}</td>
                                                    <td scope="col" ms={2}>
                                                    {(val.tags) && (val.tags.length) && (
                                                        val.tags.map((tag,ii) => {
                                                            let subrows=[];
                                                            subrows.push(
                                                                <tr>
                                                                    <td scope="col" ms={12} >{tag}</td>
                                                                </tr>
                                                            )
                                                            return subrows;
                                                        })
                                                    )}  
                                                    </td>  
                                                    <td scope="col" ms={2}>
                                                    {(val.tagDetails) && (val.tagDetails.length) && (
                                                        val.tagDetails.map((tagDetail,ii) => {
                                                            let subrows=[];
                                                            subrows.push(
                                                                <tr>
                                                                    <td scope="col" ms={12} >{tagDetail}</td>
                                                                </tr>
                                                            )
                                                            return subrows;
                                                        })
                                                    )}  
                                                    </td>                            
                                                    <td scope="col" ms={1}>{val.category}</td>
                                                    <td scope="col" onClick={()=>this.addQuestionToSession(idx)}><FontAwesomeIcon icon="fa-plus" size="lg" color={"green"}/> Add</td>
                                                </tr> )  
                                                return <tbody>{rows}</tbody>   
                                            }
                                            })
                                        }   
                                    </table>
                                    </div>                      
                                    )}                                    
                                </div>
                            )
                        )}
                        

                        {(
                            (this.state.section4) && (this.state.category === "LN") && (
                                <div className='w-100 row mb-4'>                                
                                <div className='col-12 col-md-10 d-flex justify-content-center align-items-center'>
                                        <SkillList class="form-select"                                   
                                            setSkillId={this.setSkillId}
                                            setSkillName={this.setSkillName}                    
                                        />  
                                    </div>
                                    <div className='col-12 col-md-2 d-flex justify-content-center align-items-center'>
                                        <Button className="btn-com" onClick={this.addSkillToLessons}>ADD</Button>
                                    </div>
                                </div>
                                
                            )
                        )}
                        
                        {(
                            (this.state.section4) && (this.state.category === "VD") && (
                                <div className='w-100 row mb-4'>                                
                                    <div className='col-6 col-md-3 d-flex justify-content-center align-items-center'>
                                        <input type="text" class="form-control" name="videoName" value={this.state.videoName} placeholder="Video name" />
                                    </div>
                                    <div className='col-6 col-md-5 d-flex justify-content-center align-items-center'>
                                        <input type="text"  class="form-control" name="videoLink" value={this.state.videoLink} placeholder="Video link" />
                                        </div>  
                                    <div className='col-6 col-md-2 d-flex justify-content-center align-items-center'>
                                        <select onChange={this.handleChange} class="form-select" value={this.state.video_country} name="video_country">
                                            <option selected value="IN">INDIA</option>
                                            <option  value="US">United States</option>
                                        </select>                                            
                                    </div>                              
                                    <div className='col-6 col-md-1 d-flex justify-content-center align-items-center'>
                                        <Button className="btn-com" onClick={this.addVideos}>ADD</Button>
                                    </div>
                                </div>
                            )
                        )}
                                                {(
                            (this.state.section4) && (this.state.category === "FI") && (
                                <div className='w-100 row mb-4'>     
                                    <div className='col-12 col-md-10 d-flex justify-content-center align-items-center'>
                                        <SkillList class="form-select"                                   
                                            setSkillId={this.setSkillId}
                                            setSkillName={this.setSkillName}                    
                                        />  
                                    </div>                           
                                                             
                                    <div className='col-6 col-md-1 d-flex justify-content-center align-items-center'>
                                        <Button className="btn-com" disabled = {!this.state.skillId} onClick={this.fetchDocumentsForSkill}>GO</Button>
                                    </div>
                                    {(Object.keys(questions).length) && (
                                        <div class="table-responsive border border-2 rounded-top bg-white mb-4">
                                        <table class='table bg-white table-borderless'>
                                        <thead class="table-light">
                                            <tr>
                                                <th  className="col-1">S.No</th>
                                                <th  className="col-3">File name</th>
                                                <th  className="col-2">Skill</th>
                                                <th  className="col-2">Sessions Used</th>
                                                <th  className="col-2">Tag Details</th>
                                                <th  className="col-1">Type</th>
                                                <th  className="col-1">Action</th>
                                            </tr>
                                        </thead>                                 
                                        {
                                            parent_questions.map((val, idx)=>{ 
                                           
                                               
                                                let rows =[];                                                                    
                                                let child_content = questions[val.sk][0];  
                                                
                                                rows.push(
                                                    <tr class="border-top" style={{ marginTop: "2vmin", alignItems: "center" }}>
                                                    <td scope="col" ms={1}>{idx+1}</td>
                                                    <td scope="col" ms={4}>{child_content.fileName}</td>
                                                    <td scope="col" ms={3}>{child_content.skillName}</td>
                                                    <td scope="col" ms={2}>
                                                    {(val.tags) && (val.tags.length) && (
                                                        val.tags.map((tag,ii) => {
                                                            let subrows=[];
                                                            subrows.push(
                                                                <tr>
                                                                    <td scope="col" ms={12} >{tag}</td>
                                                                </tr>
                                                            )
                                                            return subrows;
                                                        })
                                                    )}  
                                                    </td>  
                                                    <td scope="col" ms={2}>
                                                    {(val.tagDetails) && (val.tagDetails.length) && (
                                                        val.tagDetails.map((tagDetail,ii) => {
                                                            let subrows=[];
                                                            subrows.push(
                                                                <tr>
                                                                    <td scope="col" ms={12} >{tagDetail}</td>
                                                                </tr>
                                                            )
                                                            return subrows;
                                                        })
                                                    )}  
                                                    </td>                            
                                                    <td scope="col" ms={1}>{val.category}</td>
                                                    <td scope="col" onClick={()=>this.addQuestionToSession(idx)}><FontAwesomeIcon icon="fa-plus" size="lg" color={"green"}/> Add</td>
                                                </tr> )  
                                                return <tbody>{rows}</tbody>   
                                            
                                            })
                                        }   
                                    </table>
                                    </div>                      
                                    )}  
                                </div>
                            )
                        )}
                    </div>

            </form>
            </div>
            </div>
        );
    }    
}