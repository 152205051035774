import React, {Component} from 'react';
import userRights from '../../scripts/userRights';
import {Link} from 'react-router-dom' 

export default class Home extends Component {

    render(){
        return (
          <div style={{textAlign:"center", marginTop:"2vmin"}}>
            {(!userRights.isUserDesigner()) && (
                <div>
                     <Link style={linkStyle} to='/dashboard' > Dashboard </Link>
                </div>                
            )}
            
         </div>
         );
    }
}
const linkStyle={
    color:'blue'
}
