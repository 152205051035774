import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import ViewSession from '../session/view.view';
import dashboardScript from './dashboard.script';

export default class Dashboard extends Component {
  
    constructor(props) {
        super(props);
        this.state={
            level:'C',
            view:false,
            sessionType: 'LIVE',
            curriculum: 'CBSE',
            subject: 'MATH',
            levelwiseSessions:'',
            programName: 'RISE',
            qualifier:'SESSION',
            dataFetched:false,
            sessionYear: 'None'
        }
    }
    showSessionData = async() => {
        let levelwiseSessions;
        if (this.state.sessionYear === 'None')
        {
            let sk = `${this.state.programName}|${this.state.sessionType}|${this.state.level}`;
            levelwiseSessions = await dashboardScript.fetchAllSessions(this.state.qualifier, sk);
        }     
        else
        {
            //hardcoded values to get from Math and CBSE for now
            let sk = `${this.state.sessionYear}|${this.state.subject}|${this.state.curriculum}|${this.state.programName}|${this.state.sessionType}|${this.state.level}`;
            levelwiseSessions = await dashboardScript.fetchAllSessions(this.state.qualifier, sk);
        }
       console.log(levelwiseSessions.length);
       this.setState({levelwiseSessions,dataFetched:true});
    }
    handleChange=(e)=>{

        this.setState({
            [e.target.name]: e.target.value
        })

    }
    loadSession = () =>{
       // console.log(level);
        this.setState({
            view:true
        })
    }

/*     statusCount=(level, status)=>{
        let levelwiseSessions = this.state.levelwiseSessions;
        let currentLevelSessions = levelwiseSessions[level];
        console.log("Inside status count "+levelwiseSessions[level].length)
        let total = currentLevelSessions.filter(session => session.statusName === status);
        return total.length;
        // return 0;
    } */
    statusCount=(status)=>{
        let levelwiseSessions = this.state.levelwiseSessions;
        
       // console.log("Inside status count "+levelwiseSessions.length)
        let total = levelwiseSessions.filter(session => session.statusName === status);
        return total.length;
        // return 0;
    } 
    goBack=()=>{
        this.setState({view:false})
    }
    render() 
    {  
        let levelwiseSessions = this.state.levelwiseSessions;
        if (this.state.view)
        {
            return(
                <ViewSession goBack={this.goBack} levelName={this.state.level} subject={this.state.subject} sessions={levelwiseSessions}/>
            )
        }    
        return (
            <div class='container'>
                <h4 style={{ textAlign: "center" }}>Sessions Dashboard</h4>
                <Row>
                        <Col>
                            <select onChange={this.handleChange} class="form-select" value={this.state.sessionYear} name="sessionYear">
                                <option >Choose session year</option>
                                <option selected value="None">None</option>
                                <option  value="2022">2022</option>  
                                <option  value="2023">2023</option>   
                                <option  value="2024">2024</option>                    
                            </select>
                        </Col>
                        <Col ms={2}> 
                                <select onChange={this.handleChange} class="form-select" value={this.state.subject} name="subject">
                                    <option  value="MATH">MATH</option>
                                    <option  value="CS">CS</option>
                                    </select>
                            </Col> 
                            <Col ms={2}> 
                                <select onChange={this.handleChange} class="form-select" value={this.state.curriculum} name="curriculum">
                                    <option  value="CBSE">CBSE</option>
                                    <option  value="CODE">CODE</option>
                                </select>
                            </Col> 
                    <Col>
                            <select onChange={this.handleChange} class="form-select" value={this.state.sessionType} name="sessionType">
                                <option >Choose session type</option>
                                <option selected value="LIVE">LIVE</option>
                                <option  value="DEMO">DEMO</option>                                   
                            </select>
                        </Col>
                    <Col>
                        <select onChange={this.handleChange} class="form-select" value={this.state.programName} name="programName">
                            <option >Choose program name</option>
                            <option selected value="RISE">RISE</option>
                            <option  value="OLMP">Olympiad Preparation</option>  
                            <option  value="MATHLATHON">Mathlathon</option>
                            <option  value="JUNIOR-BLOCK-CODER">Junior Block Coder</option>
                            <option  value="SCRATCH">Scratch</option>
                            <option  value="SCRATCH-AI-ML">SCRATCH/AI-ML</option>
                            <option  value="APP-INVENTOR">App Inventor</option>
                            <option  value="PYTHON">Python</option>                                     
                        </select>
                    </Col>
                    <Col>
                        <select onChange={this.handleChange} class="form-select" value={this.state.qualifier} name="qualifier">
                            <option >Choose session type</option>
                            <option selected value="SESSION">REGULAR SESSION</option>
                            <option  value="ASSESSMENT">ASSESSMENT</option>                                   
                        </select>
                    </Col>
                    <Col>
                        <select onChange={this.handleChange} class="form-select" value={this.state.level} name="level">
                            <option >Choose level</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option selected value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="G">G</option>
                            <option value="H">H</option>                                  
                        </select>
                    </Col>
                    <Col >
                        <Button onClick={this.showSessionData}>GO</Button>
                    </Col>
                </Row>
                {(this.state.dataFetched) && (
                    <table class='table'>
                    <thead>
                        <tr style={{ marginTop: "2vmin", alignItems: "center" }}>
                            <th scope="col">Level</th>
                            <th scope="col">Sessions Published</th>
                            <th scope="col">Sessions in Review</th>
                            <th scope="col">Sessions in Progress</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    {
                      (levelwiseSessions.length) && (
                          <tbody>
                                <tr style={{ marginTop: "2vmin", alignItems: "center" }}>
                                        <td scope="col">{this.state.level}</td>
                                        <td scope="col">{this.statusCount('PRODUCTION|SESSION')}</td>
                                        <td scope="col">{this.statusCount('FOR REVIEW')}</td>
                                        <td scope="col">{this.statusCount('DRAFT')}</td>
                                        <td scope="col" onClick={this.loadSession} style={{cursor:"pointer"}}>GO</td>
                                </tr> 
                          </tbody>
                      )}

                        {/* levelwiseSessions.map((val, idx)=>{
                            debugger;
                                console.log(val);

                                let rows = [];
                                rows.push(                       
                                    <tr style={{ marginTop: "2vmin", alignItems: "center" }}>
                                        <td scope="col">{val}</td>
                                        <td scope="col">{this.statusCount(val, 'PRODUCTION|SESSION')}</td>
                                        <td scope="col">{this.statusCount(val, 'FOR REVIEW')}</td>
                                        <td scope="col">{this.statusCount(val, 'DRAFT')}</td>
                                        <td scope="col" onClick={()=>this.loadSession(val)} style={{cursor:"pointer"}}>GO</td>
                                    </tr>     
                                )
                                return <tbody>{rows}</tbody>
                        })} */}
                </table>
                )}
            </div>
        );
    }
}
