import React from 'react';
import {Link} from 'react-router-dom';

export default function Subject() {
      return (
        <div style={{textAlign:"center"}}><br></br>
          <Link to='/addSubject'>Add a subject</Link> | 
          <Link to='/listSubjects'> List subjects </Link> 
        </div>
        );
    
}
