import React, {Component} from 'react';
import SearchDropDown from '../searchDropDown';
import 'react-dropdown/style.css';
import {fetchAll} from '../../scripts/fetchAll';
//import {setState} from '../scripts/setState'
import ListSubjects from '../subject/list.view';

export default class  SkillList extends Component {

    constructor(props){
        super(props)
        this.state={
            skills:'',
            skillName:'',
            skillId:'',
            subject:'MATH',
            
        }
    }
    componentDidMount=async()=>{
        await fetchAll(`SKILL|${this.state.subject}`,'skills',this);
    }
     setSubject=async(e)=>{
        debugger;
        this.setState({subject: e.value})
        await fetchAll(`SKILL|${e.value}`,'skills',this);
    }
      
    setSkill = (skill) =>{
        this.setState({skillId:skill.value,skillName:skill.label});        
        this.props.setSkillId(skill.value);
        this.props.setSkillName(skill.label);
    }  
    render(){
        return (
            <div class='container'>
            <div className='w-100'>
                <ListSubjects  setSubject ={this.setSubject} /> 
            </div>
            <div className='w-100'>
                <SearchDropDown  
                    options={this.state.skills} 
                    triggerUpdate={this.setSkill}
                    value={this.state.skillName}
                    placeholder="Select a skill" />
             </div>
         </div>
         );
    }
}
