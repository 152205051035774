import apis from '../../apis/api'
import logger from '../../helper/logger'
var ObjectID = require("bson-objectid");
import {writeToDB} from '../../scripts/writeToDB';

export const saveSession=async(data)=>{
  let sk;
  if (data.year === 'None')   
      sk = `${data.programName}|${data.sessionType}|${data.levelName}|${data.sessionNumber}|`;
  else
      sk = `${data.year}|${data.subject}|${data.curriculum}|${data.programName}|${data.sessionType}|${data.levelName}|${data.sessionNumber}|`;

  let sessions = await isSessionAlreadyAvailable(sk);

  if (sessions.length)
  {
      console.log(sessions);
      let sk_full = `${sk}${data.category}|${data.categoryNumber}`;
      let sameSession = sessions.filter(session => session.sk === sk_full);
      if (sameSession.length)
          alert("The session is already available. Please edit the session or create a new session")   
      else
      {
         
          console.log("Same session but not same category available...Going ahead and creating....");
          let sessionId = sessions[0].sessionId;
          
          let jsonObject = createAnotherCategorySameSession(data, sessionId, sk_full);

          let res = await writeToDB(jsonObject);
          console.log(res);
        
      }
  }
  else
  {
      let sk_full = `${sk}${data.category}|${data.categoryNumber}`;
      let jsonObject = processContentData(data, sk_full);
      let res = await writeToDB(jsonObject);
      console.log(res);
  }
}

const isSessionAlreadyAvailable = async(sk)=>{
  //example of sk RISE|LIVE|C|1|CW|1
  try {

    console.log(sk);
    let res = await apis._get_qualifier_sk(`SESSION`,sk);
    console.log("RESPONSE"+res);
    return res;
  }
  catch(e)
  {
      logger.logError("listQuestionsScript","fetchQuestions",e)
  } 
}
const processContentData  = (data, sk)=>{

  let date = new Date();
  date = date.toISOString(); 
  let sessionId =  ObjectID();//uuid();  
  //store time in seconds in the database
  let sessionTime = (data.sessionTime * 60);

  let year=() =>{
    console.log(data.year);
    if(data.year !== 'None') { return data.year};
  }
  const sessionData = 
  {
    "pk":`SESSION|${sessionId}`,	
    "sk": sk,
    "levelName":  data.levelName,
    "sessionNumber":data.sessionNumber,
    "program": data.programName,
    "year": year(),
    "curriculum":data.curriculum || null,
    "subject":data.subject || 'MATH',
    "category":data.category,
    "sessionType":data.sessionType,
    "categoryNumber":data.categoryNumber,
    "createdAt":date,
    "updatedAt":date,
    "sessionId":sessionId,          
    "statusName":"DRAFT",
    "qualifier":data.qualifier,
    "sessionTime":sessionTime,
    "createdBy":localStorage.getItem("username")
  }
  console.log(sessionData);
  return sessionData;
}
const createAnotherCategorySameSession= (data, sessionId, sk)=>{

let date = new Date();
date = date.toISOString(); 
//store time in seconds in the database
let sessionTime = (data.sessionTime * 60);
let year=() =>{
  console.log(data.year);
  if(data.year !== 'None') { return data.year};
}
const sessionData = 
{
  "pk":`SESSION|${sessionId}`,	
  "sk":sk,
  "levelName":  data.levelName,
  "program": data.programName,
  "sessionNumber":data.sessionNumber,
  "year":year(),
  "curriculum":data.curriculum || null,
  "subject":data.subject || 'MATH',
  "category":data.category,
  "sessionType":data.sessionType,
  "categoryNumber":data.categoryNumber,
  "createdAt":date,
  "updatedAt":date,
  "sessionId":sessionId,          
  "statusName":"DRAFT",
  "qualifier":data.qualifier,
  "sessionTime":sessionTime,
  "createdBy":localStorage.getItem("username")
}
return sessionData;
} 
