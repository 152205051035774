import apis from '../../apis/api'
import logger from '../../helper/logger'

const listQuestionsScript = {

    fetchQuestions:async(skillId)=>{       
        debugger;         
        try {
            return await apis._get_secondQualifier_pk(`CONTENT`,`SKILL|${skillId}`);
        }
        catch(e)
        {
            logger.logError("listQuestionsScript","fetchQuestions",e)
        }
    },
    fetchQuestionByPkSK:async(pk, sk)=>{
        try {
          console.log(pk+ ' '+sk);
          let res = await apis._get_pk_sk(pk, sk);
          console.log("RESPONSE"+res);
          return res;
        }
        catch(e)
        {
            logger.logError("listQuestionsScript","fetchQuestionByPkSK",e)
        } 
    },
    fetchQuestionsByStatusQualifier:async(statusName, qualifier) => {
        try {
            let res = await apis._get_status(statusName, qualifier);
            return res;
        }
        catch(e)
        {
            logger.logError("listQuestionsScript","fetchQuestionsByQualifierAndStatus",e)
        }
    },
    processContentData:(data,status)=>{
        debugger;
        try{
            let date = new Date();
            date = date.toISOString();
            for (var key in data) {
                if (data[key].qualifier !== 'PARENT')
                    data[key].statusName = status
                data[key].updatedAt = date
                if (data[key].isChecked)
                    delete data[key].isChecked
            }
            return data;
        }
        catch(e)
        {
            logger.logError("listQuestionsScript","processContentData",e)
        }      

    }

}
export default listQuestionsScript