import apis from '../../apis/api'
import logger from '../../helper/logger'

const dashboardScript = {
    fetchAllSessions:async(qualifier, sk)=>{
     
      console.log(sk);
      console.log(qualifier);
      try {
        let res = await apis._get_qualifier_sk(qualifier, sk);
        console.log(res);
        return res;
      }
      catch(e)
      {
          logger.logError("dashboardScript","fetchAllSessions",e)
      }
    }
  
}
export default dashboardScript