import React, {Component} from 'react';
import SkillList from '../skills/skillList';
import {batchWriteToDB} from '../../scripts/batchWriteToDB';
import {Form, Button} from 'react-bootstrap';
import {uploadDocToS3} from '../../scripts/s3';
import uploadDocumentScript from './upload.script';
import Success from '../success';
import Fail from '../fail';
import uuid from 'react-uuid';
import logger from '../../helper/logger';
export default class UploadDocument extends Component {
  
    constructor(props) {
    //  debugger
        super(props);
        this.state = {                
        
            skillName:'',
            skillId:'',
            response:'',
            documentUpdated:'',
            isLoading:false,
            showFail:false
        };
      }

      setShowFail =(show) =>{
        this.setState({showFail:show})
        if(!show)
         this.setState({documentUpdated:false});
      }
      initialize = ()=>
      {
          this.setState({
            fileLink:'',
            fileName:'',
            fileId:'',
            contentType:'',
          })
          document.getElementById('file').value = [];
      }

      setSkillName = (skillName) =>{
        this.setState({
          skillName,
        })
      }

      setSkillId = (skillId) => {
      //  debugger;
        this.setState({
          skillId
        })
      }

      handleChange=(e)=>{
         this.setState({
             [e.target.name]: e.target.value
         })
     }
      goBack=(restore)=>{
        this.setState({
          documentUpdated:false,
        })
        if (!restore)
          this.initialize();  
      }  
      storeFile=(e)=>{
        debugger;
        console.log("e "+e.target.files[0]);
        let name = uuid();  
        let contentType = e.target.files[0].name.split('.')[1];
        let fileName = e.target.files[0].name;

        this.setState({fileName, fileId: `${name+"."+contentType}`, fileLink:e.target.files[0]});          
      }
      addFile = async()=>{
        //Upload to S3 bucket
        this.setState({isLoading:true})
        await uploadDocToS3(this.state.fileName, this.state.fileId, this.state.fileLink);
        let jsonObject = uploadDocumentScript.processContentData(this.state);
        console.log(jsonObject);
        logger.logMessage("uploadDocument.view.js","batchWrite",jsonObject);      
        let res = await batchWriteToDB(jsonObject);
        let response;
        console.log(res)
        if(Object.keys(res.UnprocessedItems).length)
        {
            logger.logMessage("uploadDocument.view","batchUpdateDB","There are a few UnProcessedItems"+res.UnprocessedItems.length);
            response = false;
            this.setState({showFail:true});
        }    
        else
        {
            logger.logMessage("uploadDocument.view","batchUpdateDB","All items have been written successfully")
            response=true;
            
        }
        this.setState({
          documentUpdated:true,
          isLoading:false,
          response
        })
      }
      render(){      
        return (
          <div class='container'>
            <h4 style={{ textAlign: "center" }}>Upload a document</h4>
            <Form>
              <div class="form-group" >
                <SkillList class="form-group"
                  setSkillId={this.setSkillId}
                  setSkillName={this.setSkillName}
                />
              </div>
              <br></br>
              <div className='col-6 col-md-5 d-flex justify-content-center align-items-center'>
                <input id="file" type="file" className="form-control" onChange={this.storeFile} />
              </div>  <br></br>
              <div className='col-6 col-md-1 d-flex justify-content-center align-items-center'>
                <Button className="btn-com" disabled={!this.state.skillId || !this.state.fileLink} onClick={this.addFile}>ADD</Button>
              </div>
            {(this.state.isLoading)&&(
                <div class="text-center font-weight-bold text-primary"><h4>Adding document. Please be patient......</h4></div>)}
              </Form>
              {(this.state.documentUpdated && this.state.response)&&(
                      <Success goBack={this.goBack}/>)}
              {(this.state.documentUpdated && !this.state.response) &&(
              <Fail show={this.state.showFail} setShowFail={this.setShowFail}/>)}
          </div>
    );
    }
}

