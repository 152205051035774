import React from 'react';
import {isEmpty, isUndefined} from '../scripts/validations';

const DisplayImage = (props) =>{
    if (isUndefined(props.imageURL) || isEmpty(props.imageURL))
        return(<div>No image</div>);
    return(            
        <div>
            <img src={`${props.imageURL}`} />
        </div>
    )   
      
}            
export default DisplayImage
