import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import userRights from '../../scripts/userRights';
import MyDropDown from '../myDropDown';
import EditSession from './edit.view';
import SessionDetails from './details.view';
import SessionView from "./session.view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
library.add(faSpinner);

import { cloneSession } from './view.script';


export default class ViewSession extends Component {

    constructor(props) {
        super(props);
        console.log(props.sessions)
        this.state = {
            sessions: props.sessions,
            levelName: props.levelName,
            selectedSubject: props.subject,
            statuses: ['ALL', 'PRODUCTION|SESSION', 'FOR REVIEW', 'DRAFT'],
            status: 'PRODUCTION|SESSION',
            edit: false,
            view: false,
            showModal:false,
            new_session_number:0,
            new_session_year:"2024",
            new_session_program:'',
            new_session_type:"LIVE",
            new_session_level:'',
            sessionToBeCloned:0,
            sessionToBeEdited: [],
            isLoading: false
        }
    }

    componentDidMount = () => {
        //fetch all sessions of this level
        this.setState({
            status: this.state.statuses[0]
        })
    }

    setStatus = (status) => {
        this.setState({
            status: status.value
        })
    }

    onEditSession = (idx) => {

        let sessionToBeEdited = this.state.sessions[idx];
        console.log(this.state.sessions[idx]);
        this.setState({
            edit: true,
            sessionToBeEdited
        })
    }

    onViewSession = (idx) => {
        let sessionToBeEdited = this.state.sessions[idx];
        console.log(this.state.sessions[idx]);
        this.setState({
            view: true,
            sessionToBeEdited
        })
    }
    setSessionToBeCloned=(sessionToBeCloned)=>{
        let new_session_level = this.state.sessions[sessionToBeCloned].levelName
        let new_session_year = ''
        this.setState({
            showModal: true,
            sessionToBeCloned,
            new_session_level
          })
        console.log("sessionToBeCloned..",sessionToBeCloned);
    }
    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    handleClose=()=>{
        this.setState({
          showModal: false,
        })
      }
    onCloneSession = async () => {
        this.handleClose();
        let idx = this.state.sessionToBeCloned;
        this.setState({
            isLoading: true,
        })
        console.log(this.state.sessions[idx])
        let clonedSession = { ...this.state.sessions[idx] };
        clonedSession.program = this.state.new_session_program
        clonedSession.sessionType = this.state.new_session_type
        clonedSession.levelName = this.state.new_session_level
        clonedSession.sessionNumber = this.state.new_session_number;//this.state.sessions[idx].sk.split('|')[3];
        clonedSession.year = this.state.new_session_year;
        clonedSession.subject = 'MATH';
        clonedSession.curriculum = 'CBSE';

        console.log(clonedSession);
        await cloneSession(clonedSession);

        this.setState({ isLoading: false })
    }
    goBack = () => {
        this.setState({ edit: false, view: false })
    }
    render() {
        if (this.state.showModal)
         {
           return (
            <Modal.Dialog>
            <Modal.Header >
              <Modal.Title><h6>Select details for the new session</h6></Modal.Title>
              <Button variant="secondary"  onClick={this.handleClose}>x</Button>
            </Modal.Header>
          
            <Modal.Body style={{minHeight:"0px",height:"250px"}}>
            <form  onChange={this.handleChange}>
            <select class="form-select mb-2" value={this.state.new_session_year} name="new_session_year">
                                <option selected >Choose session year</option>       
                                <option value="2022">2022</option>
                                <option  value="2023">2023</option>
                                <option  value="2024">2024</option>   
                                 
            </select>

            <select class="form-select mb-2" value={this.state.new_session_program} name="new_session_program">
                            <option selected >Choose program name</option>
                            <option  value="RISE">RISE</option>
                            <option  value="OLMP">Olympiad Preparation</option>  
                            <option  value="MATHLATHON">Mathlathon</option>
                                         
            </select>

            <select class="form-select mb-2" value={this.state.new_session_type} name="new_session_type">
                            <option selected >Choose session type</option>
                            <option  value="LIVE">LIVE</option>
                            <option  value="DEMO">DEMO</option>                                   
            </select>

            <select class="form-select mb-2" value={this.state.new_session_level} name="new_session_level">
                            <option selected>Choose level</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                            <option value="G">G</option>
                            <option value="H">H</option>                                  
            </select>


            <input class='form-control mb-2' type ="number" name="new_session_number" value={this.state.new_session_number} />

            </form>
            </Modal.Body>
          
            <Modal.Footer>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Button variant="primary"  onClick={this.onCloneSession}>OKAY</Button></div>
            </Modal.Footer>
          </Modal.Dialog>
           )
         }
        if (this.state.isLoading) {
            return (
                <div>
                    <div className='w-100 d-flex justify-content-center align-items-center' style={{ color: "#545cad", height: "100%" }}><FontAwesomeIcon icon="fa-solid fa-spinner" spin size="lg" />Cloning the Session. Please be patient.....</div>
                </div>
            )
        }
        let sessions = this.state.sessions;
        if (this.state.edit && (userRights.isUserDeveloper() || userRights.isUserAdmin())) {
            return (
                <EditSession goBack={this.goBack} sessionNumber={this.state.sessionNumber} session={this.state.sessionToBeEdited} />
            )
        }
        if (this.state.view) {
            return (
                <SessionView goBack={this.goBack} sessionNumber={this.state.sessionNumber} session={this.state.sessionToBeEdited} />
            )
        }
        if (this.state.edit && (userRights.isUserReviewer() || userRights.isUserDesigner())) {
            return (
                <SessionDetails goBack={this.goBack} sessionNumber={this.state.sessionNumber} session={this.state.sessionToBeEdited} />
            )
        }
        return (
            <div class='container' style={{ marginTop: "2vmin", alignItems: "center" }}>
                <Row>
                    <Col md={12}>
                        <h4 style={{ textAlign: "center" }}>Level {this.state.levelName} </h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button className="float-right" onClick={this.props.goBack}>BACK</Button>
                    </Col>
                </Row>
                <Row style={{ padding: "2vmin", alignSelf: "right" }}>
                    <Col md={9}>
                        Filter by:
                    </Col>
                    <Col md={3}>
                        <MyDropDown options={this.state.statuses}
                            triggerUpdate={this.setStatus}

                            placeholder="Select Status" />
                    </Col>
                </Row>
                <table class='table'>
                    <thead>
                        <tr style={{ marginTop: "2vmin", alignItems: "center" }}>

                            <th scope="col">Number</th>
                            <th scope="col">Type</th>
                            <th scope="col">Category</th>
                            <th scope="col">Category Number</th>
                            <th scope="col">Ice Breaker</th>
                            <th scope="col">Rapid Fire</th>
                            <th scope="col">Math Reasoning</th>
                            <th scope="col">Logical Reasoning</th>
                            <th scope="col">Videos</th>
                            <th scope="col">Last updated date</th>
                            <th scop='col'>Status</th>
                            <th scop="d-flex w-100 justify-content-center">Action</th>
                        </tr>
                    </thead>
                    {
                        sessions.map((val, idx) => {
                            console.log(this.state.status);
                            if (this.state.status === 'ALL' || this.state.status === val.statusName) {
                                let rows = [];
                                rows.push(
                                    <tr>
                                        <td>{val.sessionNumber}</td>
                                        <td>{val.sessionType} | {val.qualifier}</td>
                                        <td>{val.category}</td>
                                        <td>{val.categoryNumber}</td>
                                        <td>{val["IB"] && val.IB.length}</td>
                                        <td>{val["RF"] && val["RF"].length}</td>
                                        <td>{val["MR"] && val["MR"].length}</td>
                                        <td>{val["LR"] && val["LR"].length}</td>
                                        <td>{val["VD"] && val["VD"].length}</td>
                                        <td>{val.updatedAt}</td>
                                        <td>{val.statusName}</td>
                                        {(this.state.selectedSubject==="MATH")&&(<td onClick={() => this.setSessionToBeCloned(idx)}>Clone</td>)}
                                        {(val.year) && (<td onClick={() => this.onEditSession(idx)}>Edit</td>)}
                                        <td onClick={() => this.onViewSession(idx)}>View</td>


                                    </tr>)
                                return <tbody>{rows}</tbody>
                            }
                        })
                    }
                    <tbody>

                    </tbody>
                </table>
            </div>
        );
    }
}
