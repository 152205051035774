import React, {Component} from 'react';
import Select from 'react-select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
library.add(faSpinner);

export default class SearchDropDown extends Component {

    constructor(props){
        super(props);
        this.state={
            skillName:''
        }
    }
    onSelect=(e)=>{
        this.props.triggerUpdate(e)
        console.log(e.value)
    }
    render(){
        if (this.props.options.length === 0)
        return(
            <div style={{color:"#545cad"}}><FontAwesomeIcon icon="fa-solid fa-spinner" spin size="lg" />Loading</div>
        )
        return (
          <div>
            <form>
                <Select class="form-group" 
                    options={this.props.options} 
                    onChange={this.onSelect} 
                    placeholder = {this.props.placeholder}
                />
            </form>
         </div>
         );
    }
}
