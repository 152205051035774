import apis from '../apis/api';
import logger from '../helper/logger';

export const writeToDB = async(jsonObject)=>{        
    try {
        console.log(jsonObject);
        debugger;
        return await apis._create(jsonObject);
    }
    catch(e)
    {
        logger.logError("WriteTODB","writeToDB",e);
        return false;
    }
}