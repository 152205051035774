import React from 'react';

const Comments = (props) =>{

    return(
            <div>
                <label style={label}  htmlFor="Write you comments">Write your comments</label>
                
                {(props.comments) && ( 
                    props.comments.map((val,idx)=>{
                        return(
                        <textarea class="form-control" readonly value ={(val.comment.text) + ', ' +(val.comment.postedBy) + ', '+(val.comment.postedAt)} />
                        )
                     })           
                 ) }
              
                {<textarea class="form-control"
                        type = "text"   
                        name ="comments"
                        value ={props.typedValue}                 
                /> }
            </div>    
        )
}            

const label={
    color:"blue"
}
export default Comments
