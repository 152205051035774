export const isEmpty=(value)=>
{
    return (value === '' || value.length === 0)    
}
export const isUndefined=(value)=>{
    return (value === undefined || value === 'undefined')
}

export const isNotEmpty=(value)=>
{
    return (value != '' && value != undefined && value.length != 0 && value !== null)    
}

export const isFalse=(value)=>{
    return(value === false || value === "false")
}
export const isTrue=(value)=>{
    return(value === true || value === "true")
}