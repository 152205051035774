import { API } from "aws-amplify";

const apis = {
    //first arg is the api name, the second is api path
    _create: (rise)=> {
        console.log("Inside create function")
        return API.post("rise", "/rise", {
            body: rise
        });
    },
    _get: (id)=> {
        console.log("Get all "+id)
        return API.get("rise", `/rise/${id}`);
    },
    _get_status:(status, qualifier)=>{
        console.log("Get all "+status);
        return API.get('rise-statusName',`/rise-statusName/${status}/${qualifier}`)
    },
    _get_pk_sk: (pk, sk)=> {
        console.log("Get by "+pk+ ' '+sk)
        return API.get("rise", `/rise/${pk}/${sk}`);
    },
    _get_qualifier: (id)=> {
        console.log("Get all "+id)
        return API.get("rise-qualifier", `/rise-qualifier/${id}`);
    },
    _get_category: (id, date_range)=> {
        console.log("Get all "+id)
        return API.get("rise-category", `/rise-category/${id}/${date_range}`);
    },
    _get_qualifier_sk: (id, sk)=> {
        console.log("Get all "+id)
        return API.get("rise-qualifier", `/rise-qualifier/${id}/${sk}`);
    },      
    _get_groupId: (groupId)=> {
        console.log("Get all groupId "+groupId)
        return API.get("rise-groupId", `/rise-groupId/${groupId}`);
    },    
    _get_status_date: (status, date1, date2)=> {
        console.log("Get all status between dates "+status)
        return API.get("rise-status-updatedDate", `/rise-status-updatedDate/${status}/${date1}/${date2}`);
    },      
    _batchPut: (rises)=>{
        return API.post("rises", "/rises", {
            body: rises
        });
    },
    _delete: (pk,sk)=>{
        return API.del("rises", `/rise/${pk}/${sk}`);
    },
    _get_secondQualifier_pk: (qualifier, pk)=> {
        console.log("Get all "+qualifier)
        return API.get("rise-secondQualifier", `/rise-secondQualifier/${qualifier}/${pk}`);
    },  
}
export default apis;