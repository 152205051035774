import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
library.add(faTimesCircle);


const Fail = (props) =>{
    return(             
        <Modal show={props.show} onHide={() => {props.setShowFail(false) }}>
         <Modal.Header className="w-100" >
                        <Modal.Title className='w-100 d-flex align-items-center justify-content-between'><span className="ms-4 question_label">Something went wrong</span>
                            <FontAwesomeIcon icon="fa-solid fa-times-circle" size="lg" color='#545cad' onClick={() => { props.setShowFail(false) }} />
                        </Modal.Title>
                    </Modal.Header>

      
        <Modal.Body className='w-100'>
            <h5>Something went wrong. Could not update the data.</h5>
            <Button className="btn-com" onClick={()=>{props.setShowFail(false)}}>Try Again </Button>
            
            </Modal.Body>
             
          </Modal>
        )       
}            
export default Fail
