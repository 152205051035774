import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SearchDropDown from '../searchDropDown';
import {MONTHS} from '../../resources/months';
import designerScript from './designer.script';
import {listUsers} from '../../scripts/listUsers';
import MyDropDown from '../../components/myDropDown';
import userRights from '../../scripts/userRights';
import userProfile from '../../scripts/userProfile';
const DESIGNER = 'designer';

export default class Designer extends Component {
    constructor(props) {
        super(props);
        this.state={
            isLoading:false,
            monthName: '',
            monthPicked:'',
            questions:[],
            designers:'',
            designer:'',
            totalImages:0,
            all_questions:[],
            enableGoButton: false
        }
      }
    componentDidMount = async() => {
        debugger;
        if (userRights.isUserAdmin())
        {
            await listUsers(DESIGNER,"designers",this);
            this.setState({designer: this.state.designers[0].value});
        }
        else
            this.setState({designer: userProfile.myName()}); 
    }
    setMonth = (month) => {
        this.setState({
            monthPicked: month.value,
            monthName: month.label,
            enableGoButton: true
        })
    }

    getQuestionsByMonth=async()=>{
        this.setState({isLoading:true, questions:[]})
        let monthPicked = this.state.monthPicked;
        console.log(monthPicked);
        console.log(MONTHS[Number(monthPicked)]);
        let nextMonth = monthPicked === "12" ? '01' : (MONTHS[Number(monthPicked)].value);      
        let year1 = new Date().getFullYear();
        let year2 = monthPicked === "12" ? year1-1 : year1;
        let date1 = year2 + '-' + monthPicked + '-' + '01';
        let date2 = year1 + '-' + nextMonth + '-' + '01';
        console.log(date1+ ' '+date2);
        let all_questions = await designerScript.fetchQuestionsBetweenDates(date1, date2);
        this.setState({all_questions, enableGoButton:false},()=>{
            this.filterQuestionsByDesigner();
        });        
    }
    filterQuestionsByDesigner=()=>{
        console.log("Inside filter "+this.state.all_questions.length);
        console.log("Designer "+this.state.designer);
        let all_questions = this.state.all_questions;
        let parent_questions = all_questions.filter(all_questions => (all_questions.qualifier === "PARENT"));
        let questions=[];
        debugger;
        let totalImages = 0;
        parent_questions.map((question,idx) => {
            let selectedQuestions = all_questions.filter(all_questions => (all_questions.parentId === question.contentId && all_questions.totalImages > 0 && all_questions.isDesignedBy === this.state.designer))
            if (selectedQuestions.length === 2)
            {
                questions[question.sk] = selectedQuestions;
                totalImages += selectedQuestions[0].totalImages;
                if (selectedQuestions[0].copyQuestion === false || selectedQuestions[0].copyQuestion === "false")
                    totalImages += selectedQuestions[1].totalImages;
            }
            else if (selectedQuestions.length === 1)
            {
                alert("One question has an issue------Look at console....")
                console.log("Problem with a question where the question is production ready but images are not");
                console.log(selectedQuestions);
            }
        }) 
        console.log("totalImages "+totalImages);
        this.setState({
          questions,
          totalImages,
          isLoading:false
        })
    }
    showDate =(date)=>{
        if (date)
        {
            let newDate = new Date(date)
            return (newDate.getFullYear()+' - '+(newDate.getMonth()+1)+' - '+newDate.getDate());
        }
        return false;
    }
    setDesigner=(designer)=>{
        this.setState({designer: designer.value},()=>{
            this.filterQuestionsByDesigner();
        });
    }

    render(){
        return (                      
            <div class='container'>
                <Row style={{marginTop:"2vmin"}}>
                        <Col ms={10}>          
                            <SearchDropDown  
                                options={MONTHS} 
                                triggerUpdate={this.setMonth}
                                value={this.state.monthName}
                                placeholder="Select a month for the current year" />
                        </Col>
                        <Col ms={2}><Button disabled={!this.state.enableGoButton} onClick ={()=>this.getQuestionsByMonth()}>Go</Button></Col>
                </Row>
                {(userRights.isUserAdmin()) && ( 
                    <Row>
                        <Col>
                            <MyDropDown  
                                options={this.state.designers} 
                                triggerUpdate={this.setDesigner}
                                
                                placeholder="Select a Designer to filter" />
                        </Col>
                    </Row>    
                )}
                <hr></hr>
                {(this.state.isLoading === true) && (
                     <div>Please be patient while I am fetching data...</div>
                )}

                {(Object.keys(this.state.questions).length > 0) && (
                    <div>
                        <Row>
                            <Col><strong>#</strong></Col>
                            <Col><strong>Question</strong></Col>
                            <Col><strong>Designed By</strong></Col>
                            <Col><strong>Designed Date</strong></Col>
                            <Col><strong>Total Images</strong></Col>
                            <Col><strong>Production Date</strong></Col>
                        </Row> 
                        <Row><Col>Total Images Designed = {this.state.totalImages}</Col></Row>      
                    </div>              
                )}   
                 <br />    
                {(Object.keys(this.state.questions).length > 0) && (
                    Object.keys(this.state.questions).map((val, idx)=>{
                        let rows=[];
                        let child_content = this.state.questions[val][0];
                        console.log(this.state.questions[val][0]); 
                        rows.push(
                            <Row>
                                <Col>
                                    {idx+1}
                                </Col>
                                <Col>
                                    {child_content.question}
                                </Col>
                                <Col>
                                    {child_content.isDesignedBy}
                                </Col>
                                <Col>
                                    {this.showDate(child_content.isDesignedOn)}
                                </Col>
                                <Col>
                                    {child_content.totalImages}
                                </Col>
                                <Col>
                                    {this.showDate(child_content.isProductionOn)}
                                </Col>
                            </Row>
                        )
                        if (child_content.copyQuestion === false || child_content.copyQuestion === "false")
                        {
                            let child_content2 = this.state.questions[val][1];
                            rows.push(
                                <Row>
                                        <br />
                                    <Col>
                                        {idx + 1}
                                    </Col>
                                    <Col>
                                        {child_content2.question}
                                    </Col>
                                    <Col>
                                        {child_content2.isDesignedBy}
                                    </Col>
                                    <Col>
                                        {this.showDate(child_content2.isDesignedOn)}
                                    </Col>
                                    <Col>
                                        {child_content2.totalImages}
                                    </Col>
                                    <Col>
                                        {this.showDate(child_content2.isProductionOn)}
                                    </Col>
                                </Row>
                            )                                    
                        }
                        return <div>{rows}<hr></hr></div>
                    })    
                )}        
                       
              </div>
         );
    }
}
