import status from './status';
const userRights = {        
    isUserAdmin:()=>{
        return(localStorage.getItem('userRight')?localStorage.getItem("userRight").includes("admin"):false)
    },
    isUserDeveloper:()=>{
        return(localStorage.getItem('userRight')?localStorage.getItem("userRight").includes("developer"):false)
    },
    isUserReviewer:()=>{
        return(localStorage.getItem('userRight')?localStorage.getItem("userRight").includes("reviewer"):false)
    },
    isUserDesigner:()=>{
        return(localStorage.getItem('userRight')?localStorage.getItem("userRight").includes("designer"):false)
    },
    doIOwn:(name)=>{
        return(localStorage.getItem("username") === name)
    },
    userType:()=>{
      return(localStorage.getItem('userRight'));
    },
    canView(question)
    {
/*       if (this.isUserAdmin() )
        return true;
      if (this.doIOwn(question.createdBy))
        return true;
      if ((status.isForReview(question.statusName) || (status.isReviewed(question.statusName))) && (this.isUserReviewer()))
        return true; */
      return true;
    },
    canEdit(question)
      {
        ////debugger;
        if (this.isUserAdmin())
        {
            return true;
        }
        else 
        {
          if ((status.isDraft(question.statusName) || status.isForReview(question.statusName) || status.isSentBack(question.statusName) || status.isDesignDone(question.statusName) || status.isForDesign(question.statusName)) && this.doIOwn(question.createdBy))
            return true;
          else if (status.isForReview(question.statusName) && this.isUserReviewer())  
            return true;
          else if (status.isForDesign(question.statusName) && this.isUserDesigner())
            return true;
        }
        return false;
      },
    canDelete(question)
      {
        if (this.isUserAdmin())
        {
          if (status.isDraft(question.statusName) || 
             status.isForReview(question.statusName) || 
             status.isForDesign(question.statusName) || 
             status.isReviewed(question.statusName) || 
             status.isSentBack(question.statusName) || 
             status.isDesignDone(question.statusName))
            return true;
        }
        else 
        {       
          if ((status.isDraft(question.statusName) || 
               status.isForReview(question.statusName) || 
               status.isForDesign(question.statusName) ||
               status.isDesignDone(question.statusName) ||
               status.isSentBack(question.statusName)) && this.doIOwn(question.createdBy))
             return true;
        }
        return false;
      }

}
export default userRights