import apis from '../apis/api';
import logger from '../helper/logger'
export const fetchAll =async(pk,stateName,object)=>
{
    console.log(pk);
    try {
        let res = await apis._get(pk);
      //  console.log("Response from get "+res);
        const items = res.map((item) => {
            return {
                value: item.sk,
                label: item.myName,
            }
        });
        object.setState({
            [stateName]:items,
            loading:false
        })        
        logger.logMessage("fetchAll","fetchAll",`Fetched all ${stateName}${items}`)
        return true;
    }
    catch(e)
    {
        logger.logError("fetchAll","fetchAll",e)
        return false;
    }
  
}