import React from 'react'
import {Link} from 'react-router-dom' 

export default function Header() {
    return (
        <header className="navbar sticky-lg-top navbar-expand-md navbar-dark row mb-4" style={{backgroundColor:"#545cad", margin: "0px", padding: "0px"}}>
    <div className="col-12 col-md-2  ps-3 mt-2 mb-2 d-flex justify-content-center">
    <img  width="auto" height="50px" src= 'https://www.mathbuddyonline.com/mathbuddy/assets/images/login/header_logo.png'></img>    
    </div>
    <div className="col-12 col-md-5 d-flex justify-content-between justify-content-md-center">
    <h1 className="navbar-brand fs-4">Content Management System</h1>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    </div>
    <div className="collapse navbar-collapse  col-12 col-md-5" id="navbarSupportedContent">
      <div className='d-flex justify-content-end'>
      <ul className="navbar-nav nav-tabs">
        <li className="nav-item">
        <Link className="nav-link text-white" aria-current="page" to='/Home'>Home</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link text-white" aria-current="page" to='/about'> About</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link text-white" aria-current="page" to='/question'>Questions</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link text-white" aria-current="page" to='/subject'> Subjects</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link text-white" aria-current="page" to='/session'> Sessions</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link text-white" aria-current="page" to='/document'> Documents</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link text-white" aria-current="page" to='/report'> Reports</Link>
        </li>
      </ul></div>
    
    </div>
</header>


        // <header style={HeadStyle}>
        //     <h1>Content Management System</h1>
        //     <Link style={linkStyle} to='/Home'>Home</Link> | 
        //     <Link style={linkStyle} to='/about'> About</Link> | 
        //     <Link style={linkStyle} to='/question'> Questions</Link> |
        //     <Link style={linkStyle} to='/subject'> Subjects</Link> |
        //     <Link style={linkStyle} to='/session'> Sessions</Link> | 
        //     <Link style={linkStyle} to='/report'> Reports</Link>
        // </header>
    )
}

const HeadStyle = {
    background: '#333',
    padding: '10px',
    color: '#fff',
    textAlign: 'center'
}

const linkStyle={
    color:'#fff'
}