import apis from '../../apis/api';
import logger from '../../helper/logger';

const sortItems=(items)=>{
    //   console.log("Sort items "+items);
        items.sort((a, b)=> {
            return a.order - b.order; //sort the topics by orderLevel in ascending
        });
        // console.log("items sorted "+items)
        return items;
}
const editSessionScript = {
    fetchQuestionsByCategory:async(category, date_range)=>{                
        try {
            return await apis._get_category(category, date_range);
        }
        catch(e)
        {
            logger.logError("editSessionScript","fetchQuestionsByCategory",e)
        }
    },
    fetchQuestionByPkSK:async(pk, sk)=>{
      try {
        console.log(pk+ ' '+sk);
        let res = await apis._get_pk_sk(pk, sk);
        console.log("RESPONSE"+res);
        return res;
      }
      catch(e)
      {
          logger.logError("editSessionScript","fetchQuestionByPkSK",e)
      } 
    },
    fetchParentByQualifierSK:async(sk) =>{
        try{
            let res = await apis._get_qualifier_sk(`PARENT`,sk);
            console.log(res);
            return res;
        }
        catch(e)
        {
            logger.logError("editSessionScript","fetchParentByQualifierSK",e)
        }
        
    },
    fetchByGroupId:async(groupId) =>{
        try{
            let res = await apis._get_groupId(groupId);
            console.log(res);
            return res;
        }
        catch(e)
        {
            logger.logError("editSessionScript","fetchByGroupId",e)
        }
        
    },
    orderContent:(data, category)=>{
        let session = data;
        let questions = session[category];
        sortItems(questions);
        console.log(questions);
        return questions;
    },
    getSessions:async(sessionId)=>{
        let res = await apis._get_pk_sk(`SESSION|${sessionId}`,'');
        console.log("Sessions "+res);
        return res;
    },
    fetchDocuments:async(skillId)=>{       
        debugger;         
        try {
            return await apis._get_secondQualifier_pk(`DOCUMENT`,`SKILL|${skillId}`);
        }
        catch(e)
        {
            logger.logError("editSessionScript","fetchDocuments",e)
        }
    },

}
export default editSessionScript