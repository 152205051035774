import React, {Component} from 'react';
import {Link} from 'react-router-dom'

export default function Question(){
    
        return (
          <div style={{textAlign:"center"}}><br></br> 
              <Link to='/createQuestion'> Add questions </Link> |
              <Link to='/listQuestions'> View Questions for a skill</Link> | 
              <Link to='/listStatusQuestions'> View Questions by status</Link> 
         </div>
         );
    
}
