import React, {Component} from 'react';
import ViewQuestion from './view.view';
import EditQuestion from './edit.view';
import SkillList from '../skills/skillList';
import MathJax from 'react-mathjax-preview'
import status from '../../scripts/status';
import logger from '../../helper/logger';
import listQuestionsScript from './list.script';
import {batchWriteToDB} from '../../scripts/batchWriteToDB';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import userRights from '../../scripts/userRights';
import {isTrue, isUndefined} from '../../scripts/validations';
import uuid from 'react-uuid';


import {deleteQuestion} from '../../scripts/delete'
import { getFromS3 } from '../../scripts/s3';
const REVIEWER = 'reviewer';
const DESIGNER = 'designer';

export default class ListQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
          skills:'',
          skillId:'',
          skillName:'',
          subject:'MATH',
          parent_questions:[],
          questions: [],
          questionInView:[],
          secondChild:[], //only used while editing. This will help update the other child if edits are the same
          parentInView:[],
          view:false,
          edit:false,
          isPublished:false,
          isDeleting:false,
          userType: userRights.userType(),
          developers:'',
          reviewers:'',
          uuid: uuid(),
          username:'',
          questionsSelected:[],
          isLoading:false,
          showModal:false,
          showModalForProduction:false,
          questionIdToBeDeleted:''
        };
      }
      initialize=()=>{
        this.setState({
          skills:'',
          skillId:'',
          skillName:'',
          questions: '',
          questionInView:0,
          view:false,
          edit:false,
          isPublished:false,
          isDeleting:false,
          showModal:false,
          showModalForProduction:false,
          userType: userRights.userType(),
          uuid:uuid(),
          questionIdToBeDeleted:'',
          isLoading:false
        })

      }
      componentDidMount = () => {
        console.log("Component did mount");       
        this.showQuestions();
      }
      showQuestions=()=>{
        if ((userRights.isUserDesigner() && !userRights.isUserReviewer()) || 
        (userRights.isUserReviewer() && !userRights.isUserDesigner()))
        {
          this.getQuestionsByUserType();
        }
      }
      getQuestionsByUserType=()=>{
        if (this.state.userType === DESIGNER && userRights.isUserDesigner())
          this.fetchQuestionsByStatus('FOR DESIGN');
        else if (this.state.userType === REVIEWER && userRights.isUserReviewer())
          this.fetchQuestionsByStatus('FOR REVIEW');
      }
      fetchQuestionsByStatus=async(status)=>{
        let questions = await listQuestionsScript.fetchQuestionsByStatusQualifier(status, 'CHILD');
        this.setState({questions})
      }
      processQuestions=(all_questions)=>{
        let parent_questions;
        parent_questions = all_questions.filter(all_questions => (all_questions.qualifier === "PARENT"))

        let questions=[];
        parent_questions.map((question,idx) => {
          questions[question.sk] = all_questions.filter(all_questions => (all_questions.parentId === question.contentId))
        })     
        this.setState({
          questions,
          parent_questions
        })
      }

      fetchQuestions = async() =>{
        //returns all questions for a skill. This includes parent and child content
        let all_questions = await listQuestionsScript.fetchQuestions(this.state.skillId); 
      //  debugger;
   //     let parent_questions;
    //    let questions=[];
        if (userRights.isUserDeveloper() || userRights.isUserAdmin())
          this.processQuestions(all_questions);  

        if (userRights.isUserReviewer())
          this.processQuestions(all_questions,'FOR REVIEW');
      }
      filterSelectedQuestions=()=>{
        if (this.state.questions)
        {
            // debugger;
            let questions = this.state.questions;
            let parent_questions = this.state.parent_questions;
            let filteredQuestions=[];
            Object.keys(questions).map((val) =>{
              if (questions[val][0].isChecked || questions[val][1].isChecked)
              {
                let filtered_parent = parent_questions.filter((parent => parent.sk === val));
                if (questions[val][0].isChecked === true)
                  filteredQuestions.push(questions[val][0]);
                if (questions[val][1].isChecked === true)
                  filteredQuestions.push(questions[val][1]);  
                filteredQuestions.push(filtered_parent[0]);                  
              }
            })
            console.log(filteredQuestions);
            return filteredQuestions;
        }
      }      

      submitForReview = ()=>{
      debugger;
       let questionsToBeSubmitted =  this.filterSelectedQuestions();        

       let jsonObject = listQuestionsScript.processContentData(questionsToBeSubmitted,"FOR REVIEW");
       logger.logMessage("listQuestions.view", 'submitForReview',"processedAllData "+jsonObject)
       this.batchUpdateDB(jsonObject);
       
      }
      submitForDesign = ()=>{
        let questionsToBeSubmitted =  this.filterSelectedQuestions();     
        let jsonObject = listQuestionsScript.processContentData(questionsToBeSubmitted,"FOR DESIGN");
 
        logger.logMessage("listQuestions.view", 'submitForReview',"processedAllData "+jsonObject)
        this.batchUpdateDB(jsonObject);
        
       }
       submitForProduction=()=>{
        let questionsToBeSubmitted =  this.filterSelectedQuestions();    
        
        this.setParentStatus(questionsToBeSubmitted);         
        let jsonObject = listQuestionsScript.processContentData(questionsToBeSubmitted,"PRODUCTION");
 
        logger.logMessage("listQuestions.view", 'submitForProduction',"processedAllData "+jsonObject)
        this.batchUpdateDB(jsonObject);
      }
      batchUpdateDB=async(jsonObject) =>{
          //debugger
          let res = await batchWriteToDB(jsonObject);
          this.setState({
            isLoading:false
          })
          if(res.UnprocessedItems.length!==0)
            logger.logMessage("listQuestions.view","batchUpdateDB","There are a few UnProcessedItems"+res.UnprocessedItems.length);
          else
            logger.logMessage("listQuestions.view","batchUpdateDB","All items have been written successfully");
      }
      setParentStatus=(questions) =>{

        //Parent status will be set to only PRODUCTION and will be set only if both the children are PRODUCTION
        let parent = questions.filter(question => question.qualifier === 'PARENT');
        for (let ii=0;ii<parent.length;ii++)
        {
          if (isTrue(parent[ii].copyQuestion))
          {
            parent[ii].statusName = "PRODUCTION";
          }
          else
          {
            //Get both the children of parent and check if the status is PRODUCTION for either
            let children = questions.filter(question => (question.qualifier === 'CHILD|IN|EN' || question.qualifier === 'CHILD|US|EN' ) && (
                                                          question.parentId === parent[ii].contentId));

            if (children.length === 2)
              parent[ii].statusName = "PRODUCTION";
            else{
              if (this.anyChildInProduction(parent[ii]))
                  parent[ii].statusName = "PRODUCTION"; 
            }
 
          }  
        }
      }
      anyChildInProduction=(parent)=>
      {
        let questions = this.state.questions;
        for (let ii=0;ii<questions[parent.sk].length;ii++)
        {
          if (questions[parent.sk][ii].statusName === "PRODUCTION")
            return true; 
        }
        return false;
      }
      setSkillName = (skillName) =>{
        this.setState({
          skillName
        })
      }
      setUsers = (user) => {
        this.initialize();
        if (user)
           this.setState({username:user.value});
      }
      showQuestion=async(parentQuestion, childIndex)=>
      {
        let questionInView ={... this.state.questions[parentQuestion][childIndex]};
        let parent_questions = this.state.parent_questions;
        let parentInView =  parent_questions.filter(parent_questions => (parent_questions.contentId === questionInView.parentId));
        if (questionInView.qImage)
        {
          let image = await getFromS3(questionInView.qImage);
          questionInView.qImage = image;
        }
        if (questionInView.eImage)
        {
          let image = await getFromS3(questionInView.eImage);
          questionInView.eImage = image;
        }
        if (questionInView.oImages)
        {
          let images=[];
          for (var ii=0;ii<questionInView.oImages.length;ii++)
          {
            images[ii] = await getFromS3(questionInView.oImages[ii]);
            
          }
          questionInView.oImages = images;
        }      
        this.setState({
          questionInView,
          parentInView:parentInView[0]
        })
        this.setView(true);
      }
      showQuestionByIndex = async(idx)=>{
        let questionInView = {...this.state.questions[idx]};
        let parentId = questionInView.parentId;
        let parentInView = await listQuestionsScript.fetchQuestionByPkSK(questionInView.pk, `PARENT|${parentId}`)
        
        if (questionInView.qImage)
        {
          let image = await getFromS3(questionInView.qImage);
          questionInView.qImage = image;
        }
        if (questionInView.eImage)
        {
          let image = await getFromS3(questionInView.eImage);
          questionInView.eImage = image;
        }
        if (questionInView.oImages)
        {
          let images=[];
          for (var ii=0;ii<questionInView.oImages.length;ii++)
          {
            images[ii] = await getFromS3(questionInView.oImages[ii]);
            
          }
          questionInView.oImages = images;
        }      
        this.setState({
          questionInView,
          parentInView:parentInView[0]
        })
        this.setView(true);
      }
      editQuestion=(parentQuestion, childIndex)=>
      {
          let questionInView ={... this.state.questions[parentQuestion][childIndex]};
          let otherIndex = childIndex === 0 ?1:0;
          let secondChild={...this.state.questions[parentQuestion][otherIndex]};
          let parent_questions = this.state.parent_questions;
          let parentInView =  parent_questions.filter(parent_questions => (parent_questions.contentId === questionInView.parentId));
          this.setState({
            questionInView,
            parentInView:parentInView[0],
            secondChild,
            edit:true
          })
      }
      editQuestionByIndex =async(idx)=>{
        debugger;
        let questionInView = {...this.state.questions[idx]};
        let secondChild;
        let parentId = questionInView.parentId;
        if (questionInView.country_language === 'IN|EN')
              secondChild = this.state.questions.filter(question => question.parentId === parentId && question.country_language === 'US|EN')
        else
              secondChild = this.state.questions.filter(question => question.parentId === parentId && question.country_language === 'IN|EN')
        
        let parentInView = await listQuestionsScript.fetchQuestionByPkSK(questionInView.pk, `PARENT|${parentId}`)
        this.setState({
          questionInView,
          parentInView:parentInView[0],
          secondChild: secondChild[0],
          skillName: questionInView.skillName,
          edit:true
        })        
      }

      setView=(view)=>{
        this.setState({
          view
        })
      }
      setEdit=(edit)=>{
        if (userRights.isUserDesigner() || userRights.isUserReviewer())
          this.getQuestionsByUserType();
        else
          this.fetchQuestions()
        this.setState({
          edit,
          isPublished:false
        })
      }

      isChecked=()=> {   
        debugger; 
        let questions = this.state.questions;
        for (var key in questions)
        {
          if (questions.hasOwnProperty(key))
            if (questions[key][0].isChecked || questions[key][1].isChecked)
              return true;
        }
        return false;
      }
      deleteQuestion=async(parentIndex)=>
      {
        this.setState({
          showModal: true,
          parentIdToBeDeleted:parentIndex
        })
      }     
      getConfirmation=()=>{
        this.setState({
          showModalForProduction:true
        })
      }
      handleClose=()=>{
        this.setState({
          showModal: false,
          showModalForProduction:false,
          questionIdToBeDeleted:''
        })
      }
      handleDelete=async()=>{
        this.setState({
          isDeleting: true,
          showModal:false
        })
        let pk =`SKILL|${this.state.skillId}`;
        let sk = this.state.parentIdToBeDeleted;

        await deleteQuestion(pk,sk);

        //child one
        let sk1 = this.state.questions[this.state.parentIdToBeDeleted][0].sk;
        let sk2 = this.state.questions[this.state.parentIdToBeDeleted][1].sk;
        await deleteQuestion(pk,sk1);
        await deleteQuestion(pk,sk2);
        this.fetchQuestions();
        this.setState({isDeleting:false})  
      }
      setSkillId = (skillId) => {
        this.setState({
          skillId
        })
      }
/*hello */
/*       setUserType=()=>{
        let userType = userRights.userType();  
        this.setState({userType}); 
      } */
      isUserTypeReviewer=()=>{
        return (this.state.userType === REVIEWER)
      }
      isUserTypeDeveloper=()=>{
        return (this.state.userType === DEVELOPER)
      }
      onCheckBoxPressed=(e)=>{
        let questions = this.state.questions;
        let target_value = e.target.value.split(",");
        if (target_value[1] === "both")
        {
          questions[target_value[0]][0]["isChecked"] = e.target.checked;
          questions[target_value[0]][1]["isChecked"] = e.target.checked;
        }
        else
        {
          questions[target_value[0]][target_value[1]]["isChecked"] = e.target.checked;
        }       
        this.setState({
          questions
        })
      }
/*       moveAllStagingToProduction=()=>{
        debugger;
        let questions = this.state.questions;
        for (var ii=0;ii<questions.length;ii++)
        {
          if (questions[ii].statusName === STAGING)
          {
            console.log("Staging")
            questions[ii]["isChecked"] = true
          }
        }
        this.setState({
          questions
        })
        this.setState({
          showModalForProduction:false
        })
        this.submitForProduction();
      } */
      disableCheckBox=(statusName, name)=>{
        if (!userRights.doIOwn(name))
          return true;
        if (status.isDraft(statusName) || status.isDesignDone(statusName) || status.isSentBack(statusName))
          return false;
        return true;  
      }
      disableCheckBoxForProduction=(statusName)=>{
        if (status.isReviewed(statusName))
          return false;
        return true;  
      }
      canShowQuestion=(val)=>{
        if ((val.copyQuestion === true || val.copyQuestion === "true") && val.country_language === 'IN|EN')
          return true;
        if (val.copyQuestion === false || val.copyQuestion === "false")
          return true;  
      }
      handleChange = (e) => {
            this.setState({ [e.target.name]: e.target.value })
      }
      render(){      
        let questions = this.state.questions || [];    
        let parent_questions = this.state.parent_questions || [];
        console.log("Length "+Object.keys(questions).length); 
         if (this.state.view)
         {
           return(<div>
              <ViewQuestion setView={this.setView} parent ={this.state.parentInView} question={this.state.questionInView}/>
              </div>)
         }
          if (this.state.edit)
         {
           return(<div>
                    <EditQuestion  setEdit={this.setEdit} 
                        question={this.state.questionInView} 
                        secondChild={this.state.secondChild}
                        parent ={this.state.parentInView}
                        isPublished ={this.state.isPublished}
                        canReviewQuestion={userRights.isUserReviewer()}
                        canDesignQuestion={userRights.isUserDesigner()}
                        skillName = {this.state.skillName}
                         />
                  </div>)
         } 
         if (this.state.showModal)
         {
           return (
            <Modal.Dialog>
            <Modal.Header >
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
          
            <Modal.Body>
              <p>Do you want to delete the questions? Note that both the questions (IN and US) will get deleted</p>
            </Modal.Body>
          
            <Modal.Footer>
              <Button variant="secondary"  onClick={this.handleDelete}>Delete</Button>
              <Button variant="primary"  onClick={this.handleClose}>Cancel</Button>
            </Modal.Footer>
          </Modal.Dialog>
           )
         }
         if (this.state.showModalForProduction)
         {
           return (
            <Modal.Dialog>
            <Modal.Header >
              <Modal.Title>Confirm Move to Production</Modal.Title>
            </Modal.Header>
          
            <Modal.Body>
              <p>Do you really want to move all the staging questions to production? There is no turning back!</p>
            </Modal.Body>
          
            <Modal.Footer>
              <Button variant="secondary"  onClick={this.moveAllStagingToProduction}>Move</Button>
              <Button variant="primary"  onClick={this.handleClose}>Cancel</Button>
            </Modal.Footer>
          </Modal.Dialog>
           )
         }
         if (this.state.isDeleting)
         {
           return(<div>
             <h4>The questions are getting deleted....</h4>
              </div>)
         }
         if (this.state.isLoading)
         {
           return(<div>
             <h4>The status is getting updated....</h4>
              </div>)
         }
          return (
            <div class='container'>
              <h4 style={{textAlign:"center"}}>List of questions</h4>
                {(!userRights.isUserDesigner() && !userRights.isUserReviewer()) && (
                  <div>                 

                  <SkillList class="form-group" 
                    key = {this.state.uuid}                  
                    setSkillId={this.setSkillId}
                    setSkillName={this.setSkillName}                    
                 />                  
                  <Row style={{marginTop:"2vmin"}}>
                  <Col ms={4} class="bd-highlight">
                      <button class="btn btn-primary" type="button"
                      onClick={this.fetchQuestions} >GO</button>
                  </Col> 
                  <Col ms={4} class="bd-highlight">
                      <button class="btn btn-warning" type="button" disabled={!this.isChecked}
                      onClick={this.submitForReview} >Submit for review</button>
                  </Col>    
                  <Col ms={4} class="bd-highlight">
                      <button class="btn btn-warning" disabled={!this.isChecked} type="button"
                      onClick={this.submitForDesign} >Submit for design</button>
                  </Col>     
                  {(userRights.isUserAdmin()) && (                  
                  <Col ms={4} class="bd-highlight">
                      <button class="btn btn-warning" disabled={!this.isChecked} type="button"
                      onClick={this.submitForProduction} >PRODUCTION</button>
                  </Col>   )} 
{/*                   {(userRights.isUserAdmin()) && (                  
                  <Col ms={4} class="bd-highlight">
                      <button class="btn btn-danger" type="button"
                      onClick={this.getConfirmation} >MOVE ALL STAGING TO PRODUCTION</button>
                  </Col>   )}  */}
                </Row> 
                </div>              
                )}
                {(!userRights.isUserDesigner() && !userRights.isUserReviewer()) && (Object.keys(questions).length) && (
                <div>                
                  <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Check</th>
                      {(userRights.isUserAdmin()) && (
                      <th scope="col">To Production</th>
                      )}                      
                      <th scope="col">Question</th>
                      <th scope="col">Country|Language</th>
                      <th scope="col">Blooms</th>
                      <th scope="col">StatusName</th>
                      <th scope="col">Question Type</th>
                      <th scope="col">System Eval?</th>
                      <th scope='col'>Created By</th>
                      <th scope="col" style={{cursor:"pointer"}}>View</th>
                      <th scope="col"  style={{cursor:"pointer"}}>Edit</th>
                      <th scope='col'  style={{cursor:"pointer"}}>Delete</th>                     
                    </tr>
                  </thead>
                  {
                    parent_questions.map((val, idx)=>{
                      let rows = [];
                      if (val.copyQuestion === "true" || val.copyQuestion === true)
                      {
                        let ii=0;
                        let child_content = questions[val.sk][ii];
                        rows.push(
                          <tr key={val.sk+''+idx}>
                              <td>{ii+1}</td>
                              <td>        
                                <Form.Check type="checkbox" disabled={this.disableCheckBox(child_content.statusName, child_content.createdBy)} value={val.sk+","+"both"} onChange={this.onCheckBoxPressed}/>
                              </td>
                              {(userRights.isUserAdmin())&& (
                              <td>        
                                <Form.Check type="checkbox" disabled={this.disableCheckBoxForProduction(child_content.statusName)} value={val.sk+","+"both"}  onChange={this.onCheckBoxPressed}/>
                              </td>)}
                              <td><MathJax math={child_content.question} /></td>
                              <td>{child_content.country_language}</td>
                              <td>{val.bloomsLevel}</td>
                              <td>{child_content.statusName}</td>
                              <td>{val.category}</td>
                              <td>{val.isSystemEvaluated === true ? 'true' : 'false'}</td>
                              {/* <td>{child_content.totalImages || '0'}</td>
                              <td>{child_content.isDesignedBy}</td> */}
                              <td>{child_content.createdBy}</td>

                              <td onClick={()=>this.showQuestion(val.sk, ii)}>View</td>
                              {(userRights.canEdit(child_content)) && (
                                <td onClick={()=>this.editQuestion(val.sk, ii)}>Edit</td>)}
                              
                              {(userRights.canDelete(child_content)) && (
                                <td onClick={()=>this.deleteQuestion(val.sk, ii)}>Delete</td>)}
                            </tr>
                        )
                      }
                      else {
                        for (let ii=0;ii<2;ii++) {                     
                          let child_content=questions[val.sk][ii];
                          rows.push(
                            <tr key={val.sk+''+idx+ii}>
                                <td>{ii+1}</td>
                                <td>        
                                  <Form.Check type="checkbox" disabled={this.disableCheckBox(child_content.statusName, child_content.createdBy)} value={val.sk+","+ii} onChange={this.onCheckBoxPressed}/>
                                </td>
                                {(userRights.isUserAdmin())&& (
                                <td>        
                                  <Form.Check type="checkbox" disabled={this.disableCheckBoxForProduction(child_content.statusName)} value={val.sk+","+ii} onChange={this.onCheckBoxPressed}/>
                                </td>)}
                                <td><MathJax math={child_content.question} /></td>
                                <td>{child_content.country_language}</td>
                                <td>{val.bloomsLevel}</td>
                                <td>{child_content.statusName}</td>
                                <td>{val.category}</td>
                                <td>{val.isSystemEvaluated === true ? 'true' : 'false'}</td>
                                {/* <td>{child_content.totalImages || '0'}</td>
                                <td>{child_content.isDesignedBy}</td> */}
                                <td>{child_content.createdBy}</td>
                                <td onClick={()=>this.showQuestion(val.sk, ii)}>View</td>
                                {(userRights.canEdit(child_content)) && (
                                  <td onClick={()=>this.editQuestion(val.sk, ii)}>Edit</td>)}                                
                                {(userRights.canDelete(child_content)) && (
                                  <td onClick={()=>this.deleteQuestion(val.sk, ii)}>Delete</td>)}
                            </tr>
                          )
                        }
                      }
                      return <tbody>{rows}</tbody> 
                    })
                }
                </table> 
                </div>   
                )}   
                {(userRights.isUserDesigner() && userRights.isUserReviewer()) && (
                <div>
                  <Row>
                    <Col>    
                      <select onChange={this.handleChange} class="form-control" value={this.state.userType} name="userType">
                                        <option selected value="reviewer">REVIEWER</option>
                                        <option  value="designer">DESIGNER</option>
                      </select> 
                    </Col>
                  </Row>       
                  <Row style={{marginTop:"2vmin"}}>
                    <Col ms={4} class="bd-highlight">
                        <button class="btn btn-primary" type="button"
                        onClick={this.getQuestionsByUserType} >GO</button>
                    </Col>
                  </Row> </div> )}
                  {(userRights.isUserDesigner() || userRights.isUserReviewer()) && (
                  <div>  
                  <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Check</th>                   
                      <th scope="col">Question</th>
                      <th scope="col">Country|Language</th>
                      <th scope="col">Blooms</th>
                      <th scope="col">StatusName</th>
                      <th scope="col">Question Type</th>
                      <th scope="col">System Eval?</th>
                      {/* <th scope="col">Total Images</th>
                      <th scope="col">Designed By</th> */}
                      <th scope='col'>Created By</th>
                      <th scope="col">View</th>
                      <th scope="col">Edit</th>                   
                    </tr>
                  </thead>
                  {
                      questions.map((val, idx)=>{
                      let rows = [];
                      if (this.canShowQuestion(val))
                      {
                        let ii=0;
                        let child_content = val;
                        rows.push(
                          <tr key={val.sk+''+idx}>
                              <td>{ii+1}</td>
                              <td>        
                                <Form.Check type="checkbox" disabled={this.disableCheckBox(child_content.statusName, child_content.createdBy)} value={val.sk+","+"both"} onChange={this.onCheckBoxPressed}/>
                              </td>
                              <td><MathJax math={child_content.question} /></td>
                              <td>{child_content.country_language}</td>
                              <td>{val.bloomsLevel}</td>
                              <td>{child_content.statusName}</td>
                              <td>{val.category}</td>
                              <td>{val.isSystemEvaluated === true ? 'true' : 'false'}</td>
                              {/* <td>{child_content.totalImages || '0'}</td>
                              <td>{child_content.isDesignedBy}</td> */}
                              <td>{child_content.createdBy}</td>
                              <td onClick={()=>this.showQuestionByIndex(idx)}>View</td>
                              {(userRights.canEdit(child_content)) && (
                                <td onClick={()=>this.editQuestionByIndex(idx)}>Edit</td>)}
                            </tr>
                        )
                      }
                      return <tbody>{rows}</tbody> 
                    })
                }
                </table> 
                </div>   
                )}          
        </div>
  );
    }
}

