import React from 'react';
import ImageRegion from './imageRegion'
import DisplayImage from './displayImage';

const Images = (props) =>{
    
    let imageField = props.imageField;
    let className = props.className;
    let dataSetId = props.dataSetId;
    return(
            <div class='p-2'>
                <ImageRegion dataSetId={dataSetId} className={className} imageField ={imageField} storeFile={props.storeFile}/>
                <DisplayImage imageURL={props.imageURL} />
            </div> 
        )  
}            
export default Images
