import apis from '../apis/api';
import logger from '../helper/logger'
export const batchWriteToDB = async(jsonObject)=>{        
    try {
        console.log("BatchWriteDB follows ");
        console.log(jsonObject);
        let res = await apis._batchPut({"RESPONSES":jsonObject});
        return res;
    }
    catch(e)
    {
        logger.logError("batchWriteToDB","batchWriteToDB",e)
    }
}