import React from 'react';
import MathJax from 'react-mathjax-preview'
const MathJaxRegion = (props) =>{    
    let textField = props.textField; //sends the name of the prop
    return(
            <div>
                <label  class="form-control text-capitalize" htmlFor={`mathjax-${textField}` }>{`${textField} you typed`}</label>
                 <MathJax name={`mathjax-${textField}` } class="form-control" style={{color:"brown",fontSize:"bold"}} math = {props.typedValue} />
            </div>    
        )
       
}            

const label={
    color:"blue"
}

/*p.capitalize {
    text-transform: capitalize;
  }*/
export default MathJaxRegion
