import React from 'react';

const ImageRegion = (props) =>{
    return(
            <div >
                <label  style={label} htmlFor={props.imageField}>Upload image</label> 
                 <input className={props.className}
                    data-id={props.dataSetId} name={props.imageField} 
                    type="file" onChange={props.storeFile} />
            </div>    
        )
}            

const label={
    color:"blue"
}
/*p.capitalize {
    text-transform: capitalize;
  }*/
export default ImageRegion
