import React, {Component} from 'react';
//import './bootstrap.min.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Header from './components/layout/header';
import Home from './components/pages/home';
import About from './components/pages/about';
import Question from './components/pages/question';
import Session from './components/pages/session';
import {Amplify} from 'aws-amplify';
import config from '../config';
import userRights from './scripts/userRights';
import { withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui/dist/style.css'; //for cognito styling
import CreateSession from './components/session/create.view';
import Dashboard from './components/dashboard/dashboard.view';
import Subject from './components/pages/subject';
import AddSubject from './components/subject/add.view';
import CreateQuestion from './components/question/create.view';
import ListQuestions from './components/question/list.view';
import Report from './components/pages/report';
import Designer from './components/reports/designer.view';
import ListStatusQuestions from './components/question/status.view';
import Document from './components/pages/document';
import uploadDocument from './components/document/upload.view';
import UploadDocument from './components/document/upload.view';
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "rise",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "rises",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION        
      },
      {
        name: "rise-qualifier",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION        
      },
      {
        name: "rise-groupId",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION        
      },
      {
        name: "rise-statusName",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION        
      },
      {
        name: "rise-category",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION        
      },
      {
        name: "rise-status-updatedDate",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION          
      },
      {
        name: "rise-secondQualifier",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION          
      }
    ]
  }
});
class App extends Component { 

  componentDidMount=()=>{
    this.storeUser()
  }
  
  storeUser= async() => {
    /*To get the username from the Cognito user pool*/
    Auth.currentSession()
    .then(data => 
      {
        //debugger;
        console.log("cognito data " +data.refreshToken);
        localStorage.setItem("username",data.accessToken.payload.username);
        localStorage.setItem("userRight",data.accessToken.payload['cognito:groups'])
      })
    .catch(err => console.log(err));
  }

  render(){
      return (
        <Router>         
          <div className="App">
            <div className="">
            <Header  />
              <Route exact path="/" render={props => (
                <React.Fragment>
                  <h4 style={{textAlign:"center"}}>Welcome to the Content Management App</h4>      
                  <Dashboard/>
                </React.Fragment>
              )} />
               <Route path ='/about' component={About} />
               <Route path ='/home' component={Home} />
               <Route path ='/dashboard' component={Dashboard} />
               <Route path ='/question' component={Question} />  
               
               {(userRights.isUserAdmin() && (
                 <div>
                   <Route path ='/addSubject' component={AddSubject} /> 
                   <Route path ='/subject' component={Subject} /> 
                   <Route path ='/session' component={Session} /> 
                 </div>
               ))}
               {(userRights.isUserDeveloper() && ( 
                 <div>
                    <Route path ='/session' component={Session} /> 
                    <Route path ='/createQuestion' component = {CreateQuestion} />  
                    <Route path ='/createSession' component = {CreateSession} />  
                    <Route path ='/listStatusQuestions'  component = {ListStatusQuestions} /> 
                    <Route path ='/document' component={Document} />
                    <Route path='/uploadDocument' component = {UploadDocument} />
                  </div>
               ))}
               {
                 (userRights.isUserReviewer()) && (
                  <Route path ='/session' component={Session} />
                 )
               }
               {
                 ((userRights.isUserDesigner() || userRights.isUserAdmin()) && (
                   <div>
                      <Route path ='/report' component = {Report} />
                      <Route path ='/designer' component = {Designer} />
                  </div>
                 ))
               }
               <Route path ='/listQuestions' component={ListQuestions} />    
            </div>
          </div>
        </Router> 
      );
  }
}
export default withAuthenticator(App,{includeGreetings:true})
