import React from 'react';
import {Link} from 'react-router-dom';

export default function Session() {
      return (
          <div style={{textAlign:"center"}}><br></br>
            <Link to='/createSession'>Create a session</Link> | 
            <Link to='/dashboard'> List all sessions</Link> 
          </div>
        );   
}
