var ObjectID = require("bson-objectid");
import logger from '../../helper/logger';
const uploadDocumentScript = {

    processContentData: (data) => {
        let contentType = data.fileName.split('.')[1];
        let date = new Date().toISOString();
        let parentContentId = ObjectID();
        var contentParentData = {
            pk: `SKILL|${data.skillId}`,
            sk: `DOCUMENT|PARENT|${parentContentId}`,
            contentType: contentType,
            qualifier: 'PARENT|DOCUMENT',
            secondQualifier: 'DOCUMENT',
            groupId: parentContentId,
            skillName: data.skillName,
            contentId: parentContentId,
            statusName: 'PRODUCTION',
            isCopyQuestion: true,
            createdAt: date,
            updatedAt: date
        }
        let jsonObject = [];
        let rowObject = {}
        for (var key in contentParentData) {
            if (contentParentData.hasOwnProperty(key)) {
                logger.logMessage("createQuestion.script.js", "UpdateInDB", key);
                if (contentParentData[key].length !== 0) {
                    rowObject[key] = contentParentData[key]
                    logger.logMessage("createQuestion.script.js", "UpdateInDB", key + ' ' + contentParentData[key]);
                }
            }
        }
        jsonObject.push(rowObject);
        let indiaContentId = ObjectID();
        var contentIndiaData = {
            pk: `SKILL|${data.skillId}`,
            sk: `DOCUMENT|CHILD|${indiaContentId}`,
            contentType: contentType,
            fileName: data.fileName,
            fileLink: data.fileId,
            qualifier: 'CHILD|DOCUMENT|IN|EN',
            country_language: "IN|EN",
            secondQualifier: 'DOCUMENT',
            groupId: parentContentId,
            parentId: parentContentId,
            skillName: data.skillName,
            contentId: indiaContentId,
            statusName: 'PRODUCTION',
            isCopyQuestion: true,
            createdAt: date,
            updatedAt: date
        }
        rowObject = {}
        for (var key in contentIndiaData) {
            if (contentIndiaData.hasOwnProperty(key)) {
                logger.logMessage("createQuestion.script.js", "UpdateInDB for India", key);
                if (contentIndiaData[key].length !== 0) {
                    rowObject[key] = contentIndiaData[key]
                    logger.logMessage("createQuestion.script.js", "UpdateInDB for India", key + ' ' + contentIndiaData[key]);
                }
            }
        }
        jsonObject.push(rowObject);
        let usContentId = ObjectID();
        var contentUsData = {
            pk: `SKILL|${data.skillId}`,
            sk: `DOCUMENT|CHILD|${usContentId}`,
            contentType: contentType,
            fileName: data.fileName,
            fileLink: data.fileId,
            qualifier: 'CHILD|DOCUMENT|US|EN',
            country_language: "US|EN",
            secondQualifier: 'DOCUMENT',
            groupId: parentContentId,
            parentId: parentContentId,
            skillName: data.skillName,
            contentId: usContentId,
            statusName: 'PRODUCTION',
            isCopyQuestion: true,
            createdAt: date,
            updatedAt: date
        }
        rowObject = {}
        for (var key in contentUsData) {
            if (contentUsData.hasOwnProperty(key)) {
                logger.logMessage("createQuestion.script.js", "UpdateInDB for India", key);
                if (contentUsData[key].length !== 0) {
                    rowObject[key] = contentUsData[key]
                    logger.logMessage("createQuestion.script.js", "UpdateInDB for India", key + ' ' + contentUsData[key]);
                }
            }
        }
        jsonObject.push(rowObject);
        return jsonObject;
    }
}

export default uploadDocumentScript