const status = {
    isDraft :(statusName) =>
    {
    return (statusName === 'DRAFT')
    },
    isForReview :(statusName) =>
    {
    return (statusName === 'FOR REVIEW')
    },
    isForDesign:(statusName) => {
    return (statusName === 'FOR DESIGN')
    },
    isSentBack:(statusName) => {
    return ((statusName === 'SENT BACK') || (statusName === 'DESIGNER SENT BACK'))
    },
    isDesignDone:(statusName) => {
    return (statusName === 'DESIGN DONE')
    },
    isReviewed :(statusName) =>
    {
    return (statusName === 'REVIEWED')
    },
    isStaging :(statusName) =>
    {
    return (statusName === 'STAGING')
    }  
}  
export default status;