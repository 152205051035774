import React, {Component} from 'react';
import SkillList from '../skills/skillList';
import TextRegion from './textRegion'
import MathJaxRegion from '../mathjaxRegion'
import Pair from '../question/pair';
import {uploadToS3} from '../../scripts/s3';
import logger from '../../helper/logger'
import createQuestionScript from './create.script';
import Success from '../success';
import Fail from '../fail';
import MultiSelect from '../multiSelect';
import {isUndefined,isEmpty, isNotEmpty } from '../../scripts/validations';
import { batchWriteToDB } from '../../scripts/batchWriteToDB';


export default class CreateQuestion extends Component {
  
    constructor(props) {
    //  debugger
        super(props);
        this.state = {                
            bloomsLevel:'REMEMBER',
            options:[],
            question:'',
            hint:'',
            category: 'IB',//icebreaker, rapid fire, mathematical reasoning, logical reasoning
            qType:'mcq', //mcq, input, ms
            answers:[],
            qImage:'',
            eImage:'',
            oImages:[],
            explanation:'',
            copyQuestion:true,
            isEnabled:false,
            response:true,
            isLoading:false,
            totalImages:0,
            p_qImage:'',
            p_eImage:'',
            p_oImages:[],
            isSystemEvaluated:true,
            isLatex:false,
            subjectId:'',
            userType:'developer',
            username:'',
            subjectName:'MATH',
            skillName:'',
            skillId:''
        };
      }
      initialize = ()=>
      {
          this.setState({
            bloomsLevel:'REMEMBER',
            options:[],
            question:'',
            category: 'IB',//icebreaker, rapid fire, mathematical reasoning, logical reasoning
            qType:'mcq', //mcq, input, ms
            answers:[],
            qImage:'',
            eImage:'',
            oImages:[],
            explanation:'',
            copyQuestion:true,
            isEnabled:false,
            response:true,
            isLoading:false,
            totalImages:0,
            p_qImage:'',
            p_eImage:'',
            p_oImages:[],
            isSystemEvaluated:true,
            isLatex:false,
            subjectId:'',
            userType:'developer',
            username:'',
            subjectName:'MATH',
            skillName:'',
            skillId:'',
            hint:''
          })
      }

      addOption = (e) =>{
        debugger;
        let opt=[]
        this.setState((prevState) => ({
            options: [...prevState.options, opt],
          }));
      }

      setSkillName = (skillName) =>{
        this.setState({
          skillName,
        })
      }

      setSkillId = (skillId) => {
      //  debugger;
        this.setState({
          skillId
        })
      }

      setAnswers = (answers) => {
        //  debugger;
        console.log(answers);
          this.setState({
            answers
          })
      }
      //store images locally before uploading to s3 bucket
      storeFile = (e) =>{
        debugger;
        if (e.target.className.includes("option") ) {
          let oImages = [...this.state.oImages]   
          oImages[e.target.dataset.id]= e.target.files[0];
          this.setState({ oImages}, () => logger.logMessage("question.view.js","storeFile",'images '+this.state.oImages.length))
          this.showPreview(e.target.files[0],`p_oImages[${e.target.dataset.id}]`,e.target.dataset.id,e.target.className)
        } 
        else{
          this.setState({
            [e.target.name]:e.target.files[0],
            
          })
          this.showPreview(e.target.files[0],`p_${e.target.name}`)
        }
        let totalImages = this.state.totalImages;
        totalImages++;
        this.setState({totalImages});
      }

      showPreview = (file, stateName,idx=0, className='')=>{
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);      
         reader.onloadend = function (e) {
           if (className.includes("option"))
           {
            let p_oImages = [...this.state.p_oImages]   
            p_oImages[idx]= [reader.result]
            this.setState({
              p_oImages
            },()=>{
              console.log(this.state.p_oImages)
            })
           }
           else {
            this.setState({
                [stateName]: [reader.result]
            },()=>{
              console.log(this.state.p_qImage)
            })
           }
        }.bind(this);
      }
      //upload file to s3 bucket
      uploadFile = async(stateName,file, whichImage, idx) => {
          debugger;
           let name = await uploadToS3(file, whichImage);
   
           if (stateName === 'qImage' || stateName === 'eImage')
           {
             let result = await this.setState({ [stateName]: name})
             logger.logMessage("question.view.js","uploadFile","Uploaded images to s3 successfully")
             return result
           }
           else {
             let oImages = [...this.state.oImages]   
             oImages[idx]= name
             let result = await this.setState({ oImages })
             logger.logMessage("question.view.js","uploadFile","Uploaded images to s3 successfully")   
             return result     
           } 
      }

      handleChange = (e) => {
        if (e.target.name.includes("Image"))
          return
        if (e.target.className.includes("option")) {
          let options = [...this.state.options]   
          options[e.target.dataset.id]= e.target.value
          this.setState({ options }, () => logger.logMessage("question.view.js","handleChange",this.state.options))
        }        
        else {         
            this.setState({ [e.target.name]: e.target.value })
        }
      }
      
      handleSubmit = (event) =>{
        this.cleanUpOptionsIfEmpty()
        if (this.doOptionsHaveImagesAndText())
        {     
          alert("Either all options have to be text or all of them have to be images. Cannot have both.")
          return;
        }
        event.preventDefault();
        this.setState({
          isLoading:true
        })
        this.uploadAllFiles();        
      }

      doOptionsHaveImagesAndText=()=>{
        if (isNotEmpty(this.state.options) && isNotEmpty(this.state.oImages))
          return true;
        return false;  
      }

      cleanUpOptionsIfEmpty =()=>{
        if (!isUndefined(this.state.options))
        {
          for (var ii=0;ii<this.state.options.length;ii++)
          {
            if (isEmpty(this.state.options[ii]))
            {
              this.state.options = [];
            }
          }
        }
      }

      uploadAllFiles = async()=>
      {         
        if (this.state.qImage !== '')
        {    
          debugger;
          const res = await this.uploadFile('qImage',this.state.qImage, "Question image ")
          logger.logMessage("question.view.js","uploadAllFiles","Uploaded qImage")   
          //this.setState({uploaded:true})
        }
        if (this.state.eImage !== '')
        {          
          await this.uploadFile('eImage',this.state.eImage, "Explanation image ")
          logger.logMessage("question.view.js","uploadAllFiles","Uploaded eImage")  
          //this.setState({uploaded:true})
        }
        for (var ii=0;ii<this.state.oImages.length;ii++)
        {          
          await this.uploadFile(`oImages[${ii}]`,this.state.oImages[ii], "Option image ",ii)
          logger.logMessage("question.view.js","uploadAllFiles","Uploaded options image"+ii)  
          //this.setState({uploaded:true})
        }
        this.updateDB();
      }
      updateDB = async() => {        
        //store content in localstorage so that in case of page refresh it still persists
        createQuestionScript.saveQuestionLocalStorage(this.state);  
        let jsonObject = createQuestionScript.processContentData(this.state);
        console.log(jsonObject);
        logger.logMessage("question.view.js","updateDB",jsonObject);      
        let response = await batchWriteToDB(jsonObject);

        this.setState({
            questionUpdated:true,
            isLoading:false,
            response:true
        })
      }
      goBack=(restore)=>{
        this.setState({
          questionUpdated:false,
        })
        if (restore)
          this.restorePrevQuestion();
        else
          this.initialize();  
      }  
      allElementsFilled=()=>{
        if (isEmpty(this.state.skillId))
          return false;
        if (isEmpty(this.state.question) && isEmpty(this.state.qImage))
          return false;
        if (this.state.category === 'PR' && this.state.qType === 'input')
          return true;
        if (this.state.qType === 'url')
          return true;
        if (isEmpty(this.state.answers))
          return false;
        if ((this.state.qType !== 'input') && (isEmpty(this.state.options) && isEmpty(this.state.oImages)))
          return false;
        if (isEmpty(this.state.explanation) && isEmpty(this.state.eImage))
          return false;  
        return true;
      }
      render(){      
        let options = this.state.options
        if (this.state.isLoading)
          return <div class="text-center font-weight-bold text-primary"><h4>Adding questions. Please be patient......</h4></div>;
        if (this.state.questionUpdated && this.state.response)
          return (<Success goBack={this.goBack} />)
        if (this.state.questionUpdated && this.state.response === false)
          return (<Fail goBack={this.goBack} />)
        return (
          <div class='container'>
                <h4 style={{textAlign:"center"}}>Add a question</h4>
                <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                <div class="form-group">
                        <label for="category">Choose Question Category</label>
                          <select class="form-control" value={this.state.category} name="category">
                              <option selected value="IB">ICEBREAKER</option>
                              <option  value="RF">RAPID FIRE</option>
                              <option value="MR">MATHEMATICAL REASONING</option>
                              <option value="LR">LOGICAL REASONING</option>
                              <option value="PR">PROJECT</option>
                              <option value="RV">REVISION</option>
                          </select>
                </div>
                  <div class="form-group">
                        <label for="bloomsLevel">Choose Blooms level</label>
                          <select class="form-control" value={this.state.bloomsLevel} name="bloomsLevel">
                              <option selected value="REMEMBER">REMEMBER</option>
                              <option  value="UNDERSTAND">UNDERSTAND</option>
                              <option value="APPLY">APPLY</option>
                              <option value="ANALYSE">ANALYSE</option>
                              <option value="HOTS">HOTS</option>
                          </select>
                    </div>
                    <div class="form-group border border-primary">
                      <Pair textField="question" imageField="qImage" 
                            typedValue={this.state.question} 
                            storeFile={this.storeFile} 
                            imageURL={this.state.p_qImage} 
                            className="form-control"
                      />
                    </div>
                    <div class="form-group border border-primary">
                      <TextRegion textField="hint" className="form-control" dataSetId={1} />
                      <MathJaxRegion textField="hint" typedValue ={this.state.hint} />
                    </div>
                    <div class="form-group">
                      <label for="qType">Choose a type</label>
                      <select class="form-control" value={this.state.qType} name="qType">
                          <option selected value="mcq">Multiple Choice</option>
                          <option  value="multiple">Multiple Selection</option>
                          <option value="input">Input</option>
                          <option value="url">URL</option>
                      </select>
                    </div>
                    <br/>
                    <div>
                      <p style={{color:"red"}}>Note: You can choose all options to have images or all texts. But not both</p>
                    </div>
                    <div class="form-group">
                      {((this.state.qType === "mcq") || (this.state.qType === "multiple")) && 
                      (<button class="btn btn-primary" type="button" onClick={this.addOption}>Add an option</button>)}
                    </div>
                    <br/>{(this.state.qType !== 'input') && (                   
                      options.map((val,idx) => {
                        return (
                        <div class="border border-primary" key={idx} >
                            <Pair textField={`option${idx+1}`} imageField={`oImage${idx+1}`} 
                                  typedValue={`${this.state.options[idx]}`} 
                                  storeFile={this.storeFile}
                                  className = "option form-control"
                                  dataSetId={idx}
                                  imageURL ={`${this.state.p_oImages[idx]}`}
                             />
                        </div>
                        )
                      })
                     )}
                      {(this.state.qType === 'mcq') && (                        
                          <div class="form-group">
                          <label for="answers">Choose the answer</label>
                          <select class="form-control"  value={this.state.answers} name="answers">
                              <option selected>Select an option</option>
                              <option value={0}>option 1</option>
                              <option  value={1}>option 2</option>
                              <option value={2}>option 3</option>
                              <option value={3}>option 4</option>
                          </select></div>
                      )}
                    {(this.state.qType === 'multiple') && (                                            
                      <div>
                        <br/><br/> 
                          <MultiSelect options={[{label:"option 1", value:0},
                                                {label:"option 2",value:1},
                                                {label:"option 3",value:2},
                                                {label:"option 4",value:3}]} triggerUpdate={this.setAnswers}/>
                     <br/><br/> 
                      </div>
                    )}<br/>
                    {(this.state.qType === 'input') && (                        
                      <div>
                          <label  htmlFor="answers">Type all acceptable answers separated by pipeline symbol | like One|1|2|Two</label>
                          <input class='form-control' name="answers" 
                          type="text" value={this.state.answers} />
                      </div>
                    )}<br/><br/>
                    
                    <div class="form-group border border-primary">
                      <Pair textField="explanation" imageField="eImage" 
                          typedValue={this.state.explanation} 
                          storeFile={this.storeFile} answer
                          className="form-control"
                          imageURL = {this.state.p_eImage}
                          />
                    </div>
                    <div class="form-group" >
                        <SkillList class="form-group" 
                          setSkillId={this.setSkillId}     
                          setSkillName={this.setSkillName}
                        />
                      </div>
                    {(this.state.qType === 'input') && (
                        <div class="form-group">
                        <label for="isSystemEvaluated">Can the computer evaluate the answer?</label>
                        <select class="form-control"  value={this.state.isSystemEvaluated} name="isSystemEvaluated">
                            <option selected value={true}>YES</option>
                            <option  value={false}>NO</option>
                        </select></div>  
                    )}
                    <div class="form-group">
                          <label for="isLatex">Have you used MathJax anywhere in question, explanations, options or hints?</label>
                          <select class="form-control"  value={this.state.isLatex} name="isLatex">
                              <option selected value={false}>NO</option>
                              <option  value={true}>YES</option>
                          </select></div>    
                    <div class="form-group">
                          <label for="isLatex">The same question will be used in India and US. If you pick No, then too I will create a copy. Please make sure to Edit the copy</label>
                          <select class="form-control"  value={this.state.copyQuestion} name="copyQuestion">
                              <option  value={true}>YES</option>
                              <option selected value={false}>NO</option>                              
                          </select></div>                                                 
                    <input  disabled={!this.allElementsFilled()} class="btn btn-primary mb-2"  type="submit" value="Submit" />
                      <p>Note: Pick atleast one skill</p>
                </form>
                
         </div>
    );
    }
}

