
import React, {Component} from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

export default class  MultiSelect extends Component {

    onSelect=(e)=>{
        this.props.triggerUpdate(e)
       // console.log('Inside showList '+e.value)    
    }
    render(){
        if (this.props.options.length === 0)
        return(
            <div>Loading</div>
        )
        return (            
          <div>
                <ReactMultiSelectCheckboxes 
                options={this.props.options} 
                onChange={this.onSelect}
                value={this.selectedOptions}/>
         </div>
         );
    }
}


