
const logger = {
    logMessage: ((componentName, functionName,message) => {
        console.log({componentName:componentName,functionName :functionName,message :message});
    }),
    logError: ((componentName, functionName,err) => {
        console.log({componentName:componentName,functionName :functionName,error:err});
    })
}

export default logger;