import logger from '../../helper/logger'
import userProfile from '../../scripts/userProfile';
import editSessionScript from '../session/edit.script';
import {isNotEmpty, isEmpty, isUndefined, isTrue} from '../../scripts/validations';

const statusIsReviewedAndTested =(status)=>
{
  return (status === "REVIEWED")
}
const statusIsSentBack = (status)=>
{
  return(status==='SENT BACK')
}
const statusIsDesignDone = (status)=>
{
  return (status==='DESIGN DONE')
}
const statusIsProduction =(status)=>
{
  return (status === "PRODUCTION")
}
const findTotalImages=(data)=>{

  let totalImages =0;
  if (isNotEmpty(data.qImage))
    totalImages++;
  if (isNotEmpty(data.eImage))
    totalImages++;
  if (isNotEmpty(data.oImages))
    totalImages += data.oImages.length;
  return totalImages;
}
const processAnswers = (qType, answers)=>{
  if (qType === "mcq")
    return mcqAnswers(answers);
  if (qType === "multiple")
    return multipleAnswers(answers);
  else  
    return inputAnswers(answers);  
};
const mcqAnswers = (answer)=>{
  console.log("answer in mcq "+answer);
  let ans = [];
  ans[0]= Number(answer);
  return ans;
};

const multipleAnswers = (answers)=>{
  let ans = [];

  let ii=0
  for (var key in answers)
  {
      ans[ii] = answers[key].value === undefined ? answers[ii]:answers[key].value;
      ii++;
  }
  
  return ans;
};

const inputAnswers = (answers)=>{
  try{
      let ans = answers.split("|");
      for (let ii=0;ii<ans.length;ii++)
        ans[ii] = ans[ii].trim();
      console.log(ans);
      return ans;  
    }
  catch(e)
  {
    console.log("Cannot split");
    return answers;
  }
  
};

const getParentStatus=(first_child, second_child, status)=>{
  if (status === "PRODUCTION")
  {
    if (isTrue(first_child.copyQuestion))
      return "PRODUCTION";
   else if (second_child.statusName === 'PRODUCTION')
      return "PRODUCTION";   
  }
  return;  
}

const updateSession = (sessions, parentId, category, data1, data2, status)=>{

 // let session = { IB:[]}
  for (let ii = 0; ii<sessions.length;ii++)
  {
    let session = sessions[ii];
    let index = session[category] ? session[category].findIndex(cat => cat.contentId === parentId) : -1;
    if (index !== -1)
    {
      if (session[category][index].attributes.country_language = data1.country_language)
        session[category][index].attributes = data1;
      else
        session[category][index].attributes = data2;  
      session[category][index].attributes.statusName = status;
      return;
    }
  }
}
const editQuestionScript = { 

    processContentData:(pk, parent_data, child_data, options, oImages, 
                            second_child_data, skillName)=>{
      
      //Sometimes the bool gets converted to string. To prevent that
      let copyQuestion = parent_data.copyQuestion === "false" || parent_data.copyQuestion === false ? false : true;
      let answers=[];
      answers = parent_data.category === 'PR' || parent_data.qType === 'url'? '':processAnswers(parent_data.qType, child_data.answers); 
      let parentStatus = getParentStatus(child_data, second_child_data, child_data.statusName);
      if (isEmpty(skillName))
        skillName = parent_data.skillName;
      try{
        let date = new Date();
        date = date.toISOString();

        //pk is 'SKILL|SkillId', sk is contentId for parent and child
        let jsonObject=[];
        const parentData = {
          "pk":pk,
          "sk":parent_data.sk,
          "qType":parent_data.qType,
          "bloomsLevel":parent_data.bloomsLevel,
          "category":parent_data.category,
          "contentId":parent_data.contentId,
          "groupId":parent_data.contentId,
          "tags":parent_data.tags,
          "tagDetails":parent_data.tagDetails,
          "isLatex":parent_data.isLatex || false,
          "isSystemEvaluated":parent_data.isSystemEvaluated,
          "skillName": skillName,
          "statusName": parentStatus,
          "copyQuestion": copyQuestion,
          "qualifier":parent_data.qualifier,
          "secondQualifier": 'CONTENT',
          "updatedAt":date,
          "createdAt":parent_data.createdAt || date,
          "createdBy":parent_data.createdBy || userProfile.myName(),          
        }
        var rowObject = {}        
        for (var key in parentData) {
          if (parentData.hasOwnProperty(key)) {
            logger.logMessage("editQuestion.script.js","Process Parent Content Data",key);
            if (!isUndefined(parentData[key]))
            {
              rowObject[key] = parentData[key] 
              logger.logMessage("editQuestion.view.js","Process Parent Content Data",key + ' '+parentData[key]);
            }
          }
        }
        jsonObject.push(rowObject);
        let totalImages = findTotalImages(child_data);
        const firstChildData = 
        {
          "pk":pk,	
          "sk":child_data.sk,
          "country_language":child_data.country_language,
          "question":child_data.question,
          "hint":child_data.hint,
          "qImage":child_data.qImage,
          "options":options,
          "oImages":oImages,
          "answers":answers,
          "explanation":child_data.explanation,
          "eImage":child_data.eImage,
          "category":parent_data.category,
          "skillName": skillName,
          "contentId":child_data.contentId,
          "parentId":child_data.parentId,
          "groupId":child_data.parentId,
          "totalImages":totalImages,
          "copyQuestion":copyQuestion,
          "updatedAt":date,
          "createdAt":child_data.createdAt || date,
          "createdBy":child_data.createdBy || userProfile.myName(),
          "qualifier": child_data.qualifier,
          "secondQualifier": 'CONTENT',
          "statusName":child_data.statusName || 'DRAFT', //To prevent the status from becoming empty
          "comments":child_data.comments,
          "isReviewedOn":child_data.isReviewedOn,
          "isReviewedBy":child_data.isReviewedBy,
          "isDesignedOn":child_data.isDesignedOn,
          "isDesignedBy":child_data.isDesignedBy,
          "isProductionOn":child_data.isProductionOn,
          "isProductionBy":child_data.isProductionBy,
          "videoURL": child_data.videoURL,
        }        
        rowObject = {}  
        for (var key in firstChildData) {
          if (firstChildData.hasOwnProperty(key)) {
            logger.logMessage("editQuestion.script.js","Process Child Content Data",key);
            if (!isUndefined(firstChildData[key]))
            {
              rowObject[key] = firstChildData[key] 
              logger.logMessage("editQuestion.view.js","Process Child Content Data",key + ' '+firstChildData[key]);
            }
          }
        }
        jsonObject.push(rowObject); 
        //When the designer or reviewer edits and saves, there is a possibility that the second child is empty
        //This happens when both the children are not a copy of each and if one child has DESIGN status and another does not have a design status
        //In this case, we ignore the second child and we do not update the second child
        if (!isUndefined(second_child_data))
        {
          let secondChildData;
          if (parent_data.copyQuestion === true)
          {
            secondChildData = 
            {
              "pk":pk,	
              "sk":second_child_data.sk,
              "country_language":second_child_data.country_language,
              "question":child_data.question,
              "hint":child_data.hint,
              "qImage":child_data.qImage,
              "options":options,
              "oImages":oImages,
              "answers":answers,
              "explanation":child_data.explanation,
              "eImage":child_data.eImage,
              "category":parent_data.category,
              "skillName": skillName,
              "contentId":second_child_data.contentId,
              "parentId":second_child_data.parentId,
              "groupId":second_child_data.parentId,
              "totalImages":totalImages,
              "copyQuestion":copyQuestion,
              "updatedAt":date,
              "createdAt":child_data.createdAt || date,
              "createdBy":child_data.createdBy || userProfile.myName(),
              "qualifier": second_child_data.qualifier,
              "secondQualifier": 'CONTENT',
              "statusName":child_data.statusName || 'DRAFT', //To prevent the status from becoming empty
              "comments":child_data.comments,
              "isReviewedOn":child_data.isReviewedOn,
              "isReviewedBy":child_data.isReviewedBy,
              "isDesignedOn":child_data.isDesignedOn,
              "isDesignedBy":child_data.isDesignedBy,
              "isProductionOn":child_data.isProductionOn,
              "isProductionBy":child_data.isProductionBy,
              "videoURL": child_data.videoURL,
            }  
          }
          else //we still have to update because the skill or copyquestion parameter would have changed
          {
              secondChildData = 
              {
                "pk":pk,	//same for all
                "sk":second_child_data.sk,
                "country_language":second_child_data.country_language,
                "question":second_child_data.question,
                "hint":second_child_data.hint,
                "qImage":second_child_data.qImage,
                "options":second_child_data.options,
                "oImages":second_child_data.oImages,
                "answers":second_child_data.answers,
                "category":parent_data.category,
                "explanation":second_child_data.explanation,
                "eImage":second_child_data.eImage,
                "contentId":second_child_data.contentId,
                "parentId":second_child_data.parentId,
                "groupId":second_child_data.parentId,
                "skillName": skillName,
                "totalImages":second_child_data.totalImages,
                "copyQuestion":copyQuestion, //copy from parent
                "updatedAt":date,
                "createdAt":second_child_data.createdAt || date,
                "createdBy":second_child_data.createdBy || userProfile.myName(),
                "qualifier": second_child_data.qualifier,
                "secondQualifier": 'CONTENT',
                "statusName":second_child_data.statusName,
                "comments":second_child_data.comments,
                "isReviewedOn":second_child_data.isReviewedOn,
                "isReviewedBy":second_child_data.isReviewedBy,
                "isDesignedOn":second_child_data.isDesignedOn,
                "isDesignedBy":second_child_data.isDesignedBy,
                "isProductionOn":second_child_data.isProductionOn,
                "isProductionBy":second_child_data.isProductionBy,
                "videoURL": second_child_data.videoURL,
              }  
            
          }
          rowObject = {}  
          for (var key in secondChildData) {
            if (secondChildData.hasOwnProperty(key)) {
              logger.logMessage("editQuestion.script.js","Process Child Content Data",key);
              if (!isUndefined(secondChildData[key]))
              {
                rowObject[key] = secondChildData[key] 
                logger.logMessage("editQuestion.view.js","Process Child Content Data",key + ' '+secondChildData[key]);
              }
            }
          }
          jsonObject.push(rowObject);  
        }              
        return jsonObject;
      }
      catch(error){
        logger.logError("editQuestion.view.js","processContentData",error);
      }
    },

    processUpdateStatus:async(parent_data, first_child_data, second_child_data, status)=>{

      //We update the parent's status only if it is PRODUCTION. 
      //However, we need to update the date for the parent even if any child gets updated
      let answers=[];
      answers = parent_data.category === 'PR' || parent_data.qType === 'url' ? '':processAnswers(parent_data.qType, first_child_data.answers); 
      let parentStatus = getParentStatus(first_child_data, second_child_data, status);
      try{
      let date = new Date();
      date = date.toISOString();
      let jsonObject=[];
      const parentData = {
        "pk":parent_data.pk,
        "sk":parent_data.sk,
        "qType":parent_data.qType,
        "bloomsLevel":parent_data.bloomsLevel,
        "category":parent_data.category,
        "contentId":parent_data.contentId,
        "groupId":parent_data.contentId,
        "tags":parent_data.tags,
        "tagDetails":parent_data.tagDetails,
        "isLatex":parent_data.isLatex || false,
        "isSystemEvaluated":parent_data.isSystemEvaluated,
        "copyQuestion": parent_data.copyQuestion,
        "skillName": parent_data.skillName,
        "qualifier":parent_data.qualifier,
        "statusName":parentStatus,
        "secondQualifier": 'CONTENT',
        "updatedAt":date,
        "createdAt":parent_data.createdAt || date,
        "createdBy":parent_data.createdBy || userProfile.myName(),          
      }
      let rowObject = {}        
      for (var key in parentData) {
        if (parentData.hasOwnProperty(key)) {
          logger.logMessage("editQuestion.script.js","Process Parent Content Data",key);
          if (!isUndefined(parentData[key]))
          {
            rowObject[key] = parentData[key] 
            logger.logMessage("editQuestion.view.js","Process Parent Content Data",key + ' '+parentData[key]);
          }
        }
      }
      jsonObject.push(rowObject);
      const firstChildData = 
      {
        "pk":first_child_data.pk,	
        "sk":first_child_data.sk,
        "country_language":first_child_data.country_language,
        "question":first_child_data.question,
        "hint":first_child_data.hint,
        "qImage":first_child_data.qImage,
        "options":first_child_data.options,
        "oImages":first_child_data.oImages,
        "answers":answers,
        "explanation":first_child_data.explanation,
        "eImage":first_child_data.eImage,
        "category":parent_data.category,
        "skillName": parent_data.skillName,
        "contentId":first_child_data.contentId,
        "groupId":first_child_data.parentId,
        "parentId":first_child_data.parentId,
        "totalImages":first_child_data.totalImages,
        "copyQuestion":first_child_data.copyQuestion,
        "updatedAt":date,
        "createdAt":first_child_data.createdAt || date,
        "createdBy":first_child_data.createdBy || userProfile.myName(),
        "qualifier": first_child_data.qualifier,
        "secondQualifier": 'CONTENT',
        "statusName":status, 
        "comments":first_child_data.comments,
        "isReviewedOn":first_child_data.isReviewedOn,
        "isReviewedBy":first_child_data.isReviewedBy,
        "isDesignedOn":first_child_data.isDesignedOn,
        "isDesignedBy":first_child_data.isDesignedBy,
        "isProductionOn":first_child_data.isProductionOn,
        "isProductionBy":first_child_data.isProductionBy
      }        
      if (statusIsReviewedAndTested(status) || statusIsSentBack(status))
      {
        firstChildData["isReviewedBy"] = userProfile.myName();
        firstChildData["isReviewedOn"] = date;
      }
      else if (statusIsDesignDone(status))
      {
        firstChildData["isDesignedBy"] = userProfile.myName();
        firstChildData["isDesignedOn"] = date;
      }
      else if (statusIsProduction(status))
      {
        firstChildData["isProductionBy"] = userProfile.myName();
        firstChildData["isProductionOn"] = date;
      }
      rowObject = {}  
      for (var key in firstChildData) {
        if (firstChildData.hasOwnProperty(key)) {
          logger.logMessage("editQuestion.script.js","Process Child Content Data",key);
          if (!isUndefined(firstChildData[key]))
          {
            rowObject[key] = firstChildData[key] 
            logger.logMessage("editQuestion.view.js","Process Child Content Data",key + ' '+firstChildData[key]);
          }
        }
      }
      jsonObject.push(rowObject); 
      
      if (first_child_data.copyQuestion === true)
      {
        let secondChildData = 
        {
          "pk":second_child_data.pk,	
          "sk":second_child_data.sk,
          "country_language":second_child_data.country_language,
          "question":firstChildData.question,
          "hint":firstChildData.hint,
          "qImage":firstChildData.qImage,
          "options":firstChildData.options,
          "oImages":firstChildData.oImages,
          "answers":answers,
          "explanation":firstChildData.explanation,
          "eImage":firstChildData.eImage,
          "category":parent_data.category,
          "skillName": parent_data.skillName,
          "contentId":second_child_data.contentId,
          "groupId":second_child_data.parentId,
          "parentId":second_child_data.parentId,
          "totalImages":firstChildData.totalImages,
          "copyQuestion":firstChildData.copyQuestion,
          "updatedAt":date,
          "createdAt":firstChildData.createdAt || date,
          "createdBy":firstChildData.createdBy || userProfile.myName(),
          "qualifier": second_child_data.qualifier,
          "secondQualifier": 'CONTENT',
          "statusName":status, //To prevent the status from becoming empty
          "comments":firstChildData.comments,
          "isReviewedOn":firstChildData.isReviewedOn,
          "isReviewedBy":firstChildData.isReviewedBy,
          "isDesignedOn":firstChildData.isDesignedOn,
          "isDesignedBy":firstChildData.isDesignedBy,
          "isProductionOn":firstChildData.isProductionOn,
          "isProductionBy":firstChildData.isProductionBy
        }    
        rowObject = {}  
        for (var key in secondChildData) {
          if (secondChildData.hasOwnProperty(key)) {
            logger.logMessage("editQuestion.script.js","Process Child Content Data",key);
            if (!isUndefined(secondChildData[key]))
            {
              rowObject[key] = secondChildData[key] 
              logger.logMessage("editQuestion.view.js","Process Child Content Data",key + ' '+secondChildData[key]);
            }
          }
        }
        jsonObject.push(rowObject);
      }  
      //update the session where this question is used
    //  console.log(jsonObject);
      if (parent_data.tags)
      {
        //assuming that there is only 1 tag per question

        console.log(parent_data.tags);
        let sessions = await editSessionScript.getSessions(parent_data.tags[0]);
        updateSession(sessions, parent_data.contentId, parent_data.category, first_child_data, second_child_data, status);

        for (let ii=0;ii<sessions.length;ii++)
          jsonObject[jsonObject.length] =  sessions[ii];

        console.log(jsonObject);
      }
      return jsonObject;
    }
    catch(error)
    {
      logger.logError("editScriptQuestion.view.js","processUpdateStatus",error);
    }
  }

}
export default editQuestionScript