import React, {Component} from 'react';

import MyDropDown from '../../components/myDropDown';
export default class ListSubjects extends Component {

    constructor(props)
    {
        super(props); 
    }
    
    render(){
     const SUBJECTS = [{label: "Mathematics", value: "MATH"},
                       {label: "Computer Science", value: "CS"} ]
        return (
            <div class='container'>
                <div class="form-group" >             
                    <MyDropDown                     
                     triggerUpdate={this.props.setSubject} 
                     options={SUBJECTS}
                     value = {'MATH'}
                    />
                  </div>
                  
              </div>
         );
    }
}
