import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SkillList from '../skills/skillList';
import MultiSelect from '../multiSelect';
import logger from '../../helper/logger';
import MathJaxRegion from '../mathjaxRegion';
import Comments from '../comments';
import DisplayImage from '../displayImage';
import Images from '../images';
import editQuestionScript from './edit.script'

import { batchWriteToDB } from '../../scripts/batchWriteToDB';
import {uploadToS3, deleteFromS3, getFromS3} from '../../scripts/s3';
import userProfile from '../../scripts/userProfile';
import userRights from '../../scripts/userRights';
import {deleteQuestion} from '../../scripts/delete'
import {isNotEmpty, isEmpty,isUndefined} from '../../scripts/validations';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit,faCircleArrowLeft} from "@fortawesome/free-solid-svg-icons";
library.add(faEdit,faCircleArrowLeft);

export default class EditQuestion extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            question:props.question,
            parent:props.parent,
            secondChild:props.secondChild,
            uploaded:false,
            org_question:'',
            org_parent:'',
            org_option_images:'',
            display_qImage:'',
            display_eImage:'',
            display_oImages:'',
            options:props.question["options"],
            oImages:props.question["oImages"],
            questionUpdated:'',
            isLoading:false,
            comments:'',
            skillId:'',
            currentSkillName:props.skillName,
            newSkillName:'',
            p_oImages:[''],
            showModal:false,
            videoURL: '',
        }
    }
    componentDidMount(){
      let org_question = {...this.state.question}
      console.log("Question...", org_question)
      let org_parent = {...this.state.parent}
      let org_option_images={...this.state.question["oImages"]}
      this.setState({
        org_question,
        org_parent,
        org_option_images
      })
      this.processImagesFromS3ToDisplay();
    }
    processImagesFromS3ToDisplay=async()=>{
      let question = {...this.state.question}
      if (question.qImage)
      {
        let image = await getFromS3(question.qImage);
        this.setState({display_qImage: image});
      }
      if (question.eImage)
      {
        let image = await getFromS3(question.eImage);
        this.setState({display_eImage: image});
      }
      if (question.oImages)
      {
        let images=[];
        for (var ii=0;ii<question.oImages.length;ii++)
        {
          images[ii] = await getFromS3(question.oImages[ii]);
        }
        this.setState({display_oImages:images})
      }      
    }
   // this.setState(prevState => ({showContacts: !prevState.showContacts}))
    toggleField=(stateName)=>{
        this.setState(prevState=>({
            [stateName]: !prevState[stateName]
        }))
    }
    doesTargetContainImage(target)
    {
      return (target.includes("Image"))
    }
    doesTargetContainOptions(target)
    {
      return (target.includes("option"))
    }
    doesTargetContainComments(target)
    {
      return (target.includes("comments"))
    }
    doesTargetContainerVideoURL(target)
    {
      return (target.includes("video"))
    }
    handleChange = (e) => {
        let question = this.state.question;
        if (this.doesTargetContainImage(e.target.name))
          return;
        else if (this.doesTargetContainOptions(e.target.className))
        {
            let options = [...this.state.options]   
            options[e.target.dataset.id]= e.target.value
            this.setState({ options }, () => logger.logMessage("editQuestion.view.js","handleChange",
            this.state.options))
        }   
        else if (this.doesTargetContainComments(e.target.name)){
          this.setState({
            comments: e.target.value
          })
        }
        else if (this.doesTargetContainerVideoURL(e.target.name))
          {
            this.setState({
              videoURL: e.target.value
            })
          }
        else
            question[e.target.name] = e.target.value;
        this.setState({question})
    }
    handleChangeParent=(e)=>{
      let parent = this.state.parent;
      parent[e.target.name] = e.target.value;
      this.setState({parent})
    }
    showModal=()=>
    {
      if (this.state.parent["copyQuestion"] === false || this.state.parent["copyQuestion"] === "false"){
        this.setState({showModal:true});
      }
      else
        this.handleSubmit();
    }  
    handleSubmit = () =>{
      this.cleanUpOptionsIfEmpty();
      if (this.doOptionsHaveImagesAndText())
      {
        alert("You can either have all options as text or all options as images, but not both");
        return;
      }
      this.setState({
        isLoading:true
      })
      this.uploadAllFiles();              
    }
    doOptionsHaveImagesAndText=()=>{
      if (isNotEmpty(this.state.options) && isNotEmpty(this.state.oImages))
        return true;
      return false;  
    }
    cleanUpOptionsIfEmpty =()=>{
      if (!isUndefined(this.state.options))
      {
        for (var ii=0;ii<this.state.options.length;ii++)
        {
          if (isEmpty(this.state.options[ii]))
          {
            let options = []
            this.setState({
              options
            })
          }
        }
      }
    }
    //upload file to s3 bucket
    uploadFile = async(stateName,file, whichImage, idx) => {
      let name = await uploadToS3(file, whichImage);
      if (stateName === 'qImage' || stateName === 'eImage')
      {
        let question = this.state.question
        question[stateName] = name
        let result = await this.setState({question})
        logger.logMessage("editQuestion.view.js","uploadFile","Uploaded new images to s3 successfully")
      }
      else {
        let oImages = this.state.oImages;
        oImages[idx]= name
        // let oImages = [...this.state.oImages]   
        // oImages[idx]= name
        let result = await this.setState({ oImages })
        logger.logMessage("question.view.js","uploadFile","Uploaded images to s3 successfully")   
        return result     
      } 
    }
    uploadAllFiles = async()=>
    {        
      //to check if the image was edited
      if (this.hasImageChanged("qImage"))
      {  
        await deleteFromS3(this.state.org_question["qImage"]);
        const res = await this.uploadFile('qImage',this.state.question["qImage"], "Question image ")
        logger.logMessage("editQuestion.view.js","uploadAllFiles","Uploaded qImage")   
        this.setState({uploaded:true})
      }
      if (this.hasImageChanged("eImage"))
      {          
        await deleteFromS3(this.state.org_question["eImage"]);
        await this.uploadFile('eImage',this.state.question["eImage"], "Explanation image ")
        logger.logMessage("editQuestion.view","uploadAllFiles","Uploaded eImage")  
        this.setState({uploaded:true})
      }
     
      if (!isUndefined(this.state.oImages)){
      for (var ii=0;ii<this.state.oImages.length;ii++)
      {        
        if (this.hasOptionImageChanged(ii))  
        {
          console.log("Inside upload")
          await deleteFromS3(this.state.org_option_images[ii]);
          await this.uploadFile(`oImages[${ii}]`,this.state.oImages[ii], "Option image ",ii)
          logger.logMessage("question.view.js","uploadAllFiles","Uploaded options image"+ii)  
          this.setState({uploaded:true})
        }
      }}
      this.processContentData();
    }
    hasImageChanged(image)
    {
      return (this.state.question[image] !== this.state.org_question[image]) && 
            (this.state.question[image] !== '')
    }
    hasOptionImageChanged(idx)
    {
      if (isUndefined(this.state.org_option_images[idx]))
        return true;
      return (this.state.oImages[idx] !== this.state.org_option_images[idx])
    }

    appendCommentsToQuestion()
    {
      let date = new Date();
      date = date.toISOString();
      let question = {...this.state.question}
      if (isNotEmpty(this.state.comments))
      {
        let comment = {comment:{text:this.state.comments,postedAt:date,postedBy:userProfile.myName()}}
        let old_comment = question["comments"]||[];
        old_comment.push(comment)
        console.log(old_comment);
        question["comments"] = old_comment;
      }
      this.setState({
          question
        },()=>{
          return true;
        })
    }
    appendVideoToQuestion()
    {
      let question = {...this.state.question}
      if (isNotEmpty(this.state.videoURL))
      {
        question['videoURL'] = this.state.videoURL
      }
      this.setState({
        question
      },()=>{
        return true;
      })
    }
    setPublish = async(publish) =>{
      //publish will bring the status
      console.log("state "+publish)
      this.setState({isLoading:true});
      let jsonObject =  await editQuestionScript.processUpdateStatus(this.state.parent,this.state.question,this.state.secondChild,publish);
      logger.logMessage("editQuestion.view","setPublish",jsonObject);   
      this.batchWriteToDB(jsonObject, publish)
    }

    batchWriteToDB=async(jsonObject, publish) =>{
      let res = await batchWriteToDB(jsonObject);    
      console.log("Response from batchupdate "+res);
      
      let question = this.state.question;
      if (publish !== undefined)
       question.statusName = publish
      this.setState({
        isLoading:false,
        question
      })
       if(Object.keys(res.UnprocessedItems).length!==0)
       {
          logger.logMessage("listFlashCards.view","batchUpdateDB","There are a few UnProcessedItems"+res.data.UnprocessedItems.length);
          this.setState({ questionUpdated:false})
        }   
        else
        {
          logger.logMessage("listFlashCards.view","batchUpdateDB","All items have been written successfully")
          this.setState({ questionUpdated:true})
        }
    }
    processContentData = async() => {
      await this.appendCommentsToQuestion();
      await this.appendVideoToQuestion();
      if (this.hasSkillChanged())
      {
        let pk = `SKILL|${this.state.skillId}`;
        this.addContent(pk, this.state.newSkillName);
        this.deleteOldContent();
      }
      else
        this.addContent(this.state.question.pk, this.state.currentSkillName)
    }
    addContent=(pk, skillName='')=>{
      debugger;
      let jsonObject = editQuestionScript.processContentData(pk,this.state.parent,
                                            this.state.question,this.state.options,
                                            this.state.oImages,
                                            this.state.secondChild,skillName)
                            
      logger.logMessage("editQuestion.view","updateDB",jsonObject);  
      this.batchWriteToDB(jsonObject)  
    }
    deleteOldContent=()=>{      
      deleteQuestion(this.state.parent["pk"],this.state.parent["sk"]);
      deleteQuestion(this.state.question["pk"],this.state.question["sk"]);
      deleteQuestion(this.state.secondChild["pk"],this.state.secondChild["sk"]);
    }
    deleteImage=async(stateName)=>{
      let question = this.state.question;
      let res = await deleteFromS3(this.state.question[stateName]);
      console.log(res);
      
      question[stateName] = '';
      if (stateName === "qImage")
        this.setState({display_qImage:''});
      else if (stateName === "eImage")
        this.setState({display_eImage:''});
      this.setState({
        question
      })
    }
    hasSkillChanged=()=>{  
      if (!this.state.skillId || this.state.question.pk === `SKILL|${this.state.skillId}`)
        return false;
      return true;
    }
    showPreview = (file, stateName,idx, className)=>{          
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
    
        reader.onloadend = function (e) {
          if (["option"].includes(className))
          {
           let p_oImages = [...this.state.p_oImages]   
           p_oImages[idx]= [reader.result]
           this.setState({
             p_oImages
           },()=>{
             console.log(this.state.p_oImages)
           })
          }
        else{  
        this.setState({
            [stateName]: [reader.result]
        })
      }       
      }.bind(this);
    }
    storeFile = (e) =>{
      let oImages = this.state.oImages || [];
      if (this.doesTargetContainOptions(e.target.className)) {       
        oImages[e.target.dataset.id]= e.target.files[0]
        this.setState({ oImages, uploaded:true }, () => logger.logMessage("question.view.js","storeFile",'images '+this.state.oImages.length))
        this.showPreview(e.target.files[0],`p_oImages[${e.target.dataset.id}]`,e.target.dataset.id,e.target.className)
      } 
      else{
        let question = this.state.question;
        question[e.target.name] = e.target.files[0];
        this.setState({
          question,       
          uploaded:true
        }) 
        this.showPreview(e.target.files[0],`p_${e.target.name}`)    
      }    
    }
    answerChosen = () =>{
      let answer='';
      if (this.state.parent.category === 'PR')
        return answer;
      let qType = this.state.parent.qType;
      if (qType === 'mcq')
      {
          let temp = Number( this.state.question["answers"][0])+1;
          answer = "Correct answer is option "+temp;
      }
      else if (qType === 'multiple')
      {
        debugger;
          let ans = this.state.question["answers"];
          for (var ii=0; ii<ans.length;ii++)
          {
            if (ii === ans.length-1)
              answer += ans[ii]+1;
            else
              answer += ans[ii]+1 + " ,";
          }
      }    
      else
      {
         let ans = this.state.question["answers"];
         
         for (let ii=0;ii<ans.length;ii++)
         {
           if (ii === ans.length-1)
            answer += ans[ii];
           else
            answer += ans[ii]+'|'; 
         }
    //     let answerEntered = this.state.question["answers"];
    //     this.setState({answerEntered:answer})
      }
      return answer;
    }
    questionNotInStagingOrProduction=(status)=>{
      return (status !== 'STAGING' && status !== 'PRODUCTION')
    }
    setSkillId = (skillId) => {
      this.setState({
        skillId
      })
    }
    setSkillName =(newSkillName) =>{
      this.setState({
        newSkillName
      })
    }
    setShowModal=(status)=>{
      this.setState({
        showModal:status
      })
      this.handleSubmit();
    }
    setAnswers = (answers) => {
    
      let question = this.state.question;
      question["answers"]= answers;
      console.log(answers);
        this.setState({
          question
        })
    }
    render(){
      let options = this.state.options ||[];
      let oImages = this.state.oImages || [];
      let optionLength = options.length;
     
      if (this.state.showModal === true)
      {
        return (
         <Modal.Dialog>
         <Modal.Header >
           <Modal.Title>Updates only this Question</Modal.Title>
         </Modal.Header>       
         <Modal.Body>
           <p>Please note that since this is not a copied question, you have to edit the India and US version separately for the content only</p>
           <p>Note: The skills, question type and other things will still get updated</p>
         </Modal.Body>
         <Modal.Footer>
                <Button variant="primary"  onClick={()=>this.setShowModal(false)}>UNDERSTOOD</Button>
         </Modal.Footer>
       </Modal.Dialog>
        )
      }
      if (this.state.isLoading)
        return (<div class="text-center font-weight-bold text-primary"><h4>Updating and returning to the same question.</h4>
          <p> Note that if you have updated option image, it may not show up sometimes. </p>
          <p>Just verify by pressing Back button and Viewing the question again.</p></div>)
      if (this.props.isPublished)
          return(<div class="container"><p>Published</p><button class="btn btn-primary" type="button" onClick={()=>{this.props.setEdit(false)}}>BACK</button></div>)  
      return(          
        <div class="mt-4 mb-4 d-flex flex-column justify-content-center align-items-center">
        <div class="border border-2 container2 rounded-3 bg-white ms-2 d-flex flex-column justify-content-center align-items-center">
        <div class="w-100  d-flex justify-content-between align-items-center header rounded-top pt-2 pb-2 ps-4 pe-4">
          <h4 className="modal-title text-white headFont">Edit The Question</h4>
          <FontAwesomeIcon icon="fa-solid fa-circle-arrow-left" color={"white"} size="2x" onClick={()=>{this.props.setEdit(false)}}/>
        </div>
          
          {(this.state.question.statusName === 'PRODUCTION') && (
                  <button class="btn btn-secondary" type="button">IN PRODUCTION</button>
          )}
{/*           {(this.state.question.statusName === 'STAGING') && (
                  <button class="btn btn-secondary" type="button">IN STAGING</button>
          )} */}
{/*           {(this.state.question.statusName === 'REVIEWED') && (
                  <button class="btn btn-secondary" type="button">REVIEWED AND TESTED</button>
          )}    */}
          {(userRights.isUserAdmin()) && 
            (this.state.question.statusName === 'REVIEWED') && (
              <button class="btn btn-danger" type="button"
              onClick={()=>{this.setPublish("FOR REVIEW")}}>SEND BACK FOR REVIEW</button>
          )}         
          {(userRights.isUserAdmin()) && 
            (this.state.question.statusName === 'REVIEWED') && (
              <button class="btn btn-danger" type="button"
              onClick={()=>{this.setPublish("PRODUCTION")}}>PUBLISH TO PRODUCTION</button>
          )}  
          {(this.state.question.statusName === 'FOR REVIEW') && (this.props.canReviewQuestion) && (
            <button class="btn btn-danger" type="button"
            onClick={()=>{this.setPublish("REVIEWED")}}>REVIEWED AND TESTED</button>)}    

            {(this.state.question.statusName === 'FOR REVIEW') && (this.props.canReviewQuestion) && (
            <button class="btn btn-danger" type="button"
            onClick={()=>{this.setPublish("SENT BACK")}}>SEND AGAIN TO DEVELOPER</button>)}   

            {(this.state.question.statusName === 'FOR REVIEW') && (this.props.canReviewQuestion) && (
            <button class="btn btn-danger" type="button"
            onClick={()=>{this.setPublish("FOR DESIGN")}}>SEND AGAIN TO DESIGNER</button>)} 

            {(this.state.question.statusName === 'FOR DESIGN') && (this.props.canDesignQuestion) && (
            <button class="btn btn-danger" type="button"
            onClick={()=>{this.setPublish("DESIGN DONE")}}>DESIGN FINISHED</button>)} 

            {(this.state.question.statusName === 'FOR DESIGN') && (this.props.canDesignQuestion) && (
            <button class="btn btn-danger" type="button"
            onClick={()=>{this.setPublish("DESIGNER SENT BACK")}}>SEND AGAIN TO DEVELOPER</button>)}   

            {(this.state.question.statusName === 'DESIGN DONE') && (!this.props.canDesignQuestion) && (
            <button class="btn btn-danger" type="button"
            onClick={()=>{this.setPublish("FOR REVIEW")}}>FOR REVIEW</button>)}     

          {(this.state.question.statusName === 'DESIGN DONE') && (!this.props.canDesignQuestion) && (
            <button class="btn btn-danger" type="button"
            onClick={()=>{this.setPublish("FOR DESIGN")}}>FOR DESIGN</button>)}            
            <br></br>
            {( this.state.questionUpdated) && (
              <div style={{color:"green", paddingTop:20}}>Successfully saved the updates.</div>
            )} <br></br>
            {(this.state.questionUpdated === false) && (
              <div style={{color:"red", paddingTop:20}}>Could not update the question...</div>
            )}
                <form style={{width:"95%"}} className='mt-2' onSubmit={this.showModal}>
                  {(!this.state.e_skill) && (
                      <div class="w-100  d-flex justify-content-between align-items-center">
                        <label for='skillName'>Skill</label>
                        <div>{this.state.currentSkillName}</div>
                        <button class="btn btn-primary" type="button" disabled={(userRights.isUserDesigner())} 
                          onClick={(e)=>this.toggleField('e_skill')}> Edit Skill</button>
                      </div>           
                  )}
                  {(this.state.e_skill) && (!userRights.isUserDesigner()) && (                    
                      <SkillList class="form-group" 
                          setSkillId={this.setSkillId}     
                          setSkillName={this.setSkillName}
                        />     
                  )}
                  {(this.state.e_bloomsLevel) && (
                  <div class="w-100  d-flex justify-content-between align-items-center mt-2 mb-2" onChange={this.handleChangeParent}>
                        <label for="bloomsLevel">Choose Blooms level</label>
                          <select class="form-select w-50" value={this.state.parent["bloomsLevel"]} name="bloomsLevel">
                              <option selected value="REMEMBER">REMEMBER</option>
                              <option  value="UNDERSTAND">UNDERSTAND</option>
                              <option value="APPLY">APPLY</option>
                              <option value="ANALYSE">ANALYSE</option>
                              <option value="HOTS">HOTS</option>
                          </select>
                  </div>)}
                  {(!this.state.e_bloomsLevel) && (
                  <div class="w-100  d-flex justify-content-between align-items-center mt-2 mb-2">                      
                        <label for="bloomsLevel">Choose Blooms level</label>
                        <input class="form-control w-50" readonly value={this.state.parent["bloomsLevel"]} />
                       <button class="btn btn-primary" disabled={(userRights.isUserDesigner())} type="button" onClick={(e)=>this.toggleField('e_bloomsLevel')}> Edit Blooms</button>
                    </div>
                  )}

                  {(this.state.e_question) && (
                    <div class="form-group"  onChange={this.handleChange}>                             
                          <textarea class="form-control" type="text" name="question" value={this.state.question["question"]} />                    
                          <MathJaxRegion textField="question" typedValue ={this.state.question["question"]} />
                  </div>)}
                  {(!this.state.e_question) && (
                  <div class="form-group">                      
                        <MathJaxRegion readonly textField="question" typedValue={this.state.question["question"]} />
                       <button class="btn btn-primary" type="button" disabled={(userRights.isUserDesigner())} onClick={(e)=>this.toggleField('e_question')}> Edit Question</button>
                    </div>
                  )}
                  {(this.state.e_hint) && (
                    <div class="form-group"  onChange={this.handleChange}>                             
                          <textarea class="form-control" type="text" name="hint" value={this.state.question["hint"]} />                    
                          <MathJaxRegion textField="hint" typedValue ={this.state.question["hint"]} />
                  </div>)}
                  {(!this.state.e_hint) && (
                  <div class="form-group">                      
                        <MathJaxRegion readonly textField="hint" typedValue={this.state.question["hint"]} />
                       <button class="btn btn-primary" type="button" disabled={(userRights.isUserDesigner())} onClick={(e)=>this.toggleField('e_hint')}> Edit Hint</button>
                    </div>
                  )}
                  {(this.state.e_qImage) && (
                  <div class="form-group"  onChange={this.handleChange}>  
                  <label for="questionImage">New Question Image</label>     
                    <Images 
                      dataSetId="1"
                      className="qImage"
                      imageField ="qImage" 
                      storeFile={this.storeFile}                       
                      imageURL={this.state.p_qImage} 
                    />
                  </div>)}
                    {(!this.state.e_qImage) && (
                      <div class="form-group">                      
                          <DisplayImage imageURL={this.state.display_qImage} />
                        <button class="btn btn-primary" type="button" onClick={(e)=>this.toggleField('e_qImage')}> Edit Que Image</button>
                        <button class="btn btn-primary" type="button" disabled={(userRights.isUserDesigner() || userRights.isUserReviewer())} onClick={(e)=>this.deleteImage('qImage')}> Delete Que Image</button>
                      </div>
                    )}  
                    {(this.state.parent.qType !== 'input') && (this.state.e_options) && (
                        options.map((val,idx) => {                          
                            return (
                              <div>
                                 <textarea class="form-control"  onChange={this.handleChange} className="option" 
                                  data-id={idx}
                                  value={this.state.options[`${idx}`]} />
                                 <MathJaxRegion type="text" textField={`option${idx+1}`} 
                                  typedValue={this.state.options[`${idx}`]} />                                                                                                   
                              </div>     
                            )
                          }))                    
                    }
                    {(this.state.parent.qType !== 'input') && (!this.state.e_options) && (
                        options.map((val,idx) => {
                            return (
                            <div class="border border-primary" key={idx} >                             
                              <MathJaxRegion readonly textField={`option${idx+1}`}  
                              typedValue={this.state.options[`${idx}`]} />                                         
                              </div>
                        )})
                    )           
                    }
                   {(this.state.parent.qType !== 'input') && (!this.state.e_options) &&  (optionLength) &&   (                
                      <button class="btn btn-primary" type="button"  
                      onClick={(e)=>this.toggleField(`e_options`)}> Edit Options</button>    
                      )   
                   }
                    {(this.state.parent.qType !== 'input') && (this.state.e_oImages) && (
                        oImages.map((val,idx) => {                          
                            return (
                              <div  onChange={this.handleChange}>                                                             
                                    <Images 
                                      dataSetId={idx}
                                      className="option"
                                      imageField ={`oImage${idx+1}`}
                                      storeFile={this.storeFile}                                    
                                      imageURL={this.state.p_oImages[idx]} 
                                     />                                    
                              </div>     
                            )
                          }))                    
                    }
                    {(this.state.parent.qType !== 'input')  && (!this.state.e_oImages) && (
                        oImages.map((val,idx) => {
                            return (
                                <div style={{borderWidth:1, borderColor:"red"}}>
                                  <DisplayImage imageURL={this.state.display_oImages[idx]} />                        
                                </div>                                   
                            )})
                    )           
                    }
                   {(this.state.parent.qType !== 'input') && (!this.state.e_oImages) &&  (                    
                      <button class="btn btn-primary" type="button"  
                      onClick={(e)=>this.toggleField(`e_oImages`)}> Edit Option Images</button>    
                      )   
                   }                    
                    <div class="form-group">                      
                        <label for="answer">Question type you chose (Not editable)</label>
                        <input class="form-control" readonly value={this.state.parent.qType} />
                    </div>
                    {(this.state.parent.qType === 'mcq') && (this.state.e_answers) && (
                    <div class="form-group"  onChange={this.handleChange}>
                        <label for="answers">Choose an answer</label>
                        <select class="form-select"  value={this.state.question["answers"]} name="answers">
                              <option selected value="0">option 1</option>
                              <option  value="1">option 2</option>
                              <option value="2">option 3</option>
                              <option value="3">option 4</option>
                          </select>
                    </div>)}

                    {(this.state.parent.qType === 'multiple') && (this.state.e_answers) && (
                    <div class="form-group" >
                          <MultiSelect options={[{label:"option 1", value:0},
                                                {label:"option 2",value:1},
                                                {label:"option 3",value:2},
                                                {label:"option 4",value:3}]} triggerUpdate={this.setAnswers}/>

                    </div>)}

                    {(this.state.parent.qType === 'input') && (this.state.e_answers) && (
                    <div class="form-group"  onChange={this.handleChange}>
                        <label for="answers">Type the answers in form 1|2|3 using pipelines to separate answers</label>
                        <input class='form-control' name="answers" 
                          type="text" value={this.state.question["answers"]} />
                    </div>)}

                    {(!this.state.e_answers) && (
                    <div class="form-group">                      
                        <label for="answer">Answer(s) you chose</label>
                        <input class="form-control" readonly value={this.answerChosen(this.state.question["answers"])} />
                        <button class="btn btn-primary" type="button" disabled={(userRights.isUserDesigner())} onClick={(e)=>this.toggleField('e_answers')}> Edit Answer</button>
                    </div>
                    )}  

                    {(this.state.e_isLatex) &&  (
                      <div class="form-group"  onChange={this.handleChangeParent}>
                            <label for="levelName">Have you used MathJax anywhere in question, options, explanations or hints??</label>
                              <select class="form-control" value={this.state.parent["isLatex"]} name="isLatex">
                                  <option selected value="false">NO</option>
                                  <option  value="true">YES</option>
                              </select>
                      </div>)}
                  {(!this.state.e_isLatex) && (
                  <div class="form-group">                      
                        <label for="bloomsLevel">You chose MathJax usage as </label>
                        <input class="form-control" readonly value={this.state.parent["isLatex"]} />
                       <button class="btn btn-primary" disabled={(userRights.isUserDesigner())} 
                       type="button" onClick={(e)=>this.toggleField('e_isLatex')}> Edit Latex</button>
                    </div>
                  )} 

                  {(this.state.parent.qType === 'input') && (this.state.e_isSystemEvaluated) && (
                        <div class="form-group" onChange={this.handleChangeParent}>
                          <label for="isSystemEvaluated">Can the computer evaluate the answer? </label>
                          <select class="form-control" value={this.state.parent["isSystemEvaluated"]} name="isSystemEvaluated">
                            <option selected value={true}>YES</option>
                            <option  value={false}>NO</option>
                          </select>
                        </div>  
                  )}

                  {(this.state.parent.qType === 'input') && (!this.state.e_isSystemEvaluated) && (
                        <div class="form-group">
                          <label for="isSystemEvaluated">You chose 'Can the computer evaluate the answer?' as </label>
                          <input class="form-control" readonly value={this.state.parent["isSystemEvaluated"]} />
                          <button class="btn btn-primary" disabled={(userRights.isUserDesigner())} 
                          type="button" onClick={(e)=>this.toggleField('e_isSystemEvaluated')}> Edit System Evaluated</button>
                        </div>  
                  )}


                  {(this.state.e_explanation) && (
                  <div class="form-group"  onChange={this.handleChange}>                             
                        <textarea class="form-control" type="text" name="explanation" value={this.state.question["explanation"]} />                    
                        <MathJaxRegion textField="explanation" typedValue ={this.state.question["explanation"]} />
                  </div>)}
                  {(!this.state.e_explanation) && (
                  <div class="form-group">                      
                        <MathJaxRegion readonly textField="explanation" typedValue={this.state.question["explanation"]} />
                        <button class="btn btn-primary" type="button"disabled={(userRights.isUserDesigner())}  onClick={(e)=>this.toggleField('e_explanation')}> Edit Explanation</button>
                    </div>
                  )}       

                {(this.state.e_eImage) && (
                    <div class="form-group"  onChange={this.handleChange}>    
                     <label for="questionImage">New Explanation Image</label> 
                    <Images 
                        dataSetId="1"
                        className="eImage"
                        imageField ="eImage" 
                        storeFile={this.storeFile}
                        uploaded={this.state.uploaded} 
                        imageURL={this.state.p_eImage} 
                        />
                </div>)}
                {(!this.state.e_eImage) && (
                <div class="form-group">                      
                        <DisplayImage imageURL={this.state.display_eImage} />
                    <button class="btn btn-primary" type="button" onClick={(e)=>this.toggleField('e_eImage')}> Edit Exp Image</button>
                    <button class="btn btn-primary" type="button" disabled={(userRights.isUserDesigner() || userRights.isUserReviewer())} onClick={(e)=>this.deleteImage('eImage')}> Delete Exp Image</button>
                    </div>
                )}                
                {(this.state.e_copyQuestion) &&  (
                      <div class="form-group"  onChange={this.handleChangeParent}>
                            <label for="copyQuestion">Both the India and US versions have the same question</label>
                              <select class="form-control" value={this.state.parent["copyQuestion"]} name="copyQuestion">
                                <option  value={true}>YES</option>
                                <option selected value={false}>NO</option>  
                              </select>
                      </div>)}
                {(!this.state.e_copyQuestion) && (
                <div class="form-group">                      
                      <label for="bloomsLevel">You chose India and US versions to be the same</label>
                      <input class="form-control" readonly value={this.state.parent["copyQuestion"]} />
                      <button class="btn btn-primary" disabled={(userRights.isUserDesigner())} 
                      type="button" onClick={(e)=>this.toggleField('e_copyQuestion')}> Edit Copy Question</button>
                  </div>
                )}
                {(this.state.e_video) &&  (
                      <div class="form-group"  onChange={this.handleChange}>
                            <label for="video">VideoURL</label>
                            <input class="form-control" type="text" name="video" value={this.state.videoURL} />   
                      </div>)}
                  {(!this.state.e_video) && (
                  <div class="form-group">                      
                        <label for="video">Video URL </label>
                        <input class="form-control" disabled={true} readonly value={this.state.question["videoURL"]} />
                       <button class="btn btn-primary" disabled={(userRights.isUserDesigner())} 
                       type="button" onClick={(e)=>this.toggleField('e_video')}> Edit Video</button>
                    </div>
                  )} 

                <div class="form-group"  onChange={this.handleChange}>
                  <Comments comments={this.state.question["comments"]} typedValue={this.state.comments} 
                  />
                </div>
                <input class="btn btn-primary mb-2"  type="submit" value="Submit" />
                      <p>Note: Whatever is edited will be updated on click of the Submit button</p>
                </form>
        </div></div>
        )     }  
}            
