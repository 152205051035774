import apis from '../../apis/api';
import logger from '../../helper/logger';
var ObjectID = require("bson-objectid");

const processAnswers = (qType, answers)=>{
  if (qType === "mcq")
    return mcqAnswers(answers);
  if (qType === "multiple")
    return multipleAnswers(answers);
  else  
    return inputAnswers(answers);  
};
const mcqAnswers = (answer)=>{
  console.log("answer in mcq "+answer);
  let ans = [];
  ans[0]= Number(answer);
  return ans;
};

const multipleAnswers = (answers)=>{
  let ans = [];
  debugger;

  for (var key in answers)
  {
      ans[key] = answers[key].value;
  }
  
  return ans;
};

const inputAnswers = (answers)=>{
  
  let ans = answers.split("|");
  for (let ii=0;ii<ans.length;ii++)
    ans[ii] = ans[ii].trim();
  console.log(ans);
  return ans;
};

const createQuestionScript = {
    fetchAllSkills:async()=>{
      try {
        let res = await apis._get('SKILL');
        return res;
      }
      catch(e)
      {
          logger.logError("CreateQuestionScript","fetchAllSkills",e)
      }
    },
    fetchSkill:async(pk,sk)=>{
      //debugger;
      try {
        let res = await apis._getByPkAndSk(pk,sk);
        console.log("Tag "+res.data[0].tag)
        return res.data[0]
      }
      catch(e)
      {
          logger.logError("CreateQuestionScript","fetchAllSkills",e)
      }
    },
    saveQuestionLocalStorage:(data) =>{
        if (localStorage.getItem('restore') === true)
        {          
              localStorage.setItem('bloomsLevel',data.bloomsLevel)
              localStorage.setItem('category',data.category)
              localStorage.setItem('question', data.question)
              localStorage.setItem('options',data.options)
              localStorage.setItem('qType',data.qType)
              localStorage.setItem('answer',data.answer)
              localStorage.setItem('qImage',data.qImage)
              localStorage.setItem('oImages',data.oImages)
              localStorage.setItem('eImage',data.eImage)
              localStorage.setItem('explanation',data.explanation)      
        }
    },

    processContentData:(data)=>{
        //For any new question, we create 3 rows. One that holds the skillid, question category, blooms taxonomy, 
        //category, qType
        let date = new Date();
        date = date.toISOString(); 
        let jsonObject =[]; 
        let parentContentId =  ObjectID();//uuid();  
        if (data.qType === 'input') //clear all options and their images if any
        {
          data.options=[];
          data.oImages=[]; 
        }
        let answers=[];
        answers = data.category === 'PR' || data.qType === 'url' ? '': processAnswers(data.qType, data.answers); 
        const contentParentData = 
        {
          "pk":`SKILL|${data.skillId}`,	
          "sk":`PARENT|${parentContentId}`,
          "category":data.category,
          "qType":data.qType,
          "bloomsLevel":data.bloomsLevel,
          "skillName": data.skillName,
          "qualifier":'PARENT',
          "secondQualifier": 'CONTENT',
          "groupId":parentContentId,
          "createdAt":date,
          "updatedAt":date,
          "contentId":parentContentId,
          "isLatex":data.isLatex || false,
          "copyQuestion":data.copyQuestion,
          "isSystemEvaluated": data.isSystemEvaluated || true, 
          "createdBy":localStorage.getItem("username")
        }
        let rowObject = {}        
        for (var key in contentParentData) {
          if (contentParentData.hasOwnProperty(key)) {
            logger.logMessage("createQuestion.script.js","UpdateInDB",key);
            if (contentParentData[key].length !== 0)
            {
              rowObject[key] = contentParentData[key] 
              logger.logMessage("createQuestion.script.js","UpdateInDB",key + ' '+contentParentData[key]);
            }
          }
        }
        jsonObject.push(rowObject);

        //let us create a row for holding India and US content. We always push all three rows whenever a new content is created
        let indiaContentId = ObjectID();//uuid();
        const contentIndiaData = 
        {
          "pk":`SKILL|${data.skillId}`, //CONTENT|${parentContentId}`,	
          "sk":`CHILD|${indiaContentId}`,
          "country_language":"IN|EN",
          "question":data.question,
          "hint":data.hint,
          "qImage":data.qImage,
          "options":data.options,
          "oImages":data.oImages,
          "answers":answers,
          "explanation":data.explanation,
          "eImage":data.eImage,
          "category":data.category,
          "skillName": data.skillName,
          "qualifier":'CHILD|IN|EN',
          "secondQualifier": 'CONTENT',
          "contentId": indiaContentId,
          "parentId": parentContentId,
          "groupId":parentContentId,
          "totalImages":data.totalImages,
          "copyQuestion":data.copyQuestion,
          "createdAt":date,
          "updatedAt":date,
          "statusName":"DRAFT",
          "createdBy":localStorage.getItem("username")
        }
        rowObject = {}        
        for (var key in contentIndiaData) {
          if (contentIndiaData.hasOwnProperty(key)) {
            logger.logMessage("createQuestion.script.js","UpdateInDB for India",key);
            if (contentIndiaData[key].length !== 0)
            {
              rowObject[key] = contentIndiaData[key] 
              logger.logMessage("createQuestion.script.js","UpdateInDB for India",key + ' '+contentIndiaData[key]);
            }
          }
        }
        jsonObject.push(rowObject);

        let usContentId = ObjectID();//uuid();
        const contentUsData = 
        {
          "pk":`SKILL|${data.skillId}`, //CONTENT|${parentContentId}`,	
          "sk":`CHILD|${usContentId}`,
          "country_language":"US|EN",
          "question":data.question,
          "hint":data.hint,
          "qImage":data.qImage,
          "options":data.options,
          "oImages":data.oImages,
          "answers":answers,
          "explanation":data.explanation,
          "eImage":data.eImage,
          "skillName": data.skillName,
          "category":data.category,
          "qualifier":'CHILD|US|EN',
          "secondQualifier": 'CONTENT',
          "contentId": usContentId,
          "groupId": parentContentId,
          "parentId": parentContentId,
          "totalImages":data.totalImages,
          "copyQuestion":data.copyQuestion,
          "createdAt":date,
          "updatedAt":date,
          "statusName":"DRAFT",
          "createdBy":localStorage.getItem("username")
        }
        rowObject = {}        
        for (var key in contentUsData) {
          if (contentUsData.hasOwnProperty(key)) {
            logger.logMessage("createQuestion.script.js","UpdateInDB for US",key);
            if (contentUsData[key].length !== 0)
            {
              rowObject[key] = contentUsData[key] 
              logger.logMessage("createQuestion.script.js","UpdateInDB for US",key + ' '+contentUsData[key]);
            }
          }
        }
        jsonObject.push(rowObject);
        return jsonObject;
    } 
}
export default createQuestionScript