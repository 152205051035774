import React, {Component} from 'react';
import addSubjectScript from './add.script';
import {writeToDB} from '../../scripts/writeToDB';

export default class AddSubject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myName:'',
            isLoading:false,
            subjects:'',
            subjectId:''
        };
      }
    handleChange=(e) =>{
        this.setState({[e.target.name]: e.target.value.trim()})
        console.log(this.state.myName);
    }  
    handleSubmit=(e) => {
          console.log("Inside Handle Submit of Subject");
          e.preventDefault();    
          this.setState({
            isLoading:true
            })
            let jsonObject = addSubjectScript.processContentData(this.state.myName);
            this.updateDB(jsonObject);  
           
    }
    updateDB =async(jsonObject)=>
    {
        let res = await writeToDB(jsonObject);
        console.log(res);
        this.setState({
            isLoading:false
        })
        
    }
    
    render(){
        if (this.state.isLoading)
        {
            return (
                <div>
                    <p>Adding Subject. Please be patient.....</p>
                </div>
            )
        }
        return (
            <div class='container'>
                <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                    <div class="form-group" >                    
                    <label htmlFor="Subject">Subject</label>
                    <input class='form-control' 
                        name="myName" value={this.state.myName}/>
                    </div>
                    <input disabled={!this.state.myName}  
                     class="btn btn-primary mb-2"  type="submit" value="Submit" />       
                <p style={{color:"red"}}>*Please do not add the same subject again! I wont be able to delete</p>
                
{/*                 <div class="form-group" >    
                    <p>List of subjects added so far</p>                
                    <MyDropDown 
                     value={this.state.subjectId} 
                     triggerUpdate={this.setSubject} 
                     list={this.state.subjects}/>
                  </div> */}
                  </form>
              </div>
         );
    }
}
