import React from 'react';
import {isUndefined} from '../../scripts/validations';
const TextRegion = (props) =>{
    
    let textField = props.textField; //sends the name of the prop
    let className = (isUndefined(props.className) ? textField:props.className)
    let dataSetId = (isUndefined(props.dataSetId) ? textField:props.dataSetId)

    return(
            <div>
                <label style={label} class="text-capitalize" htmlFor={textField}>{textField}</label>
                <textarea class="form-control"
                        type = "text"
                        name={textField}
                        id={textField}
                        data-id={dataSetId}
                        className={className}
                        value={props.typedValue}
                />
            </div>    
        )
       
}            

const label={
    color:"blue"
}
/*p.capitalize {
    text-transform: capitalize;
  }*/
export default TextRegion
